import axios from "axios";
import { message } from "antd";
import { getItem } from "../../SecureStorage";
import {
  ADD_NEW_DOCUMENT_TYPE_FAILURE,
  ADD_NEW_DOCUMENT_TYPE_LOADING,
  ADD_NEW_DOCUMENT_TYPE_SUCCESS,
  DELETE_OVERVIEW_DOC_FAILURE,
  DELETE_OVERVIEW_DOC_LOADING,
  DELETE_OVERVIEW_DOC_SUCCESS,
  GET_EVENT_OVERVIEW_FAILURE,
  GET_EVENT_OVERVIEW_LOADING,
  GET_EVENT_OVERVIEW_SUCCESS,
  GET_STAGE_OVERVIEW_FAILURE,
  GET_STAGE_OVERVIEW_LOADING,
  GET_STAGE_OVERVIEW_SUCCESS,
} from "../types";
import { getEventData } from "../gantt-stage-action";

export const getStageOverview = (project_id, stage) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_STAGE_OVERVIEW_LOADING, payload: null });
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_stage_overview`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: project_id,
        stage: stage,
        sub_contractor_mode: isSubContractor ? true : false,
      },
    })
    .then((result) => {
      dispatch({ type: GET_STAGE_OVERVIEW_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_STAGE_OVERVIEW_FAILURE,
        payload: { message: err.message },
      });
      return message.error("Error occurred while retrieving stage overview!");
    });
};

export const getEventOverview = (event_id) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));
  dispatch({ type: GET_EVENT_OVERVIEW_LOADING, payload: null });

  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_event_overview`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        event_id: event_id,
        sub_contractor_mode: isSubContractor ? true : false,
      },
    })
    .then((result) => {
      dispatch({ type: GET_EVENT_OVERVIEW_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_EVENT_OVERVIEW_FAILURE,
        payload: { message: err.message },
      });
      return message.error("Error occurred while retrieving event overview!");
    });
};

export const addNewDocumentType = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: ADD_NEW_DOCUMENT_TYPE_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_event_doc_checklist/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: ADD_NEW_DOCUMENT_TYPE_SUCCESS, payload: result.data });
    await message.success("Success!", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while adding new document type!", 1);
    dispatch({ type: ADD_NEW_DOCUMENT_TYPE_FAILURE, payload: e });
    return e;
  }
};

export const deleteEventOverviewDoc = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: DELETE_OVERVIEW_DOC_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_event_doc/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: DELETE_OVERVIEW_DOC_SUCCESS, payload: result.data });
    await message.success("Success!", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while deleting document!", 1);
    dispatch({ type: DELETE_OVERVIEW_DOC_FAILURE, payload: e });
    return e;
  }
};

export const deleteNotUploadedEventOverviewDoc = (data) => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${userData.authToken}`,
  };
  axios
    .post(`${process.env.REACT_APP_BASE_URL}delete_doc_checklist/`, data)
    .then((res) => {
      message.success("Deleted successfully!");
      return res;
    })
    .catch((err) => message.error(err.message));
};

export const deleteVersionDocs = (data, workTable, event_id) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));


  axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${userData.authToken}`,
  };
  axios
    .post(`${process.env.REACT_APP_BASE_URL}delete_document_folder/`, data)
    .then((res) => {
      if (!workTable) {
        dispatch(getEventData(event_id, isSubContractor));
      } else {
        dispatch(getEventOverview(event_id));
      }
      message.success("Deleted the Version successfully!");
      return res;
    })
    .catch((err) => message.error(err.message));
};
