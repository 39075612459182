import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import "./card-percentage.style.less";

const CardPercentage = ({
  title,
  dataMargin,
  items,
  cardName,
  statusDataBank,
}) => {
  const [status, setStatus] = useState(statusDataBank);
  const [flag, setFlag] = useState();



  useEffect(() => {
    const el = document.activeElement;
    let count = items?.reduce((acc, val) => {
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);

    if (count == items?.length) {
      setStatus(100);
    } else {
      setStatus((count / items?.length) * 100);
      if (el.getAttribute("card") === cardName) {
        setFlag(true);
      } else {
        setFlag(false);
      }
    }

  }, [items]);

  useEffect(() => {
    setStatus(statusDataBank);
  }, [statusDataBank]);

  return (
    <>
      <div className="card-row" style={{ marginTop: dataMargin ? "2rem" : 0 }}>
        <h1
          style={
            !dataMargin
              ? {
                  color: "#2d2d2d",
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }
              : {
                  color: "#3d87f1",
                  fontSize: "1.6rem",
                }
          }
        >
          {title}
        </h1>
        {status === 100 ? (
          <div className="finished">
            <img src={require("../../assets/logos/ic-actions-check.svg")} />
            <p>Completed</p>
          </div>
        ) : flag ? (
          <div className="progress">
            <div className="inner-progress">
              <div className="img-container">
                <img src={require("../../assets/sitevisit/progress.png")} />
              </div>
              <p style={{ color: "#3d87f1", fontSize: "1.1rem" }}>
                In-Progress
              </p>
            </div>

            <Progress
              type="circle"
              status="active"
              showInfo
              percent={status?.toFixed(0)}
              strokeWidth={10}
              strokeColor="#9ec3f8"
              trailColor="#e7f1ff"
            />
          </div>
        ) : (
          <div className="pending">
            <div className="inner-pending">
              <div className="img-container"></div>
              <p>Pending</p>
            </div>
            <Progress
              type="circle"
              status="active"
              showInfo
              percent={status?.toFixed(0)}
              strokeWidth={10}
              strokeColor={"#f1a73d"}
              trailColor={"#fff7e6"}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CardPercentage;
