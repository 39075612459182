import {
    GET_GANTT_EVENTS_MENU_LOADING,
    GET_GANTT_EVENTS_MENU_SUCCESS,
    GET_GANTT_EVENTS_MENU_FAILURE,
    GET_GANTT_EVENTS_MENU_RESET,
    GET_EVENTS_LOADING,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,
    GET_EVENTS_RESET
} from '../../actions/types';

const INITIAL_STATE = {
    gantteventsmenu: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};

const ganttEventMenuReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_GANTT_EVENTS_MENU_LOADING:
            return {
                ...state,
                gantteventsmenu: {
                    ...state.gantteventsmenu,
                    get: {
                        ...state.gantteventsmenu,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.gantteventsmenu.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_GANTT_EVENTS_MENU_SUCCESS:
            return {
                ...state,
                gantteventsmenu: {
                    ...state.gantteventsmenu,
                    get: {
                        ...state.gantteventsmenu.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.gantteventsmenu.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_GANTT_EVENTS_MENU_FAILURE:
            return {
                ...state,
                gantteventsmenu: {
                    ...state.gantteventsmenu,
                    gantteventsmenu: {
                        ...state.gantteventsmenu,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.gantteventsmenu.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case GET_GANTT_EVENTS_MENU_RESET:
            return {
                ...state,
                gantteventsmenu: {
                    ...state.gantteventsmenu.get,
                    get: {
                        ...INITIAL_STATE.gantteventsmenu.get,
                        reset: true,
                    },
                },
            };
            case GET_EVENTS_LOADING:
                return {
                    ...state,
                    gantteventsmenu: {
                        ...state.gantteventsmenu,
                        get: {
                            ...state.gantteventsmenu,
                            loading: true,
                            reset: false,
                            success: {
                                ...state.gantteventsmenu.success,
                                ok: false,
                            },
                            failure: {
                                error: false,
                                message: '',
                            },
                        },
                    },
                };
            case GET_EVENTS_SUCCESS:
                return {
                    ...state,
                    gantteventsmenu: {
                        ...state.gantteventsmenu,
                        get: {
                            ...state.gantteventsmenu.get,
                            loading: false,
                            reset: false,
                            success: {
                                ...state.gantteventsmenu.get.success,
                                ok: true,
                                data: action.payload,
                            },
                            failure: {
                                error: false,
                                message: '',
                            },
                        },
                    },
                };
            case GET_EVENTS_FAILURE:
                return {
                    ...state,
                    gantteventsmenu: {
                        ...state.gantteventsmenu,
                        gantteventsmenu: {
                            ...state.gantteventsmenu,
                            loading: false,
                            reset: false,
                            success: {
                                ...state.gantteventsmenu.success,
                                ok: false,
                                data: null,
                            },
                            failure: {
                                error: true,
                                message: action.payload.message,
                            },
                        },
                    },
                };
            case GET_EVENTS_RESET:
                return {
                    ...state,
                    gantteventsmenu: {
                        ...state.gantteventsmenu.get,
                        get: {
                            ...INITIAL_STATE.gantteventsmenu.get,
                            reset: true,
                        },
                    },
                }
    }
    return state;
}

export default ganttEventMenuReducers;