import { getItem } from "../../SecureStorage";
import {
  SSO_AUTHENTICATE_FAILURE,
  SSO_AUTHENTICATE_LOADING,
  SSO_AUTHENTICATE_RESET,
  SSO_AUTHENTICATE_SUCCESS,
} from "../../actions/sso";



const INITIAL_STATE = {
  get: {
    loading: false,
    reset: false,
    success: {
      ok: false,
      // data: {
      //   auction: {
      //     token: getItem("auctionToken")
      //       ? JSON.parse(getItem("auctionToken"))
      //       : "",
      //     is_new: getItem("is_new_auction_user")
      //       ? JSON.parse(getItem("is_new_auction_user"))
      //       : false,
      //     pending_vendor_id: getItem("pending_vendor_id")
      //       ? JSON.parse(getItem("pending_vendor_id"))
      //       : null,
      //   },
      //   ecom: {
      //     token: getItem("ecomToken") ? JSON.parse(getItem("ecomToken")) : "",
      //     is_new: getItem("is_new_ecom_user")
      //       ? JSON.parse(getItem("is_new_ecom_user"))
      //       : false,
      //     userType: getItem("ecom_user_type")
      //       ? JSON.parse(getItem("ecom_user_type"))
      //       : "",
      //   },
      // },
    },
    failure: {
      error: false,
      message: "",
    },
  },
};

const ssoReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SSO_AUTHENTICATE_LOADING:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          reset: false,
          success: {
            ...state.get.success,
            ok: false,
          },
          failure: {
            error: false,
            message: "",
          },
        },
      };
    case SSO_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          reset: false,
          success: {
            ...state.get.success,
            ok: true,
            data: {
              ...state.get.success.data,
              ...action.payload,
            },
          },
          failure: {
            error: false,
            message: "",
          },
        },
      };
    case SSO_AUTHENTICATE_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          reset: false,
          success: {
            ...state.get.success,
            ok: false,
            data: null,
          },
          failure: {
            error: true,
            message: action.payload.message,
          },
        },
      };
    case SSO_AUTHENTICATE_RESET:
      return {
        ...state,
        get: {
          ...INITIAL_STATE.get,
          reset: true,
        },
      };
  }
  return state;
};

export default ssoReducers;
