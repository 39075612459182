import { Upload, Button } from "antd";
import React, { useEffect, useState } from "react";
import ImageModal from "./ImageModal";
import FloatInput from "./FloatInput";
import { proposedInverterLocationApi } from "../../actions/get-site-visit-action";
import { useDispatch } from "react-redux";

function ProposedLocationUpload({
  state,
  setState,
  objKey,
  name,
  title,
  siteVisitId,
}) {
  const [desc, setDesc] = useState();
  const dispatch = useDispatch();
  const uploadHandler = (files) => {
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);
    formData.append("title", title);
    let filesData = [];
    for (let i = 0; i < files?.length; i++) {
      formData.append("file", files[i]);
      filesData.push({ desc: desc || null });
    }
    if (filesData?.length > 0) {
      formData.append("files_data", JSON.stringify(filesData));
    }
    dispatch(proposedInverterLocationApi(formData, "add", siteVisitId));
  };
  return (
    <>
      <FloatInput
        label="Enter Note"
        placeholder="Enter Note"
        onChange={(e) => {
          setDesc(e.target.value);
        }}
        card={name}
      />
      <div className="buttons-grp">
        <Upload
          accept=".jpg,.jpeg,.svg,.png"
          name="file"
          beforeUpload={(file, FileList) => {
            uploadHandler(FileList);
            return false;
          }}
          showUploadList={false}
          multiple={false}
        >
          <Button className="upload">Upload Picture</Button>
        </Upload>

        <Button
          onClick={() => {
            setState({
              ...state,
              [objKey]: { ...state[objKey], showFile: true },
            });
          }}
          className="save"
        >
          View All Images
        </Button>

        <ImageModal
          title="Image List"
          existingFiles={
            state[objKey]?.existing_files?.length > 0
              ? state[objKey]?.existing_files
              : []
          }
          isVisible={state[objKey]?.showFile}
          setIsVisible={() =>
            setState({
              ...state,
              [objKey]: { ...state[objKey], showFile: false },
            })
          }
          data={state[objKey]?.files?.length > 0 ? state[objKey]?.files : []}
          setFormState={setState}
          objectKey={objKey}
          formState={state}
        />
      </div>
    </>
  );
}

export default ProposedLocationUpload;
