import { Col, Row, Radio, Space, Button, message } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSiteVisitDetails,
  getSiteVisitformDetails,
  updateBuildingArea,
} from "../../actions/get-site-visit-action";
import "./building-area.style.less";
import { mapDetails, rccObject } from "./buildingAreaConstants";
import BuildingAreaTopCard from "./BuildingAreaTopCard";
import CardPercentage from "./CardPercentage";
import FloatInput from "./FloatInput";
import FloatInputSuffix from "./FloatInputSuffix";
import SiteVistContext from "./SiteVisitContext";
import UploadFile from "./UploadFile";
import MultipleForms from "./MultipleForms";
import {
  getSiteVisitFormFields,
  mergeFiles,
  processFiles,
  sendFiles,
} from "../constants";

function RccBuilding({ selectedBuildingArea, title, siteVisitId }) {
  const siteVisitContext = useContext(SiteVistContext);

  const {
    handleActiveTabHandler,
    activeTab,
    rccRef,
    rccBuildingDetails,
    setRccBuildingDetails,
    saveLaterText,
    setSaveLaterText,
    buildingAreaIndex,
  } = siteVisitContext;

  //  LA Details
  const [la, setLa] = useState(0);
  const [laData, setLaData] = useState([]);
  const [laLength, setLaLength] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setRccBuildingDetails({ ...rccBuildingDetails, name: title });
  }, [title]);

  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
      dispatch(
        getSiteVisitformDetails(siteVisitId, "rcc_la_index", buildingAreaIndex)
      );
    }
  }, [siteVisitId, buildingAreaIndex]);

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  const getSiteVisitFormDetailsReducer = useSelector((state) => {
    return state.getSiteVisitFormDetailsReducers.siteVisitFormDetails.get;
  });

  let imageKeys = [
    "any_shadow_causing_objects_image",
    "any_trees_surrounding_building_image",
    "chimney_on_rooftop_image",
    "building_height_image",
    "rooftop_image",
  ];

  useEffect(() => {
    if (getSiteVisitFormDetailsReducer.success.data) {
      let data = getSiteVisitFormDetailsReducer.success.data;
      setLa(data?.rcc_building_la_detail_array?.length);
      setLaLength(data?.rcc_building_la_detail_array?.length);
      const temp1 = mapDetails(data?.rcc_building_la_detail_array, [
        "id",
        "range",
        "type",
        "file",
      ]);
      setLaData(temp1);
      setRccBuildingDetails({
        ...rccBuildingDetails,
        rcc_building_la_detail_array: temp1,
      });
    }
  }, [getSiteVisitFormDetailsReducer.success.data]);

  useEffect(() => {
    if (
      getSiteVisitDetailsReducer?.success.data?.rcc_building_details_array
        .length > 0
    ) {
      let data = getSiteVisitDetailsReducer?.success.data;

      const details = data?.rcc_building_details_array[buildingAreaIndex];

      const processedImages = imageKeys.reduce((acc, key) => {
        acc[key] = processFiles(details[key], details, `${key}_ids`);
        return acc;
      }, {});

      let {
        any_shadow_causing_objects_image,
        any_trees_surrounding_building_image,
        chimney_on_rooftop_image,
        building_height_image,
        rooftop_image,
        rcc_building_la_detail_array,
        ...existingData
      } = details;

      setRccBuildingDetails({
        ...existingData,
        ...processedImages,
      });
    }
  }, [getSiteVisitDetailsReducer?.success.data, buildingAreaIndex]);

  const onChangeHandler = (e) => {
    const value = e.target.value;

    setRccBuildingDetails({
      ...rccBuildingDetails,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (saveLaterText === "RCC buildings") {
      saveHandler();
    }
  }, [saveLaterText]);

  const incrementHandler = (key) => {
    const dataMap = {
      la_details: {
        data: laData,
        setData: setLaData,
        count: la,
        length: laLength,
        setCount: setLa,
        newItem: {
          range: "",
          type: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
    };

    const { data, setData, setCount, newItem, count, length } = dataMap[key];
    if (count > length) {
      message.warning("Please add a new one after saving the first one!");
      return;
    }
    const val = data?.length === 0 ? 0 : data[data.length - 1].id;
    const newItemWithId = { ...newItem, id: val + 1 };

    setCount((prevCount) => prevCount + 1);
    setData([...data, newItemWithId]);
  };

  const decrementHandler = (key) => {
    const dataMap = {
      la_details: {
        count: la,
        setCount: setLa,
        data: laData,
        setData: setLaData,
        length: laLength,
      },
    };

    const { count, setCount, data, setData, length } = dataMap[key];

    if (count - 1 < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    if (count <= 1) {
      setData([]);
      setCount(0);
    } else {
      setCount(count - 1);
      setData(data.slice(0, -1));
    }
  };

  const handleValues = (value, key) => {
    const dataMap = {
      la_details: {
        setCount: setLa,
        setData: setLaData,
        data: laData,
        apiKey: "rcc_building_la_detail_array",
        fields: ["id", "range", "type", "file"],
        length: laLength,
        newItem: {
          range: "",
          type: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
    };

    const { setCount, setData, data, newItem, length, apiKey, fields } =
      dataMap[key];

    if (value < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    const arr = mapDetails(
      getSiteVisitDetailsReducer.success.data[apiKey],
      fields
    );

    for (let i = arr.length === 0 ? 0 : data.length; i < value; i++) {
      arr.push({
        id: i + 1,
        ...newItem,
      });
    }

    setCount(value);
    setData(arr);
  };

  const saveHandler = () => {
    let keys = Object.keys(rccObject);
    let count = keys?.reduce((acc, v) => {
      let val = rccBuildingDetails[v];

      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val && val.hasOwnProperty("showFile")) {
        if (val?.existing_files?.length > 0 || val?.files?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);

    formData.append("index", buildingAreaIndex);

    const {
      rooftop_image,
      building_height_image,
      chimney_on_rooftop_image,
      any_trees_surrounding_building_image,
      any_shadow_causing_objects_image,
      rcc_building_la_detail_array,
      ...existingData
    } = rccBuildingDetails;

    formData.append("rcc_building_details", JSON.stringify(existingData));
    for (let i = 0; i < imageKeys?.length; i++) {
      sendFiles(
        rccBuildingDetails[imageKeys[i]]?.files,
        imageKeys[i],
        formData
      );
    }

    if (count == keys.length) {
      Promise.resolve(dispatch(updateBuildingArea(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the fields");
      setSaveLaterText();
    }
  };

  const getRoofDetailsItems = () => {
    let items = [
      rccBuildingDetails?.building_height,
      mergeFiles(rccBuildingDetails?.building_height_image),
      rccBuildingDetails?.is_parapet_wall,
      rccBuildingDetails?.is_existing_columns,
      rccBuildingDetails?.is_water_proofing_layer,
    ];
    if (rccBuildingDetails?.is_parapet_wall) {
      items.push(rccBuildingDetails?.parapet_wall_height);
    }
    if (rccBuildingDetails?.is_water_proofing_layer) {
      items.push(rccBuildingDetails?.water_proofing_layer_type);
      items.push(rccBuildingDetails?.is_anchoring_allowed);
    }
    return items;
  };
  const getRoofObstructionItems = () => {
    let arr = [
      rccBuildingDetails?.is_chimney_on_rooftop,
      rccBuildingDetails?.any_trees_surrounding_building,
      rccBuildingDetails?.any_shadow_causing_objects,
    ];
    if (rccBuildingDetails?.is_chimney_on_rooftop) {
      arr.push(mergeFiles(rccBuildingDetails?.chimney_on_rooftop_image));
    }
    if (rccBuildingDetails?.any_trees_surrounding_building) {
      arr.push(
        mergeFiles(rccBuildingDetails?.any_trees_surrounding_building_image)
      );
      arr.push(rccBuildingDetails?.tree_height);
    }
    if (rccBuildingDetails?.any_shadow_causing_objects) {
      arr.push(
        mergeFiles(rccBuildingDetails?.any_shadow_causing_objects_image)
      );
    }

    return arr;
  };

  return (
    <div className="building-area-container">
      <div
        className="card-container card-container-top"
        ref={(element) => {
          rccRef.current[0] = element;
        }}
      >
        <BuildingAreaTopCard
          label1="Name"
          label2="Type"
          placeholder1="Name"
          placeholder2="Type"
          name1="name"
          name2="type"
          onChange={onChangeHandler}
          value1={title}
          value2={selectedBuildingArea}
        />
      </div>
      <h1 className="upper-text">Basic Building Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Basic Building Details"
              items={[
                rccBuildingDetails?.building_age,
                mergeFiles(rccBuildingDetails?.rooftop_image),
              ]}
            />
            <FloatInputSuffix
              type="number"
              required={true}
              label="Building Age"
              placeholder="Building Age"
              onChange={onChangeHandler}
              card="Basic Building Details"
              name="building_age"
              value={rccBuildingDetails?.building_age}
              suffix={"years"}
            />
            <h1 className="inner-text">Upload Rooftop Images</h1>
            <UploadFile
              state={rccBuildingDetails}
              setState={setRccBuildingDetails}
              objKey="rooftop_image"
              fileType="is_rcc_building_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Roof Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Roof Details"
              items={getRoofDetailsItems()}
            />
            <FloatInputSuffix
              type="number"
              required={true}
              label="Height of the building"
              placeholder="Height of the building"
              onChange={onChangeHandler}
              card="Roof Details"
              name="building_height"
              value={rccBuildingDetails?.building_height}
              suffix={"m"}
            />
            <UploadFile
              state={rccBuildingDetails}
              setState={setRccBuildingDetails}
              objKey="building_height_image"
              fileType="is_rcc_building_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">is Parapet wall available?</h1>
            <Radio.Group
              name="is_parapet_wall"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.is_parapet_wall}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {rccBuildingDetails?.is_parapet_wall && (
              <FloatInputSuffix
                type="number"
                required={true}
                label="Height of Parapet wall"
                placeholder="Height of Parapet wall"
                onChange={onChangeHandler}
                card="Roof Details"
                name="parapet_wall_height"
                value={rccBuildingDetails?.parapet_wall_height}
                suffix={"m"}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Existing Columns on Roof?</h1>
            <Radio.Group
              name="is_existing_columns"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.is_existing_columns}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Water Proofing Layer</h1>
            <Radio.Group
              name="is_water_proofing_layer"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.is_water_proofing_layer}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        {rccBuildingDetails?.is_water_proofing_layer && (
          <>
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Water Proofing Layer Type</h1>
                <Radio.Group
                  name="water_proofing_layer_type"
                  onChange={onChangeHandler}
                  value={rccBuildingDetails?.water_proofing_layer_type}
                  className="horizontal-radio"
                  card="Roof Details"
                >
                  <Space direction="horizontal" wrap={true}>
                    <Radio value="Cement">
                      <span className="text-image">
                        {" "}
                        a) Cement{" "}
                        <img
                          src={require("../../assets/sitevisit/cement.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="Stone Tiles">
                      <span className="text-image">
                        {" "}
                        b) Stone Tiles
                        <img
                          src={require("../../assets/sitevisit/stoneTiles.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="Brick Mortar">
                      <span className="text-image">
                        {" "}
                        c) Brick Mortar
                        <img
                          src={require("../../assets/sitevisit/brickMortar.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="China Mosiac">
                      <span className="text-image">
                        {" "}
                        d) China Mosiac
                        <img
                          src={require("../../assets/sitevisit/chinaMosiac.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>{" "}
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Anchoring allowed</h1>
                <Radio.Group
                  name="is_anchoring_allowed"
                  onChange={onChangeHandler}
                  value={rccBuildingDetails?.is_anchoring_allowed}
                  card="Roof Details"
                >
                  <Space direction="vertical">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </>
        )}
      </div>
      <h1 className="upper-text">Access</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[3] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Access"
              items={[
                rccBuildingDetails?.roof_access,
                rccBuildingDetails?.roof_access_type,
              ]}
            />
            <h1 className="inner-text">Roof Access</h1>
            <Radio.Group
              name="roof_access"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.roof_access}
              card="Access"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Roof Access Type</h1>
            <Radio.Group
              name="roof_access_type"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.roof_access_type}
              className="horizontal-radio"
              card="Access"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value="Staircase Inside Building">
                  <span className="text-image">
                    {" "}
                    a) Staircase Inside Building
                    <img
                      src={require("../../assets/sitevisit/stairCase.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Ladder Outside">
                  <span className="text-image">
                    {" "}
                    b) Ladder Outside
                    <img
                      src={require("../../assets/sitevisit/ladderOutside.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Obstructions on the roof top</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[4] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Obstructions on the roof top"
              items={getRoofObstructionItems()}
            />
            <h1 className="inner-text">Chimney on the roof or nearby</h1>
            <Radio.Group
              name="is_chimney_on_rooftop"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.is_chimney_on_rooftop}
              card="Obstructions on the roof top"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {rccBuildingDetails?.is_chimney_on_rooftop && (
              <UploadFile
                state={rccBuildingDetails}
                setState={setRccBuildingDetails}
                objKey="chimney_on_rooftop_image"
                fileType="is_rcc_building_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Any trees surrounding the building</h1>
            <Radio.Group
              name="any_trees_surrounding_building"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.any_trees_surrounding_building}
              card="Obstructions on the roof top"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {rccBuildingDetails?.any_trees_surrounding_building && (
              <UploadFile
                state={rccBuildingDetails}
                setState={setRccBuildingDetails}
                objKey="any_trees_surrounding_building_image"
                fileType="is_rcc_building_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        {rccBuildingDetails?.any_trees_surrounding_building === true && (
          <Row>
            <Col span={22}>
              <FloatInputSuffix
                type="number"
                required={true}
                label="Height of the tree from rooftop"
                placeholder="Height of the tree from rooftop"
                onChange={onChangeHandler}
                card="Obstructions on the roof top"
                name="tree_height"
                value={rccBuildingDetails?.tree_height}
                suffix={"m"}
              />{" "}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={22}>
            <h1 className="inner-text">
              Any other Shadow causing obejcts on roof and surrounding to the
              roof/building
            </h1>
            <FloatInput
              required={true}
              label="Enter value"
              placeholder="Enter value"
              name="any_shadow_causing_objects"
              onChange={onChangeHandler}
              card="Obstructions on the roof top"
              value={rccBuildingDetails?.any_shadow_causing_objects}
            />
            {rccBuildingDetails?.any_shadow_causing_objects && (
              <UploadFile
                state={rccBuildingDetails}
                setState={setRccBuildingDetails}
                objKey="any_shadow_causing_objects_image"
                fileType="is_rcc_building_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Dust,Heat and Smoke impact on rooftop</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[5] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Dust,Heat and Smoke impact on rooftop"
              items={[
                rccBuildingDetails?.dust_impact,
                rccBuildingDetails?.smoke_impact,
                rccBuildingDetails?.heat_impact,
                rccBuildingDetails?.chemical_type,
              ]}
            />
            <h1 className="inner-text">Dust Impact on Rooftop</h1>
            <Radio.Group
              name="dust_impact"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.dust_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Smoke Impact on Rooftop</h1>
            <Radio.Group
              name="smoke_impact"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.smoke_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Heat Impact on Rooftop</h1>
            <Radio.Group
              name="heat_impact"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.heat_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Type of chemical in the smoke</h1>
            <FloatInput
              required={true}
              label="Type of chemical in the smoke"
              placeholder="Type of chemical in the smoke"
              name="chemical_type"
              onChange={onChangeHandler}
              card="Dust,Heat and Smoke impact on rooftop"
              value={rccBuildingDetails?.chemical_type}
            />
          </Col>
        </Row>
      </div>

      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[6] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="LA Details"
              items={
                rccBuildingDetails?.existing_la
                  ? [
                      la,
                      rccBuildingDetails?.rcc_building_la_detail_array,
                      rccBuildingDetails?.existing_la,
                    ]
                  : [rccBuildingDetails?.existing_la]
              }
              title="LA Details"
            />
            <h1 className="inner-text">Existing LA s on the rooftop</h1>
            <Radio.Group
              name="existing_la"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.existing_la}
              className="horizontal-radio"
              cardName="LA Details"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              label="No of Las"
              placeholder="No of Las*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "la_details")
              }
              value={la}
              objKey="la_details"
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="La Details"
            />
            {laData?.map((i, index) => {
              return (
                <MultipleForms
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={laData}
                  setItems={setLaData}
                  fields={getSiteVisitFormFields("LA")}
                  title="LA"
                  type="rcc_building_la_details"
                  imageType="is_rcc_building_la_image"
                  updateApiKey="la_image"
                  typeOptions={["Conventional", "ESE"]}
                  siteVisitId={siteVisitId}
                  laIndex={buildingAreaIndex}
                  isBuildingAndArea
                  length={laLength}
                  emptyFeilds={{
                    range: "",
                    type: "",
                    file: { showFile: false, existing_files: [], files: [] },
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Water availability for O&M</h1>
      <div
        className="card-container"
        ref={(element) => {
          rccRef.current[7] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Water availability for O&M"
              items={[
                rccBuildingDetails?.is_water_tapping,
                rccBuildingDetails?.water_tds,
              ]}
            />
            <h1 className="inner-text">Water tapping availability near roof</h1>
            <Radio.Group
              name="is_water_tapping"
              onChange={onChangeHandler}
              value={rccBuildingDetails?.is_water_tapping}
              className="horizontal-radio"
              card="Water availability for O&M"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">TDS of water available</h1>
            <FloatInputSuffix
              type="number"
              label="TDS of water available"
              placeholder="TDS of water available"
              onChange={onChangeHandler}
              card="Water availability for O&M"
              name="water_tds"
              value={rccBuildingDetails?.water_tds}
              suffix={"ppm"}
            />
          </Col>
        </Row>
      </div>

      <div
        className="add-container"
        onClick={() => handleActiveTabHandler(2)}
        style={{ cursor: "pointer" }}
      >
        <p>+Add new Building/Area</p>
      </div>
      <div className="btn-container" onClick={() => saveHandler()}>
        <Button>Save</Button>
      </div>
    </div>
  );
}

export default RccBuilding;
