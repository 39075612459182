import {
    GET_DELETE_LOADING,
    GET_DELETE_SUCCESS,
    GET_DELETE_FAILURE,
    GET_DELETE_RESET,
    GET_UPDATE_LOADING,
    GET_UPDATE_SUCCESS,
    GET_UPDATE_FAILURE,
    GET_UPDATE_RESET,
    CREATE_NEW_CHECKLIST_ITEM_LOADING,
    CREATE_NEW_CHECKLIST_ITEM_SUCCESS,
    CREATE_NEW_CHECKLIST_ITEM_FAILURE,
    CREATE_NEW_CHECKLIST_ITEM_RESET,
    ADD_SECONDARY_EMAIL_LOADING,
    ADD_SECONDARY_EMAIL_SUCCESS,
    ADD_SECONDARY_EMAIL_FAILURE,
    ADD_SECONDARY_EMAIL_RESET,
    REMOVE_SECONDARY_EMAIL_LOADING,
    REMOVE_SECONDARY_EMAIL_SUCCESS,
    REMOVE_SECONDARY_EMAIL_FAILURE,
    REMOVE_SECONDARY_EMAIL_RESET
} from '../../actions/types';

const INITIAL_STATE = {
    update: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};

const updateReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_UPDATE_FAILURE:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case GET_UPDATE_RESET:
            return {
                ...state,
                update: {
                    ...state.update.get,
                    get: {
                        ...INITIAL_STATE.update.get,
                        reset: true,
                    },
                },
            };
        case CREATE_NEW_CHECKLIST_ITEM_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case CREATE_NEW_CHECKLIST_ITEM_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case CREATE_NEW_CHECKLIST_ITEM_FAILURE:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case CREATE_NEW_CHECKLIST_ITEM_RESET:
            return {
                ...state,
                update: {
                    ...state.update.get,
                    get: {
                        ...INITIAL_STATE.update.get,
                        reset: true,
                    },
                },
            };
        case ADD_SECONDARY_EMAIL_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case ADD_SECONDARY_EMAIL_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case ADD_SECONDARY_EMAIL_FAILURE:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case ADD_SECONDARY_EMAIL_RESET:
            return {
                ...state,
                update: {
                    ...state.update.get,
                    get: {
                        ...INITIAL_STATE.update.get,
                        reset: true,
                    },
                },
            };
        case REMOVE_SECONDARY_EMAIL_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case REMOVE_SECONDARY_EMAIL_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case REMOVE_SECONDARY_EMAIL_FAILURE:
            return {
                ...state,
                update: {
                    ...state.update,
                    get: {
                        ...state.update.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.update.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case REMOVE_SECONDARY_EMAIL_RESET:
            return {
                ...state,
                update: {
                    ...state.update.get,
                    get: {
                        ...INITIAL_STATE.update.get,
                        reset: true,
                    },
                },
            };
    }
    return INITIAL_STATE;
}

export default updateReducers;    