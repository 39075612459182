import React from "react";
import { getItem, removeItem } from "../SecureStorage";

const AuctionReRoute = (props) => {
  const user = JSON.parse(getItem("userData"));
  const email = user?.username;
  const auctionToken = JSON.parse(getItem("auctionToken"));
  const pendingVendorId = getItem("pending_vendor_id")
    ? JSON.parse(getItem("pending_vendor_id"))
    : null;

  removeItem("redirectFrom");

  const path = window.location.pathname?.replace("/auction/", "");

  const userData = {
    token: user.authToken,
    username: user.username,
    userId: user.userId,
    is_consumer: user.isConsumer,
    is_vendor: !user.isConsumer,
    is_admin: false,
    name: user.name,
    company_name: user.companyName,
    website: user.website,
    phone: user.phone,
    expirationDate: new Date(new Date().getTime() + 3600 * 1000),
    department: user.department,
  };

  if (pendingVendorId) {
    const pendingVendorUrl = `${
      process.env.REACT_APP_AUCTION_SITE_BASE_URL
    }?token=${auctionToken}&path=${path}&email=${email}&redirectFrom=pmt&pendingVendorId=${pendingVendorId}&user=${JSON.stringify(
      userData
    )}`;
    return window.location.replace(pendingVendorUrl);
  }

  const url = `${
    process.env.REACT_APP_AUCTION_SITE_BASE_URL
  }?token=${auctionToken}&path=${path}&email=${email}&redirectFrom=pmt&user=${JSON.stringify(
    userData
  )}`;

  return window.location.replace(url);
};

export default AuctionReRoute;
