import { createContext } from 'react';

const UpdateContext = createContext({
    updateStatus: false,
    updateTicketStatus: false,
    updateUploadStatus: false,
    updateTicketStatusHandler: () => { },
    updateUploadStatusHandler: () => { },
    updateStatusHandler: () => { }
});

export default UpdateContext;
