import {
  GET_SITE_VISIT_FAILURE,
  GET_SITE_VISIT_LOADING,
  GET_SITE_VISIT_SUCCESS,
} from "../../actions/types";

const initialState = {
  siteVisit: {
    get: {
      loading: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const getSiteVisitReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_VISIT_LOADING:
      return {
        ...state,
        siteVisit: {
          ...state.siteVisit,
          get: {
            ...state.siteVisit.get,
            loading: true,
            reset: false,
            success: {
              ...state.siteVisit.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_SITE_VISIT_SUCCESS:
      return {
        ...state,
        siteVisit: {
            ...state.siteVisit,
            get: {
                ...state.siteVisit.get,
                loading: false,
                reset: false,
                success: {
                    ...state.siteVisit.get.success,
                    ok: true,
                    data: action.payload,
                },
                failure: {
                    error: false,
                    message: '',
                },
            },
        },
      };
    case GET_SITE_VISIT_FAILURE:
      return {
        ...state,
        siteVisit: {
            ...state.siteVisit,
            get: {
                ...state.siteVisit.get,
                loading: false,
                reset: false,
                success: {
                    ...state.siteVisit.get.success,
                    ok: false,
                    data: null,
                },
                failure: {
                    error: true,
                    message: action.payload.message,
                },
            },
        },
      };
    default:
      return state;
  }
};

export default getSiteVisitReducers;
