import { Row, Col, Pagination, Tooltip, Popover } from "antd";
import React, { useEffect, useState, useRef } from "react";
import SiteLeftCard from "./SiteLeftCard";
import SiteTabsHeader from "./SiteTabsHeader";
import "./site-tabs.style.less";
import SiteVistContext from "./SiteVisitContext";
import SiteRightCard from "./SiteRightCard";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getItem, setItem } from "../../SecureStorage";
import AddBuildingArea from "./AddBuildingArea";
import { getSiteVisitDetails } from "../../actions/get-site-visit-action";
import { useDispatch, useSelector } from "react-redux";

function SiteTabs(props) {
  const [siteVisitId, setSiteVisitId] = useState(
    props.location.siteVisitId || getItem("siteVisitId")
  );

  const [searchElement, setSearchElement] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [buildingAreaIndex, setBuildingAreaIndex] = useState();
  const [isChatboxVisible, setIsChatboxVisible] = useState(false);

  const [totalDocuments, setTotalDocuments] = useState([]);
  let defaultTabs = [
    {
      id: 0,
      name: "Client Details",
      collapseTabs: ["Company Details", "Contact Details", "Project Details"],
      isCollapse: false,
      progress: 0,
    },
    {
      id: 1,
      name: "Electrical Details",
      collapseTabs: ["Basic Details"],
      isCollapse: true,
      progress: 0,
    },
    {
      id: 2,
      name: "Buildings/Area",
      collapseTabs: ["Basic Details"],
      isCollapse: true,
      progress: 0,
    },
    {
      id: 3,
      name: "Proposed Locations",
      collapseTabs: ["Proposed Locations"],
      isCollapse: true,
      progress: 0,
    },
    {
      id: 4,
      name: "Project Specifications",
      collapseTabs: ["Project Specifications"],
      isCollapse: true,
      progress: 0,
    },
  ];

  const siteRef = useRef([]);
  const electricalRef = useRef([]);
  const rccRef = useRef([]);
  const metalSheetRef = useRef([]);
  const groundMountRef = useRef([]);
  const carPortRef = useRef([]);
  const projectSpecRef = useRef([]);
  const proposedLocationRef = useRef([]);
  const [tabs, setTabs] = useState(defaultTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(4);
  const [activeCollapseInnerId, setActiveCollapseInnerId] = useState(0);
  const [activeCollapseTab, setActiveCollapseTab] = useState(
    tabs[0].collapseTabs[0]
  );

  const [electricalDetails, setElectricalDetails] = useState({
    contracted_load: "",
    grid_voltage_level: "",
    solar_pv_interconnected_voltage: "",
    express_dedicated_line: null,
    dg_synchronisation_panel: null,
    transformer_details_array: [],
    dg_details_array: [],
    spare_feeder_details_array: [],
    lt_panel_details_array: [],
    is_spare_feeder: null,
    vacant_chamber_available: "",
    vacant_chamber_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    bus_bar_extension_allowed: "",
    bus_bar_extension_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    note: "",
    capacitor_bank_rating: "",
    cabling_mode: [],
    type_of_machinery: "",
    type_of_load: "",
    load_shedding: "",
    holiday_avg_load: "",
    working_avg_load: "",
    non_working_days_nos: "",
    net_meter_requirement: null,
    internet_availability: null,
    single_phase_supply_availability: null,
  });

  const [rccBuildingDetails, setRccBuildingDetails] = useState({
    name: "",
    type: "Building",
    buildingAreaType: "RCC buildings",
    building_age: "",
    rooftop_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    building_height: "",
    building_height_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_parapet_wall: null,
    parapet_wall_height: "",
    is_existing_columns: null,
    is_water_proofing_layer: null,
    water_proofing_layer_type: "",
    is_anchoring_allowed: null,
    roof_access: "",
    roof_access_type: [],
    is_chimney_on_rooftop: null,
    chimney_on_rooftop_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    any_trees_surrounding_building: null,
    any_trees_surrounding_building_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    tree_height: "",
    any_shadow_causing_objects: "",
    any_shadow_causing_objects_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    dust_impact: "",
    heat_impact: "",
    smoke_impact: "",
    chemical_type: "",
    existing_la: null,
    rcc_building_la_detail_array: [],
    is_water_tapping: null,
    water_tds: "",
  });

  const [saveLaterText, setSaveLaterText] = useState();

  const [metalSheetDetails, setMetalSheetDetails] = useState({
    name: "",
    type: "Building",
    buildingAreaType: "Metal Sheet",
    shed_type: "",
    sheet_age: "",
    sheet_thickness: "",
    shed_age: "",
    building_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    metal_sheet_profile: [],
    metal_sheet_profile_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    purlins_type: [],
    purlins_type_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    building_height: "",
    building_height_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    roof_inclination: "",
    roof_orientation: "",
    roof_structure_condition: "",
    roof_remarks: "",
    roof_insurance: null,
    is_drainage_existing: null,
    roof_access: "",
    roof_access_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    roof_access_type: [],
    roof_access_type_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_skylight_on_rooftop: null,
    is_skylight_on_rooftop_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_chimney_on_rooftop: null,
    chimney_on_rooftop_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    chimney_height: "",
    any_trees_surrounding_building: null,
    any_trees_surrounding_building_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    tree_height: "",
    any_shadow_causing_objects: "",
    any_shadow_causing_objects_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    dust_impact: "",
    heat_impact: "",
    smoke_impact: "",
    chemical_type: "",
    existing_la: null,
    metal_sheet_la_detail_array: [],
    is_water_tapping: null,
    is_water_tapping_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    water_tds: "",
  });

  const [groundMountDetails, setGroundMountDetails] = useState({
    name: "",
    type: "Area",
    buildingAreaType: "Ground Mount",
    highlight_designated_ground_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    area: "",
    area_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    soil_type: "",
    soil_type_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    ground_condition: "",
    ground_condition_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    ground_remarks: "",
    ground_remarks_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_trees_inside_area: null,
    is_trees_inside_area_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    height_of_trees: "",
    any_shadow_surrounding_area: "",
    any_shadow_surrounding_area_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_water_tapping: null,
    is_water_tapping_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    water_tds: "",
  });

  const [carPortDetails, setCarPortDetails] = useState({
    name: "",
    type: "Area",
    buildingAreaType: "Car/Bus Port",
    area_type: "",
    area_type_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    soil_type: "",
    soil_type_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_land_levelling_required: null,
    is_water_proofing_layer: null,
    water_proofing_layer_type: "",
    existing_cable_routing: "",
    existing_cable_routing_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_metal_sheet_required: null,
    profile_sheet: "",
    is_proposed_ground_area: null,
    is_proposed_ground_area_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    height_of_trees: "",
    any_shadow_surrounding_area: "",
    any_shadow_surrounding_area_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    shadow_height: "",
    shadow_height_image: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    is_water_tapping: null,
    water_tds: "",
  });

  const [proposedLocationDetails, setProposedLocationDetails] = useState({
    inverters_proposed_locations_array: [],
    satellite_images: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    number_of_inverters: "",
    proposed_location_of_inverter: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    proposed_location_of_acdb: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    proposed_location_of_earthing: {
      files: [],
      existing_files: [],
      showFile: false,
    },
    proposed_ac_cable_routing: {
      files: [],
      existing_files: [],
      showFile: false,
    },
  });

  const [specs, setSpecs] = useState({
    is_metal_sheet_roof_puncturing_allowed: null,
    can_trees_cut_metal_sheet: null,
    is_rcc_roof_puncturing_allowed: null,
    can_trees_cut_rcc: null,
    is_site_grading_ground_mount: null,
    is_site_leveling_ground_mount: null,
    area_type: "",
    is_site_grading_carport: null,
    is_site_leveling_carport: null,
    is_carport_puncturing_allowed: null,
    can_trees_cut_carport: null,
  });

  const saveLaterHandler = (title, selectedBuildingArea) => {
    if (title === "Client Details") {
      setSaveLaterText("Client Details");
    } else if (title === "Electrical Details") {
      setSaveLaterText("Electrical Details");
    } else if (title === "Proposed Locations") {
      setSaveLaterText("Proposed Locations");
    } else if (selectedBuildingArea === "RCC buildings") {
      setSaveLaterText("RCC buildings");
    } else if (selectedBuildingArea === "Metal Sheet") {
      setSaveLaterText("Metal Sheet");
    } else if (selectedBuildingArea === "Ground Mount") {
      setSaveLaterText("Ground Mount");
    } else if (selectedBuildingArea === "Car/Bus Port") {
      setSaveLaterText("Car/Bus Port");
    } else {
      setSaveLaterText("Project Specifications");
    }
  };

  const handleActiveTabHandler = (id) => {
    setActiveTab(id);
    setActiveCollapseTab(tabs[id].collapseTabs[0]);
    setBuildingAreaIndex(tabs[id]?.tabIndex);

    setItem("building_area_index", tabs[id]?.tabIndex);
    setSearchElement("");
    setSearchData([]);
  };
  const handleTabs = (newTabs) => {
    let arr = defaultTabs;
    arr.splice(3, 0, ...newTabs);
    arr[arr.length - 2].id = arr.length - 2;
    arr[arr.length - 1].id = arr.length - 1;
    setTabs([...arr]);
  };

  const handleEndIndex = (id) => {
    setEndIndex(id);
  };
  const handleStartIndex = (id) => {
    setStartIndex(id);
  };

  const handleActiveCollapseTab = (name) => {
    setActiveCollapseTab(name);
  };

  const handleInnerCollapseId = (id) => {
    setActiveCollapseInnerId(id);
  };
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <a>
          <LeftOutlined style={{ paddingRight: "5px" }} />
          Prev
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          Next
          <RightOutlined style={{ paddingLeft: "5px" }} />
        </a>
      );
    }
    return originalElement;
  }

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);

  useEffect(() => {
    if (getSiteVisitDetailsReducer.success.data) {
      setTotalDocuments([
        ...getSiteVisitDetailsReducer.success.data?.project_specifications_documents?.map(
          (doc, index) => {
            const { id, title, file } = doc;
            return {
              id: id,
              index: index,
              name: title,
              url: file,
              is_uploaded: id === null ? false : true,
            };
          }
        ),
      ]);
    }
  }, [
    getSiteVisitDetailsReducer.success.data?.project_specifications_documents,
  ]);

  const editTabsHandler = (values) => {
    setTabs([...values]);
  };

  return (
    <>
      <SiteVistContext.Provider
        value={{
          activeTab: activeTab,
          defaultTabs: defaultTabs,
          handleActiveTabHandler: handleActiveTabHandler,
          editTabsHandler: editTabsHandler,
          tabsList: tabs,
          handleTabs: handleTabs,
          startIndex: startIndex,
          endIndex: endIndex,
          handleEndIndex: handleEndIndex,
          handleStartIndex: handleStartIndex,
          activeCollapseTab: activeCollapseTab,
          handleActiveCollapseTab: handleActiveCollapseTab,
          activeCollapseInnerId: activeCollapseInnerId,
          handleInnerCollapseId: handleInnerCollapseId,
          siteRef: siteRef,
          electricalRef: electricalRef,
          rccRef: rccRef,
          rccBuildingDetails: rccBuildingDetails,
          setRccBuildingDetails: setRccBuildingDetails,
          metalSheetRef: metalSheetRef,
          metalSheetDetails: metalSheetDetails,
          setMetalSheetDetails: setMetalSheetDetails,
          groundMountRef: groundMountRef,
          groundMountDetails: groundMountDetails,
          setGroundMountDetails: setGroundMountDetails,
          carPortRef: carPortRef,
          carPortDetails: carPortDetails,
          setCarPortDetails: setCarPortDetails,
          electricalDetails: electricalDetails,
          setElectricalDetails: setElectricalDetails,
          specs: specs,
          setSpecs: setSpecs,
          proposedLocationDetails: proposedLocationDetails,
          setProposedLocationDetails: setProposedLocationDetails,
          proposedLocationRef: proposedLocationRef,
          searchElement: searchElement,
          setSearchElement: setSearchElement,
          searchData: searchData,
          setSearchData: setSearchData,
          projectSpecRef: projectSpecRef,
          totalDocuments: totalDocuments,
          buildingAreaIndex: buildingAreaIndex,
          setBuildingAreaIndex: setBuildingAreaIndex,
          saveLaterText: saveLaterText,
          setSaveLaterText: setSaveLaterText,
        }}
      >
        <div className="site-tabs-container">
          <SiteTabsHeader tabs={tabs} />
          <div className="site-container-body">
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              {activeTab == 2 ? (
                <>
                  <AddBuildingArea siteVisitId={siteVisitId} />
                </>
              ) : (
                <>
                  <Col span={8}>
                    <SiteLeftCard
                      title={tabs[activeTab].name}
                      collapseTabs={tabs[activeTab].collapseTabs}
                      isCollapse={tabs[activeTab].isCollapse}
                      buildingAreaType={tabs[activeTab]?.type}
                      siteVisitId={siteVisitId}
                      selectedBuildingArea={tabs[activeTab]?.selectedValue}
                    />
                  </Col>
                  <Col span={16}>
                    <SiteRightCard
                      title={tabs[activeTab].name}
                      collapseTabs={tabs[activeTab].collapseTabs}
                      isCollapse={tabs[activeTab].isCollapse}
                      siteVisitId={siteVisitId}
                      buildingAreaType={tabs[activeTab]?.type}
                      selectedBuildingArea={tabs[activeTab]?.selectedValue}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
          <Pagination
            onChange={(page) => {
              setActiveTab(page - 1);
              setActiveCollapseTab(tabs[page - 1].collapseTabs[0]);
              setBuildingAreaIndex(tabs[page - 1]?.tabIndex);
              setItem("building_area_index", tabs[page - 1]?.tabIndex);
            }}
            current={activeTab + 1}
            pageSize={1}
            total={tabs?.length}
            itemRender={itemRender}
            showTotal={(total, range) => (
              <p style={{ color: "#8f8f8f" }}>
                Showing <strong>{range[0]}</strong> of {total} Forms
              </p>
            )}
          />
          <p
            style={{
              position: "absolute",
              bottom: "20px",
              fontSize: "1.3rem",
              fontWeight: "600",
              color: "#3d87f1",
              textDecoration: "underline",
              left: "10px",
              zIndex: "1",
              margin: "0",
              cursor: "pointer",
            }}
            onClick={() => {
              saveLaterHandler(
                tabs[activeTab]?.name,
                tabs[activeTab]?.selectedValue
              );
            }}
          >
            Save for Later
          </p>
        </div>
      </SiteVistContext.Provider>
    </>
  );
}

export default SiteTabs;
