import {
  FLOAT_RFQ_LOADING,
  FLOAT_RFQ_SUCCESS,
  FLOAT_RFQ_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  event: {
    post: {
      loading: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const floatRfqReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLOAT_RFQ_LOADING:
      return {
        ...state,
        event: {
          ...state.event,
          post: {
            ...state.event.post,
            loading: true,
            success: {
              ...state.event.post.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case FLOAT_RFQ_SUCCESS:
      return {
        ...state,
        event: {
          ...state.event,
          post: {
            ...state.event.post,
            loading: false,
            success: {
              ...state.event.post.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case FLOAT_RFQ_FAILURE:
      return {
        ...state,
        event: {
          ...state.event,
          post: {
            ...state.event.post,
            loading: false,
            success: {
              ...state.event.post.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
  }
  return state;
};

export default floatRfqReducers;
