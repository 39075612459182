import {
    GET_SAMPLE_EVENT_LOADING,
    GET_SAMPLE_EVENT_SUCCESS,
    GET_SAMPLE_EVENT_FAILURE
} from "../../actions/types";

const INITIAL_STATE = {
    sampleevent: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: "",
            },
        },
    },
};

const getSampleEventReducers = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_SAMPLE_EVENT_LOADING:
            return {
                ...state,
                sampleevent: {
                    ...state.sampleevent,
                    get: {
                        ...state.sampleevent.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.sampleevent.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };

        case GET_SAMPLE_EVENT_SUCCESS:
            return {
                ...state,
                sampleevent: {
                    ...state.sampleevent,
                    get: {
                        ...state.sampleevent.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.sampleevent.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };

        case GET_SAMPLE_EVENT_FAILURE:
            return {
                ...state,
                sampleevent: {
                    ...state.sampleevent,
                    get: {
                        ...state.sampleevent.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.sampleevent.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        default:
            return state;
    }
};

export default getSampleEventReducers;
