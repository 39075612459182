import * as actions from '../../actions/types';

const INITIAL_STATE = {
    epcs: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    },
    basicEPCs: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    },
    teamMembers: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    },
};

const epcReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.GET_EPCS_LOADING:
            return {
                ...state,
                epcs: {
                    ...state.epcs,
                    get: {
                        ...state.epcs.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.epcs.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_EPCS_SUCCESS:
            return {
                ...state,
                epcs: {
                    ...state.epcs,
                    get: {
                        ...state.epcs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.epcs.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_EPCS_FAILURE:
            return {
                ...state,
                epcs: {
                    ...state.epcs,
                    get: {
                        ...state.epcs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.epcs.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case actions.GET_EPCS_RESET:
            return {
                ...state,
                epcs: {
                    ...state.epcs.get,
                    post: {
                        ...INITIAL_STATE.epcs.get,
                        reset: true,
                    },
                },
            }
        case actions.GET_BASIC_EPCS_LOADING:
            return {
                ...state,
                basicEPCs: {
                    ...state.basicEPCs,
                    get: {
                        ...state.basicEPCs.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.basicEPCs.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_BASIC_EPCS_SUCCESS:
            return {
                ...state,
                basicEPCs: {
                    ...state.basicEPCs,
                    get: {
                        ...state.basicEPCs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.basicEPCs.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_BASIC_EPCS_FAILURE:
            return {
                ...state,
                basicEPCs: {
                    ...state.basicEPCs,
                    get: {
                        ...state.basicEPCs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.basicEPCs.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case actions.GET_BASIC_EPCS_RESET:
            return {
                ...state,
                basicEPCs: {
                    ...state.basicEPCs.get,
                    post: {
                        ...INITIAL_STATE.basicEPCs.get,
                        reset: true,
                    },
                },
            }
        case actions.GET_EPC_TEAM_MEMBERS_LOADING:
            return {
                ...state,
                teamMembers: {
                    ...state.teamMembers,
                    get: {
                        ...state.teamMembers.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.teamMembers.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_EPC_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                teamMembers: {
                    ...state.teamMembers,
                    get: {
                        ...state.teamMembers.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.teamMembers.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case actions.GET_EPC_TEAM_MEMBERS_FAILURE:
            return {
                ...state,
                teamMembers: {
                    ...state.teamMembers,
                    get: {
                        ...state.teamMembers.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.teamMembers.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case actions.GET_EPC_TEAM_MEMBERS_RESET:
            return {
                ...state,
                teamMembers: {
                    ...state.teamMembers.get,
                    post: {
                        ...INITIAL_STATE.teamMembers.get,
                        reset: true,
                    },
                },
            }
    }
    return state;
}

export default epcReducers;
