import {
    POST_SAMPLE_EVENT_LOADING,
    POST_SAMPLE_EVENT_SUCCESS,
    POST_SAMPLE_EVENT_FAILURE,
    POST_SAMPLE_EVENT_RESET
} from '../../actions/types';

const INITIAL_STATE = {
    event: {
        post: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
    },
};

const postEventReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POST_SAMPLE_EVENT_LOADING:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.event.post.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_SAMPLE_EVENT_SUCCESS:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.event.post.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_SAMPLE_EVENT_FAILURE:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.event.post.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case POST_SAMPLE_EVENT_RESET:
            return {
                ...state,
                event: {
                    ...state.event.post,
                    post: {
                        ...INITIAL_STATE.event.post,
                        reset: true,
                    },
                },
            }
    }
    return state;
}

export default postEventReducers;