import {
  LOGIN_USER_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_RESET,
} from "../../actions/types";

const INITIAL_STATE = {
  login: {
    post: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const loginReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_LOADING:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: true,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case LOGIN_USER_RESET:
      return {
        ...state,
        login: {
          ...state.login.post,
          post: {
            ...INITIAL_STATE.login.post,
            reset: true,
          },
        },
      };
  }
  return INITIAL_STATE;
};

export default loginReducers;
