import * as actions from "../../actions/types";

const INITIAL_STATE = {
    planningStages: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: "",
            },
        },
    },
    planningEvents: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: "",
            },
        },
    },
};

const planningReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //Planning Stages
        case actions.GET_PLANNING_STAGES_LOADING:
            return {
                ...state,
                planningStages: {
                    ...state.planningStages,
                    get: {
                        ...state.planningStages.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.planningStages.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case actions.GET_PLANNING_STAGES_SUCCESS:
            return {
                ...state,
                planningStages: {
                    ...state.planningStages,
                    get: {
                        ...state.planningStages.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.planningStages.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case actions.GET_PLANNING_STAGES_FAILURE:
            return {
                ...state,
                planningStages: {
                    ...state.planningStages,
                    get: {
                        ...state.planningStages.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.planningStages.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case actions.GET_PLANNING_STAGES_RESET:

        //Planning Events
        case actions.GET_PLANNING_EVENTS_LOADING:
            return {
                ...state,
                planningEvents: {
                    ...state.planningEvents,
                    get: {
                        ...state.planningEvents.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.planningEvents.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case actions.GET_PLANNING_EVENTS_SUCCESS:
            return {
                ...state,
                planningEvents: {
                    ...state.planningEvents,
                    get: {
                        ...state.planningEvents.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.planningEvents.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case actions.GET_PLANNING_EVENTS_FAILURE:
            return {
                ...state,
                planningEvents: {
                    ...state.planningEvents,
                    get: {
                        ...state.planningEvents.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.planningEvents.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case actions.GET_PLANNING_EVENTS_RESET:
        return {
            ...state,
            planningEvents: {
                ...state.planningEvents.get,
                post: {
                    ...INITIAL_STATE.planningEvents.get,
                    reset: true,
                },
            },
        };
    }
    return state;
};

export default planningReducers;
