import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import { getItem, removeItem, setItem } from "./SecureStorage";

const SafEarthNavigator = ({ token, path }) => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const isNew = query.get("is_new");
  const email = query.get("email");
  const projectId = query.get("projectId");
  const requestFinance = query.get("requestFinance");
  const createNewProposal = query.get("createNewProposal");
  const auctionToken = query.get("auctionToken");
  const redirectFrom = query.get("redirectFrom");
  const firstLogin = query.get("first_login");
  const department = query.get("department");
  const isConsumer = query.get("is_consumer");

  setItem("userData", { authToken: token, username: email, department });
  setItem("redirectFrom", redirectFrom);
  setItem("is_new", isNew);
  setItem("email", email);
  setItem("auctionToken", auctionToken);

  if (projectId) {
    setItem("projectId", projectId);
  } else {
    removeItem("projectId");
  }

  if (requestFinance) {
    setItem("requestFinance", true);
  } else {
    removeItem("requestFinance");
  }

  if (createNewProposal) {
    setItem("createNewProposal", true);
  } else {
    removeItem("createNewProposal");
  }

  if (firstLogin) {
    setItem("firstLogin", true);
  }

  if (isConsumer) {
    const userData = JSON.parse(getItem("userData"));
    setItem("userData", {
      ...userData,
      department: "Customer",
      isConsumer: true,
    });
  }

  return window.location.replace("/" + path);
};

export default withRouter(SafEarthNavigator);
