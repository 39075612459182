import {
  GET_PROJECT_REPORT_LOADING,
  GET_PROJECT_REPORT_SUCCESS,
  GET_PROJECT_REPORT_FAILURE,
} from "../types";
import axios from "axios";
import { getItem } from "../../SecureStorage";
import { message } from "antd";

export const getProjectReports = () => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  dispatch({ type: GET_PROJECT_REPORT_LOADING, payload: null });
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${userData.authToken}`,
  };

  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_reports/`, {
      params: {
        project_id: projectId,
      },
    })
    .then((res) => {
      dispatch({ type: GET_PROJECT_REPORT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_PROJECT_REPORT_FAILURE, payload: err.message });
    });
};

export const deleteReports = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + userData.authToken,
  };

  axios
    .post(`${process.env.REACT_APP_BASE_URL}delete_reports/`, data)
    .then((result) => {
        dispatch(getProjectReports());
      return message.success("Report has been deleted successfully!");
    })
    .catch((err) => {
      return message.error("Something went wrong!");
      
    });
};
