import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row, Upload, message } from "antd";
import FormModal from "./FormModal";
import { useDispatch, useSelector } from "react-redux";
import FloatInput from "./FloatInput";
import { proposedInverterLocationApi } from "../../actions/get-site-visit-action";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

function InverterForm({
  data,
  items,
  setItems,
  title,
  imageType,
  siteVisitId,
  length,
  dataIndex,
}) {
  const [desc, setDesc] = useState();
  const [imageIndex, setImageIndex] = useState(-1);
  const [isCollapsed, setIsCollapsed] = useState(-1);

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  useEffect(() => {
    setIsCollapsed(items?.length - 1);
  }, []);

  const dispatch = useDispatch();

  const deleteHandler = (index) => {
    if (index < length) {
      const formData = new FormData();
      formData.append("index", index);
      formData.append("site_visit_id", siteVisitId);
      dispatch(proposedInverterLocationApi(formData, "delete", siteVisitId));
    } else {
      let arr = items;
      arr[index] = {
        id: index + 1,
        building_id: "",
        inverter_type: "",
        file: { showFile: false, existing_files: [], files: [] },
      };
      setItems([...arr]);
    }
  };

  const saveHandler = (type, index) => {
    let buildingType = items[index]?.inverter_type;
    if (items[index]?.building_id >= 0) {
      const formData = new FormData();
      formData.append("site_visit_id", siteVisitId);
      formData.append(buildingType, items[index]?.building_id);
      formData.append("title", "Proposed Location of Inverter");
      formData.append("inverter_type", buildingType);
      let filesData = [];
      for (let i = 0; i < items[index]?.file?.files?.length; i++) {
        formData.append("file", items[index]?.file?.files[i]);
        filesData.push({ desc: desc || null });
      }
      if (filesData?.length > 0) {
        formData.append("files_data", JSON.stringify(filesData));
      }
      if (type === "edit") {
        formData.append("index", index);
        dispatch(proposedInverterLocationApi(formData, "edit", siteVisitId));
      } else {
        dispatch(proposedInverterLocationApi(formData, "add", siteVisitId));
      }
    } else {
      message.warning("Please Select Roof Type");
    }
  };

  const viewImageHandler = (index) => {
    setImageIndex(index);
    let arr = items;
    arr[index] = {
      ...arr[index],
      file: {
        ...arr[index].file,
        showFile: true,
      },
    };
    setItems([...arr]);
  };
  const uploadHandler = (filelist, index) => {
    let newItems = [...items];
    setImageIndex(index);
    newItems[index] = {
      ...newItems[index],
      file: {
        ...newItems[index].file,
        files: [...newItems[index].file?.files, ...filelist],
      },
    };
    setItems(newItems);
  };

  const checkboxHandler = (values, index) => {
    let newItems = items;
    setImageIndex(index);
    let lastItem = values[values.length - 1];
    newItems[index] = {
      ...newItems[index],
      building_id: lastItem?.split(" ")[0],
      inverter_type: lastItem?.split(" ")[1],
    };
    setItems([...newItems]);
  };

  let arr = [
    ...getSiteVisitDetailsReducer?.success.data?.metal_sheet_details_array.map(
      (i, index) => {
        return {
          ...i,
          inverter_type: "roof_id",
          index: index,
        };
      }
    ),
    ...getSiteVisitDetailsReducer?.success.data?.rcc_building_details_array.map(
      (i, index) => {
        return {
          ...i,
          inverter_type: "rcc_id",
          index: index,
        };
      }
    ),
  ];

  return (
    <div>
      <div
        className={isCollapsed === dataIndex ? "collapse-active" : "collapse"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <h1 style={{ margin: 0 }} className="collapse-text">
            {title} {data.id}
          </h1>
          {items[dataIndex]?.building_id >= 0 && (
            <span
              className="delete-icon"
              onClick={() => deleteHandler(dataIndex)}
            >
              x
            </span>
          )}
        </div>
        {isCollapsed === dataIndex ? (
          <UpOutlined onClick={() => setIsCollapsed(-1)} />
        ) : (
          <DownOutlined onClick={() => setIsCollapsed(dataIndex)} />
        )}
      </div>
      {isCollapsed === dataIndex && (
        <>
          <Row>
            <Col span={24}>
              <Checkbox.Group
                onChange={(e) => checkboxHandler(e, dataIndex)}
                value={
                  items[dataIndex]?.building_id +
                  " " +
                  items[dataIndex]?.inverter_type
                }
                title="Machinery Type"
              >
                <div className="checkbox-group-border">
                  {arr?.map((i, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={`${i.index} ${i.inverter_type}`}
                      >
                        {i.name}
                      </Checkbox>
                    );
                  })}
                </div>
              </Checkbox.Group>
            </Col>
          </Row>
          <FloatInput
            label="Enter Note"
            placeholder="Enter Note"
            onChange={(e) => setDesc(e.target.value)}
            value={desc}
          />
          <div className="buttons-grp">
            <Upload
             accept=".jpg,.jpeg,.png,.heic"
              name="file"
              beforeUpload={(file, FileList) =>
                uploadHandler(FileList, dataIndex)
              }
              showUploadList={false}
              multiple={true}
            >
              <Button className="upload">Upload Picture</Button>
            </Upload>

            {dataIndex < length ? (
              <Button
                className="save"
                onClick={() => saveHandler("edit", dataIndex)}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="save"
                onClick={() => saveHandler("save", dataIndex)}
              >
                Save
              </Button>
            )}

            <Button
              onClick={() => viewImageHandler(dataIndex)}
              className="save"
            >
              View All Images
            </Button>

            <FormModal
              title="Image List"
              existingFiles={
                items[imageIndex]?.file?.existing_files?.length > 0
                  ? items[imageIndex]?.file?.existing_files
                  : []
              }
              isVisible={items[imageIndex]?.file?.showFile}
              setIsVisible={() => {
                let arr = items;
                arr[imageIndex] = {
                  ...arr[imageIndex],
                  file: {
                    ...arr[imageIndex].file,
                    showFile: false,
                  },
                };
                setItems(arr);
                setImageIndex(-1);
              }}
              data={
                items[imageIndex]?.file?.files?.length > 0
                  ? items[imageIndex]?.file?.files
                  : []
              }
              setFormState={setItems}
              formState={items}
              index={imageIndex}
              imageType={imageType}
              uploadHandler={uploadHandler}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default InverterForm;
