import {
  GET_LOAN_LIST_FAILURE,
  GET_LOAN_LIST_LOADING,
  GET_LOAN_LIST_SUCCESS,
} from "../../actions/types";

const INITIAL_STATE = {
  loanList: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const getloanListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOAN_LIST_LOADING:
      return {
        ...state,
        loanList: {
          ...state.loanList,
          get: {
            ...state.loanList.get,
            loading: true,
            reset: false,
            success: {
              ...state.loanList.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_LOAN_LIST_SUCCESS:
      return {
        ...state,
        loanList: {
          ...state.loanList,
          get: {
            ...state.loanList.get,
            loading: false,
            reset: false,
            success: {
              ...state.loanList.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_LOAN_LIST_FAILURE:
      return {
        ...state,
        loanList: {
          ...state.loanList,
          get: {
            ...state.loanList.get,
            loading: false,
            reset: false,
            success: {
              ...state.loanList.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export default getloanListReducer;
