import React, { useContext, useEffect, useState } from "react";
import CardPercentage from "./CardPercentage";
import SiteVistContext from "./SiteVisitContext";
import { Col, Row, message } from "antd";
import "./proposed-location.style.less";
import ProposedLocationUpload from "./ProposedLocationUpload";
import FloatInputSuffix from "./FloatInputSuffix";
import InverterForm from "./InverterForm";
import { getSiteVisitDetails } from "../../actions/get-site-visit-action";
import { useDispatch, useSelector } from "react-redux";

function ProposedLocation({ siteVisitId }) {
  //  inverter Details
  const [inverter, setInverter] = useState(0);
  const [inverterData, setInverterData] = useState([]);
  const [inverterLength, setInverterLength] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  const siteVisitContext = useContext(SiteVistContext);
  const {
    proposedLocationDetails,
    setProposedLocationDetails,
    proposedLocationRef,
  } = siteVisitContext;

  const getInverterData = (array) => {
    return array.map((i, index) => {
      return {
        id: index + 1,
        building_id:
          i.inverter_type === "roof_id"
            ? parseInt(i.roof_id)
            : parseInt(i.rcc_id),
        inverter_type: i.inverter_type,
        file: {
          showFile: false,
          files: [],
          existing_files: i["files"].map((file, index) => {
            return file;
          }),
        },
      };
    });
  };

  useEffect(() => {
    if (getSiteVisitDetailsReducer.success.data) {
      let data = getSiteVisitDetailsReducer.success.data;
      setInverterLength(data?.inverters_proposed_locations_array?.length);
      setInverter(data?.inverters_proposed_locations_array?.length);
      setInverterData(
        getInverterData(data?.inverters_proposed_locations_array)
      );
      setProposedLocationDetails({
        inverters_proposed_locations_array:
          data?.inverters_proposed_locations_array,
        satellite_images: {
          files: [],
          existing_files: data?.proposed_locations["Satellite Images"],
          showFile: false,
        },
        proposed_location_of_acdb: {
          files: [],
          existing_files: data?.proposed_locations["Proposed Location of Acdb"],
          showFile: false,
        },
        proposed_location_of_earthing: {
          files: [],
          existing_files:
            data?.proposed_locations["Proposed Location of Earthing"],
          showFile: false,
        },
        proposed_ac_cable_routing: {
          files: [],
          existing_files: data?.proposed_locations["Proposed AC Cable Routing"],
          showFile: false,
        },
      });
    }
  }, [getSiteVisitDetailsReducer.success.data]);

  const imageTypes = [
    {
      name: "Satellite images showing roofs, proposed locations for inverters and ACDBs, cable routing, LT panel locations, and proposed locations for earth pits.",
      objKey: "satellite_images",
      title: "Satellite Images",
    },
    {
      name: "Proposed location for ACDB",
      objKey: "proposed_location_of_acdb",
      title: "Proposed Location of Acdb",
    },
    {
      name: "Proposed earthing location",
      objKey: "proposed_location_of_earthing",
      title: "Proposed Location of Earthing",
    },
    {
      name: "Proposed AC cable routing",
      objKey: "proposed_ac_cable_routing",
      title: "Proposed AC Cable Routing",
    },
  ];

  const incrementHandler = (key) => {
    const dataMap = {
      inverter_details: {
        data: inverterData,
        setData: setInverterData,
        count: inverter,
        length: inverterLength,
        setCount: setInverter,
        newItem: {
          building_id: "",
          inverter_type: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
    };

    const { data, setData, setCount, newItem, count, length } = dataMap[key];
    if (count > length) {
      message.warning("Please add a new one after saving the first one!");
      return;
    }
    const val = data.length === 0 ? 0 : data[data.length - 1].id;
    const newItemWithId = { ...newItem, id: val + 1 };

    setCount((prevCount) => prevCount + 1);
    setData([...data, newItemWithId]);
  };

  const decrementHandler = (key) => {
    const dataMap = {
      inverter_details: {
        count: inverter,
        setCount: setInverter,
        data: inverterData,
        setData: setInverterData,
      },
    };
    const { count, setCount, data, setData } = dataMap[key];
    if (count - 1 < inverterLength) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }
    if (count <= 1) {
      setData([]);
      setCount(0);
    } else {
      setCount(count - 1);
      setData(data.slice(0, -1));
    }
  };

  const handleValues = (value, key) => {
    if (value < inverterLength) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }
    const dataMap = {
      inverter_details: {
        setCount: setInverter,
        setData: setInverterData,
        data: inverterData,
        newItem: {
          building_id: "",
          inverter_type: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
    };

    const { setCount, setData, data, newItem } = dataMap[key];

    const arr = getInverterData(
      getSiteVisitDetailsReducer.success.data
        ?.inverters_proposed_locations_array
    );

    for (let i = data.length; i < value; i++) {
      arr.push({
        id: i + 1,
        ...newItem,
      });
    }

    setCount(value);
    setData(arr);
  };

  return (
    <div className="proposed-location-wrapper">
      <h1 className="upper-text">Proposed Location</h1>
      <div
        className="card-container"
        ref={(element) => {
          proposedLocationRef.current[0] = element;
        }}
      >
        <Row justify="center">
          <Col span={22}>
            <CardPercentage
              cardName="Inverter Details"
              items={[
                inverter,
                proposedLocationDetails?.inverters_proposed_locations_array,
              ]}
              title="Inverter Details"
            />
            <FloatInputSuffix
              label="No of Inverters"
              placeholder="No of Inverters*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "inverter_details")
              }
              value={inverter}
              objKey="inverter_details"
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="Inverter Details"
            />
            {inverterData.map((i, index) => {
              return (
                <InverterForm
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={inverterData}
                  setItems={setInverterData}
                  title="Inverter"
                  imageType="is_proposed_inverter_location_image"
                  siteVisitId={siteVisitId}
                  length={inverterLength}
                />
              );
            })}
          </Col>
        </Row>
      </div>
      {imageTypes.map((i, index) => {
        return (
          <div
            className="card-container"
            ref={(element) => {
              proposedLocationRef.current[index + 1] = element;
            }}
            key={index}
          >
            <Row justify="center">
              <Col span={22}>
                <CardPercentage
                  cardName={i.name}
                  title={i.name}
                  items={[proposedLocationDetails[i.objKey]?.existing_files]}
                />
                <ProposedLocationUpload
                  state={proposedLocationDetails}
                  setState={setProposedLocationDetails}
                  objKey={i.objKey}
                  name={i.name}
                  title={i.title}
                  siteVisitId={siteVisitId}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}

export default ProposedLocation;
