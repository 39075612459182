import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { NavLink, Link } from "react-router-dom";
// import TmdbIcon from '../../assets/tmdb-power.png';
import "./sidebar.style.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Sidebar = (props) => {
  const [expandedLinks, setExpandedLinks] = useState(false);
  const [selectedLogin, setSelectedLogin] = useState("");

  const toggleLinks = () => {
    setExpandedLinks(!expandedLinks);
  };

  useEffect(() => {
    setExpandedLinks(false);
    if (props.resetSidebarFlag) {
      props.resetSidebarFlag();
    }
  }, [props.closeSidebarAction === true]);

  const menu = (
    <Menu style={{ width: "21rem", textAlign: "right" }}>
      <Menu.Item
        onClick={() => setSelectedLogin("Project Marketplace")}
        key="0"
        style={{ padding: "8.5% 5%" }}
      >
        <div className="mi-project-marketplace">
          <p
            style={
              selectedLogin === "Project Marketplace"
                ? { color: "#3d87f1" }
                : null
            }
          >
            Project Marketplace
          </p>
          <img
            src="/images/Landing_page_mobile/login_dropdown_icons/Project Marketplace.svg"
            alt="Project Marketplace Icon"
          />
        </div>
        <div className="mi-link-section">
          <Link
            style={{ color: "#3d87f1" }}
            to={{
              pathname: "/logIn",
              loginProps: { selectedLogin: "Project MarketPlace" },
            }}
          >
            Sign In
          </Link>
          <Link style={{ color: "#3d87f1", margin: "0 0 0 5%" }} to="/signup">
            Sign up
          </Link>
        </div>
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => setSelectedLogin("Component Marketplace")}
        key="1"
        style={{ padding: "8.5% 5%" }}
      >
        <div className="mi-project-marketplace">
          <p
            style={
              selectedLogin === "Component Marketplace"
                ? { color: "#3d87f1" }
                : null
            }
          >
            Component Marketplace
          </p>
          <img
            src="/images/Landing_page_mobile/login_dropdown_icons/Component Marketplace.svg"
            alt="Project Marketplace Icon"
          />
        </div>
        <div className="mi-link-section">
          <Link
            style={{ color: "#3d87f1" }}
            to={{
              pathname: "/logIn",
              loginProps: { selectedLogin: "Component Marketplace" },
            }}
          >
            Sign In
          </Link>
          <Link style={{ color: "#3d87f1", margin: "0 0 0 5%" }} to="/signup">
            Sign up
          </Link>
        </div>
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item
        onClick={() => setSelectedLogin("Project Management Tool")}
        key="2"
        style={{ padding: "8.5% 5%" }}
      >
        <div className="mi-project-marketplace">
          <p
            style={
              selectedLogin === "Project Management Tool"
                ? { color: "#3d87f1" }
                : null
            }
          >
            Solar Flow
          </p>
          <img
            src="/images/Landing_page_mobile/login_dropdown_icons/Project Management Tool.svg"
            alt="Project Marketplace Icon"
          />
        </div>
        <div className="mi-link-section">
          <Link
            style={{ color: "#3d87f1" }}
            to={{
              pathname: "/logIn",
              loginProps: { selectedLogin: "Project Management Tool" },
            }}
          >
            Sign In
          </Link>
          <Link style={{ color: "#3d87f1", margin: "0 0 0 5%" }} to="/signup">
            Sign up
          </Link>
        </div>
      </Menu.Item>
    </Menu>
  );

  const productMenu = (
    <Menu style={{ width: "18.2rem" }}>
      <Menu.Item key="0" style={{ padding: "7% 5%" }}>
      <a
          onClick={() => setExpandedLinks(false)}
          href="https://safearth.in/"
          target="_blank"
          style={{ fontSize: "1.4rem", fontWeight: "600", color: "#2d2d2d" }}
        >
          Project Marketplace
        </a>
      </Menu.Item>
      <Menu.Item key="1" style={{ padding: "7% 5%" }}>
      <Link
          onClick={() => setExpandedLinks(false)}
          to="/login"
          style={{ fontSize: "1.4rem", fontWeight: "600", color: "#2d2d2d" }}
        >
          Project Management Tool
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" style={{ padding: "7% 5%" }}>
        <a
          onClick={() => setExpandedLinks(false)}
          href="https://ecom.safearth.in/"
          target="_blank"
          style={{ fontSize: "1.4rem", fontWeight: "600", color: "#2d2d2d" }}
        >
          Component Marketplace
        </a>
      </Menu.Item>
    </Menu>
  );

  const location = useLocation();

  if (
    location &&
    (location?.pathname === "/" ||
      location?.pathname?.includes("signup") ||
      location?.pathname === "/financing" ||
      location?.pathname === "/request-financing")
  ) {
    return null;
  }

  return (
    <div className="mobile-navigation-container">
      <ul style={props.style} className="sidebar-top">
        <div className="sidebar-links">
          <li className="sidebar-link bars" onClick={toggleLinks}>
            <FontAwesomeIcon
              style={{ "margin-left": "1.4rem", width: "2.8rem" }}
              icon={faBars}
            />
          </li>
          <li
            className="sidebar-link-logo"
            onClick={() => setExpandedLinks(false)}
          >
            <NavLink to="/">
              <img
                src="/images/Landing_page_mobile/SafEarth Logo.svg"
                alt="logo"
              />
            </NavLink>
          </li>
          <li className="sidebar-link-login">
            {/* <Dropdown overlay={menu} trigger={["click"]}> */}
            <Link
              to="/login"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              Login
            </Link>
            {/* </Dropdown> */}
          </li>
        </div>
      </ul>
      <CSSTransition
        in={expandedLinks}
        timeout={300}
        classNames="visible"
        unmountOnExit
      >
        <div className="sidebar-links-container">
          <li className="sidebar-link" onClick={() => setExpandedLinks(false)}>
            <Link to="/">
              <img
                src="/images/Landing_page_mobile/SafEarth Logo.svg"
                alt="Safearth Logo"
              />
            </Link>
          </li>
          <li className="sidebar-link sidebar-link-products-section">
            <Dropdown overlay={productMenu} trigger={["click"]}>
              <Link style={{ color: "#ffffff", width: "100%" }}>
                Products{" "}
                <DownOutlined
                  style={{ fontSize: "1.4rem", padding: "0 0 0 1rem" }}
                />
              </Link>
            </Dropdown>
          </li>

          <li className="sidebar-link" onClick={() => setExpandedLinks(false)}>
            <a
              style={{ color: "#ffffff", textDecoration: "none" }}
              target="_blank"
              href="https://safearth.in/calculator"
            >
              Calculator
            </a>
          </li>
          <li className="sidebar-link" onClick={() => setExpandedLinks(false)}>
            <a
              style={{ color: "#ffffff", textDecoration: "none" }}
              target="_blank"
              href="https://blog.safearth.in/"
            >
              Blog
            </a>
          </li>
          <li className="sidebar-link" onClick={() => setExpandedLinks(false)}>
            <a
              style={{ color: "#ffffff", textDecoration: "none" }}
              target="_blank"
              href="https://safearth.in/team"
            >
              Team
            </a>
          </li>
          <li className="sidebar-link" onClick={() => setExpandedLinks(false)}>
            <a
              style={{ color: "#ffffff", textDecoration: "none" }}
              target="_blank"
              href="https://safearth.in/contactUs"
            >
              Contact Us
            </a>
          </li>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Sidebar;
