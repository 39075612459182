import {
    GET_APPROVAL_LOADING,
    GET_APPROVAL_SUCCESS,
    GET_APPROVAL_FAILURE,
    GET_APPROVAL_RESET
} from '../../actions/types';

const INITIAL_STATE = {
    approval: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};

const approvalReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_APPROVAL_LOADING:
            return {
                ...state,
                approval: {
                    ...state.approval,
                    get: {
                        ...state.approval.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.approval.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_APPROVAL_SUCCESS:
            return {
                ...state,
                approval: {
                    ...state.approval,
                    get: {
                        ...state.approval.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.approval.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_APPROVAL_FAILURE:
            return {
                ...state,
                approval: {
                    ...state.approval,
                    get: {
                        ...state.approval.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.approval.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case GET_APPROVAL_RESET:
            return {
                ...state,
                approval: {
                    ...state.approval.get,
                    post: {
                        ...INITIAL_STATE.approval.get,
                        reset: true,
                    },
                },
            }
    }
    return state;
}

export default approvalReducers;