import React, { useEffect, useState } from "react";
import FloatInputSuffix from "./FloatInputSuffix";
import { Button, Col, Row, Select, Upload, message } from "antd";
import "./electrical-details.style.less";
import FormModal from "./FormModal";
import {
  buildingAreaApi,
  electricalDetailsApi,
} from "../../actions/get-site-visit-action";
import { useDispatch } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const { Option } = Select;

function MultipleForms({
  data,
  items,
  setItems,
  title,
  imageType,
  siteVisitId,
  length,
  type,
  dataIndex,
  isBuildingAndArea,
  fields,
  typeOptions,
  emptyFeilds,
  updateApiKey,
  laIndex,
}) {
  const [imageIndex, setImageIndex] = useState(-1);
  const [isCollapsed, setIsCollapsed] = useState(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsCollapsed(items?.length - 1);
  }, []);

  const deleteHandler = (index) => {
    if (index < length) {
      const formData = new FormData();
      formData.append("index", index);
      formData.append("site_visit_id", siteVisitId);
      formData.append("type", type);
      if (isBuildingAndArea) {
        formData.append("la_index", laIndex);
        if (title === "LA" && type === "rcc_building_la_details") {
          dispatch(
            buildingAreaApi(
              formData,
              "delete",
              siteVisitId,
              "rcc_la_index",
              laIndex
            )
          );
        } else if (title === "LA" && type === "metal_sheet_la_details") {
          dispatch(
            buildingAreaApi(
              formData,
              "delete",
              siteVisitId,
              "metal_la_index",
              laIndex
            )
          );
        } else {
          dispatch(buildingAreaApi(formData, "delete", siteVisitId));
        }
      } else {
        dispatch(electricalDetailsApi(formData, "delete", siteVisitId));
      }
    } else {
      let arr = items;
      arr[index] = { ...emptyFeilds, id: index + 1 };
      setItems([...arr]);
    }
  };

  const saveHandler = (btnType, index) => {
    // Checking if all the feilds are filled
    let isValid = fields.every((field) => items[index][field.name]);
    if (isValid) {
      const formData = new FormData();
      formData.append("site_visit_id", siteVisitId);
      let { file, ...objWithoutFile } = items[index];
      formData.append(type, JSON.stringify(objWithoutFile));
      for (let i = 0; i < items[index]?.file?.files?.length; i++) {
        formData.append(updateApiKey, items[index]?.file?.files[i]);
      }
      if (btnType === "edit") {
        formData.append("index", index);
        if (isBuildingAndArea) {
          formData.append("la_index", laIndex);

          if (title === "LA" && type === "rcc_building_la_details") {
            dispatch(
              buildingAreaApi(
                formData,
                "edit",
                siteVisitId,
                "rcc_la_index",
                laIndex
              )
            );
          } else if (title === "LA" && type === "metal_sheet_la_details") {
            dispatch(
              buildingAreaApi(
                formData,
                "edit",
                siteVisitId,
                "metal_la_index",
                laIndex
              )
            );
          } else {
            dispatch(buildingAreaApi(formData, "edit", siteVisitId));
          }
        } else {
          dispatch(electricalDetailsApi(formData, "edit", siteVisitId));
        }
      } else {
        if (isBuildingAndArea) {
          formData.append("la_index", laIndex);
          if (title === "LA" && type === "rcc_building_la_details") {
            dispatch(
              buildingAreaApi(
                formData,
                "add",
                siteVisitId,
                "rcc_la_index",
                laIndex
              )
            );
          } else if (title === "LA" && type === "metal_sheet_la_details") {
            dispatch(
              buildingAreaApi(
                formData,
                "add",
                siteVisitId,
                "metal_la_index",
                laIndex
              )
            );
          } else {
            dispatch(buildingAreaApi(formData, "add", siteVisitId));
          }
        } else {
          dispatch(electricalDetailsApi(formData, "add", siteVisitId));
        }
      }
    } else {
      message.warning("Please select all the fields");
    }
  };

  const handleChange = (field, value) => {
    let arr = items;
    arr[dataIndex] = {
      ...arr[dataIndex],
      [field]: value,
    };
    setItems([...arr]);
  };

  const viewImageHandler = (index) => {
    setImageIndex(index);
    let arr = items;
    arr[index] = {
      ...arr[index],
      file: {
        ...arr[index].file,
        showFile: true,
      },
    };
    setItems([...arr]);
  };

  const uploadHandler = (filelist, index) => {
    let newItems = [...items];
    setImageIndex(index);
    newItems[index] = {
      ...newItems[index],
      file: {
        ...newItems[index].file,
        files: [...newItems[index].file?.files, ...filelist],
      },
    };
    setItems(newItems);
  };

  return (
    <div>
      <div
        className={isCollapsed === dataIndex ? "collapse-active" : "collapse"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <h1 style={{ margin: 0 }} className="collapse-text">
            {title} {data.id}
          </h1>
          {Object.values(items[dataIndex])?.some((val) => val) && (
            <span
              className="delete-icon"
              onClick={() => deleteHandler(dataIndex)}
            >
              x
            </span>
          )}
        </div>
        {isCollapsed === dataIndex ? (
          <UpOutlined onClick={() => setIsCollapsed(-1)} />
        ) : (
          <DownOutlined onClick={() => setIsCollapsed(dataIndex)} />
        )}
      </div>
      {isCollapsed === dataIndex && (
        <>
          {fields?.map((field) => (
            <Row key={field.name}>
              <Col span={24}>
                {field.type === "input" ? (
                  <FloatInputSuffix
                    label={field.label}
                    placeholder={field.placeholder}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    value={items[dataIndex][field.name]}
                    required={field.required}
                    suffix={field.suffix}
                  />
                ) : (
                  field.type === "select" && (
                    <Select
                      allowClear
                      showArrow
                      className="select"
                      placeholder={field.placeholder}
                      value={items[dataIndex][field.name] || null}
                      onChange={(value) => handleChange(field.name, value)}
                    >
                      {typeOptions?.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  )
                )}
              </Col>
            </Row>
          ))}
          <div className="buttons-grp">
            <Upload
              accept=".jpg,.jpeg,.png,.heic"
              name="file"
              beforeUpload={(file, FileList) =>
                uploadHandler(FileList, dataIndex)
              }
              showUploadList={false}
              multiple={true}
            >
              <Button className="upload">Upload Picture</Button>
            </Upload>

            {dataIndex < length ? (
              <Button
                className="save"
                onClick={() => saveHandler("edit", dataIndex)}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="save"
                onClick={() => saveHandler("save", dataIndex)}
              >
                Save
              </Button>
            )}
            <Button
              onClick={() => viewImageHandler(dataIndex)}
              className="save"
            >
              View All Images
            </Button>

            <FormModal
              title="Image List"
              existingFiles={
                items[imageIndex]?.file?.existing_files?.length > 0
                  ? items[imageIndex]?.file?.existing_files
                  : []
              }
              isVisible={items[imageIndex]?.file?.showFile}
              setIsVisible={() => {
                let arr = items;
                arr[imageIndex] = {
                  ...arr[imageIndex],
                  file: {
                    ...arr[imageIndex].file,
                    showFile: false,
                  },
                };
                setItems(arr);
                setImageIndex(-1);
              }}
              data={
                items[imageIndex]?.file?.files?.length > 0
                  ? items[imageIndex]?.file?.files
                  : []
              }
              setFormState={setItems}
              formState={items}
              laIndex={laIndex}
              index={imageIndex}
              imageType={imageType}
              uploadHandler={uploadHandler}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MultipleForms;
