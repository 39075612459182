import * as Actions from "../types";
import axios from "axios";
import { message } from "antd";
import { getItem } from "../../SecureStorage";

export const getEPCs = () => (dispatch) => {
    let userData;

    if (getItem("userData")) {
        userData = JSON.parse(getItem("userData"));
    }

    dispatch({ type: Actions.GET_EPCS_LOADING, payload: null });

    axios
        .get(`${process.env.REACT_APP_BASE_URL}get_epc`, {
            headers: {
                Authorization: "Token " + userData.authToken,
            },
        })
        .then((result) => {
            dispatch({ type: Actions.GET_EPCS_SUCCESS, payload: result.data });
        })
        .catch((err) => {
            dispatch({
                type: Actions.GET_EPCS_FAILURE,
                payload: { message: err.message },
            });
            return message.error('Some error occurred while retrieving EPCs!');
        });
};

export const assignEpc = (data) => async (dispatch) => {

    let userData;

    if (getItem('userData')) {
        userData = JSON.parse(getItem('userData'));
    }

    dispatch({ type: Actions.ASSIGN_EPCS_LOADING, payload: data })

    await message.loading('Saving...', 1);

    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}assign_epc_to_project/`, data, {
            headers: {
                Authorization: 'Token ' + userData.authToken
            }
        });
        if (result?.data?.status === "FAIL") {
            throw result.data;
        }
        dispatch({ type: Actions.ASSIGN_EPCS_SUCCESS, payload: result.data });
        await message.success('EPC assigned successfully!', 1);
        return result;
    }
    catch (e) {
        if (e?.status === "FAIL") {
            message.error(e.response, 2);
        }
        else {
            message.error('Error occurred while assigning EPC!', 1);
        }
        dispatch({ type: Actions.ASSIGN_EPCS_FAILURE, payload: e });
        return e;
    }
};

export const updateEpc = (data) => async (dispatch) => {

    let userData;

    if (getItem('userData')) {
        userData = JSON.parse(getItem('userData'));
    }

    dispatch({ type: Actions.UPDATE_EPC_LOADING, payload: data })

    await message.loading('Saving...', 1);

    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}update_epc_details/`, data, {
            headers: {
                Authorization: 'Token ' + userData.authToken
            }
        });
        if (result?.data?.status === "FAIL") {
            throw result.data;
        }
        dispatch({ type: Actions.UPDATE_EPC_SUCCESS, payload: result.data });
        await message.success('EPC updated successfully!', 1);
        return result;
    }
    catch (e) {
        if (e?.status === "FAIL") {
            message.error(e.response, 2);
        }
        else {
            message.error('Error occurred while updating EPC!', 1);
        }
        dispatch({ type: Actions.UPDATE_EPC_FAILURE, payload: e });
        return e;
    }
};

export const getBasicEPCs = () => (dispatch) => {
    let userData;

    if (getItem("userData")) {
        userData = JSON.parse(getItem("userData"));
    }

    dispatch({ type: Actions.GET_BASIC_EPCS_LOADING, payload: null });

    axios
        .get(`${process.env.REACT_APP_BASE_URL}get_epc`, {
            headers: {
                Authorization: "Token " + userData.authToken,
            },
            params: {
                is_basic: true
            }
        })
        .then((result) => {
            dispatch({ type: Actions.GET_BASIC_EPCS_SUCCESS, payload: result.data });
        })
        .catch((err) => {
            dispatch({
                type: Actions.GET_BASIC_EPCS_FAILURE,
                payload: { message: err.message },
            });
            return message.error('Error occurred while retrieving basic EPCs!');
        });
};

export const addEPCTeamMember = (data) => async (dispatch) => {

    let userData;

    if (getItem('userData')) {
        userData = JSON.parse(getItem('userData'));
    }

    dispatch({ type: Actions.ADD_EPC_TEAM_MEMBER_LOADING, payload: data })

    await message.loading('Saving...', 1);

    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}add_epc_team_member/`, data, {
            headers: {
                Authorization: 'Token ' + userData.authToken
            }
        });
        if (result?.data?.status === "FAIL") {
            throw result.data;
        }
        dispatch({ type: Actions.ADD_EPC_TEAM_MEMBER_SUCCESS, payload: result.data });
        await message.success('Team member added to EPC successfully!', 1);
        return result;
    }
    catch (e) {
        if (e?.status === "FAIL") {
            message.error(e.response, 2);
        }
        else {
            message.error('Error occurred while adding team member to EPC!', 1);
        }
        dispatch({ type: Actions.ADD_EPC_TEAM_MEMBER_FAILURE, payload: e });
        return e;
    }
};
export const assignEPCTeam = (data) => async (dispatch) => {

    let userData;

    if (getItem('userData')) {
        userData = JSON.parse(getItem('userData'));
    }

    dispatch({ type: Actions.ASSIGN_EPC_TEAM_LOADING, payload: data })

    await message.loading('Saving...', 1);

    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}assign_epc_team_member_role/`, data, {
            headers: {
                Authorization: 'Token ' + userData.authToken
            }
        });
        if (result?.data?.status === "FAIL") {
            throw result.data;
        }
        dispatch({ type: Actions.ASSIGN_EPC_TEAM_SUCCESS, payload: result.data });
        await message.success('EPC updated successfully!', 1);
        return result;
    }
    catch (e) {
        if (e?.status === "FAIL") {
            message.error(e.response, 2);
        }
        else {
            message.error('Error occurred while assigning team to EPC!', 1);
        }
        dispatch({ type: Actions.ASSIGN_EPC_TEAM_FAILURE, payload: e });
        return e;
    }
};

export const getEPCTeamMembers = (epc_id) => (dispatch) => {
    let userData;

    if (getItem("userData")) {
        userData = JSON.parse(getItem("userData"));
    }

    dispatch({ type: Actions.GET_EPC_TEAM_MEMBERS_LOADING, payload: null });

    axios
        .get(`${process.env.REACT_APP_BASE_URL}get_epc_team_members/`, {
            headers: {
                Authorization: "Token " + userData.authToken,
            },
            params: {
                epc_id: epc_id
            }
        })
        .then((result) => {
            dispatch({ type: Actions.GET_EPC_TEAM_MEMBERS_SUCCESS, payload: result.data });
        })
        .catch((err) => {
            dispatch({
                type: Actions.GET_EPC_TEAM_MEMBERS_FAILURE,
                payload: { message: err.message },
            });
            return message.error('Error occurred while retrieving EPC Team Members!');
        });
};
