import { Modal, Row, Col, Button, Upload } from "antd";
import React from "react";
import "./image-modal.style.less";
import { deleteFile } from "../../actions/get-site-visit-action";
import { useDispatch } from "react-redux";

function ImageModal({
  title,
  isVisible,
  setIsVisible,
  data,
  setFormState,
  formState,
  objectKey,
  fileType,
  existingFiles,
  imageIndex,
}) {
  const handleOk = () => {};
  const handleCancel = () => {
    setIsVisible(false);
  };

  const dispatch = useDispatch();

  const uploadProps = {
    showUploadList: false,
    multiple: true,
    beforeUpload: (file, FileList) => {
      setFormState({
        ...formState,
        [objectKey]: { ...formState?.[objectKey], files: [...FileList] },
      });
      return false;
    },
    accept: ".jpg,.jpeg,.svg,.png",
  };

  const handleRemove = (id) => {
    const temp = data.filter((i) => i.uid !== id);
    setFormState({
      ...formState,
      [objectKey]: { ...formState?.[objectKey], files: temp },
    });
  };
  return (
    <Modal
      title={title}
      className="image-modal"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {data?.length == 0 && existingFiles?.length === 0 ? (
        <>
          <h1
            style={{
              color: "#3d87f1",
              fontSize: "2rem",
              textAlign: "center",
              margin: "3rem 0",
            }}
          >
            No Images
          </h1>
        </>
      ) : (
        <div style={{ height: "calc(30vh)", overflow: "auto" }}>
          {" "}
          {existingFiles?.map((i, index) => {
            return (
              <div className="image-row">
                <Row key={index}>
                  <Col span={6}>
                    <img src={i.file} alt={i.file} />
                  </Col>
                  <Col span={15}>
                    <h1>File - {index + 1}</h1>
                  </Col>
                  <Col span={3}>
                    <img
                      src={require("../../assets/logos/Remove.png")}
                      style={{ width: "2rem" }}
                      onClick={() => {
                        const formData = new FormData();
                        formData.append("file_id", i.id);
                        if (fileType) {
                          formData.append(`${fileType}`, true);
                        }
                        if (imageIndex >= 0) {
                          formData.append("index", imageIndex);
                        }
                        dispatch(deleteFile(formData));
                        setFormState({
                          ...formState,
                          [objectKey]: {
                            ...formState[objectKey],
                            existing_files: existingFiles?.filter(
                              (x) => x.id !== i.id
                            ),
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
          {data?.map((i, index) => {
            return (
              <div className="image-row">
                <Row key={index}>
                  <Col span={6}>
                    <img src={URL?.createObjectURL(i)} alt={i.name} />
                  </Col>
                  <Col span={15}>
                    <h1>{i.name}</h1>
                  </Col>
                  <Col span={3}>
                    <img
                      src={require("../../assets/logos/Remove.png")}
                      style={{ width: "2rem" }}
                      onClick={() => handleRemove(i.uid)}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      )}
      <Row gutter={[8, 8]} justify="center">
        <Col span={12}>
          <Upload {...uploadProps}>
            <Button className="upload">Upload Picture</Button>
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
}

export default ImageModal;
