import {
    GET_GANTTS_LOADING,
    GET_GANTTS_SUCCESS,
    GET_GANTTS_FAILURE,
    GET_GANTTS_RESET
} from '../../actions/types';

const INITIAL_STATE = {
    gantts: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
    },
};

const ganttReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_GANTTS_LOADING:
            return {
                ...state,
                gantts: {
                    ...state.gantts,
                    get: {
                        ...state.gantts.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.gantts.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_GANTTS_SUCCESS:
            return {
                ...state,
                gantts: {
                    ...state.gantts,
                    get: {
                        ...state.gantts.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.gantts.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_GANTTS_FAILURE:
            return {
                ...state,
                gantts: {
                    ...state.gantts,
                    get: {
                        ...state.gantts.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.gantts.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case GET_GANTTS_RESET:
            return {
                ...state,
                gantts: {
                    ...state.gantts.get,
                    get: {
                        ...INITIAL_STATE.gantts.get,
                        reset: true,
                    },
                },
            }
    }
    return INITIAL_STATE;
}

export default ganttReducers;