import { Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useOnlineStatus from "./useOnlineStatus";

function NoInternet() {
  const isOnline = useOnlineStatus();
  const history = useHistory();

  useEffect(() => {
    checkOnline();
  }, []);
  const checkOnline = () => {
    if (isOnline) {
      history.push("/");
      
    } else {
      history.push("/no-internet");
    }
  };
  return (
    <div
      style={{
        height: "calc(100vh - 60px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        className="main-header-left-icon"
        src={require("../assets/logos/Blue.png")}
        alt="SolarFlow"
        style={{ height: "15rem", width: "15rem" }}
      />
      <p
        style={{
          color: "#3d87f1",
          fontSize: "2rem",
          fontFamily: "SourceSansPro",
        }}
      >
        Oops!
      </p>
      <p
        style={{
          color: "#8f8f8f",
          fontSize: "1.5rem",
          fontFamily: "SourceSansPro",
        }}
      >
        Slow or no internet connect found.
      </p>
      <p
        style={{
          color: "#8f8f8f",
          fontSize: "1.5rem",
          fontFamily: "SourceSansPro",
        }}
      >
        Please check your internet settings.
      </p>
      <Button
        style={{
          color: "#fff",
          backgroundColor: "#3d87f1",
          borderRadius: "5px",
          fontSize: "1.5rem",
          width: "12rem",
        }}
        onClick={() => checkOnline()}
      >
        Retry
      </Button>
    </div>
  );
}

export default NoInternet;
