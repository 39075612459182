import {
  GET_GANTT_EVENT_STAGE_LOADING,
  GET_GANTT_EVENT_STAGE_SUCCESS,
  GET_GANTT_EVENT_STAGE_FAILURE,
  GET_GANTT_EVENT_STAGE_RESET,
  GET_GANTT_EVENT_STAGE_INITIAL_VALUES,
  UPDATE_GANTT_EVENT_STAGE,
} from "../../actions/types";

const INITIAL_STATE = {
  gantteventstage: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const ganttEventStageReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GANTT_EVENT_STAGE_LOADING:
      return {
        ...state,
        gantteventstage: {
          ...state.gantteventstage,
          get: {
            ...state.gantteventstage,
            loading: true,
            reset: false,
            success: {
              ...state.gantteventstage.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_GANTT_EVENT_STAGE_SUCCESS:
      return {
        ...state,
        gantteventstage: {
          ...state.gantteventstage,
          get: {
            ...state.gantteventstage.get,
            loading: false,
            reset: false,
            success: {
              ...state.gantteventstage.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_GANTT_EVENT_STAGE_FAILURE:
      return {
        ...state,
        gantteventstage: {
          ...state.gantteventstage,
          get: {
            ...state.gantteventstage,
            loading: false,
            reset: false,
            success: {
              ...state.gantteventstage.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_GANTT_EVENT_STAGE_RESET:
      return {
        ...state,
        gantteventstage: {
          ...state.gantteventstage.get,
          get: {
            ...INITIAL_STATE.gantteventstage.get,
            reset: true,
          },
        },
      };
    case GET_GANTT_EVENT_STAGE_INITIAL_VALUES:
      return state;
    case UPDATE_GANTT_EVENT_STAGE:
      return {
        ...state,
        gantteventstage: {
          ...state.gantteventstage,
          get: {
            ...state.gantteventstage.get,
            loading: false,
            reset: false,
            success: {
              ...state.gantteventstage.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
  }
  return state;
};

export default ganttEventStageReducers;
