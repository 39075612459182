import {
    GET_SITE_VISIT_DETAILS_FAILURE,
    GET_SITE_VISIT_DETAILS_LOADING,
    GET_SITE_VISIT_DETAILS_SUCCESS,
  } from "../../actions/types";
  
  const initialState = {
    siteVisitDetails: {
      get: {
        loading: false,
        success: {
          ok: false,
          data: null,
        },
        failure: {
          error: false,
          message: "",
        },
      },
    },
  };
  
  const getSiteVisitDetailsReducers = (state = initialState, action) => {
    switch (action.type) {
      case GET_SITE_VISIT_DETAILS_LOADING:
        return {
          ...state,
          siteVisitDetails: {
            ...state.siteVisitDetails,
            get: {
              ...state.siteVisitDetails.get,
              loading: true,
              reset: false,
              success: {
                ...state.siteVisitDetails.get.success,
                ok: false,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
  
      case GET_SITE_VISIT_DETAILS_SUCCESS:
        return {
          ...state,
          siteVisitDetails: {
              ...state.siteVisitDetails,
              get: {
                  ...state.siteVisitDetails.get,
                  loading: false,
                  reset: false,
                  success: {
                      ...state.siteVisitDetails.get.success,
                      ok: true,
                      data: action.payload,
                  },
                  failure: {
                      error: false,
                      message: '',
                  },
              },
          },
        };
      case GET_SITE_VISIT_DETAILS_FAILURE:
        return {
          ...state,
          siteVisitDetails: {
              ...state.siteVisitDetails,
              get: {
                  ...state.siteVisitDetails.get,
                  loading: false,
                  reset: false,
                  success: {
                      ...state.siteVisitDetails.get.success,
                      ok: false,
                      data: null,
                  },
                  failure: {
                      error: true,
                      message: action.payload.message,
                  },
              },
          },
        };
      default:
        return state;
    }
  };
  
  export default getSiteVisitDetailsReducers;
  