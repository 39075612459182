import {
    ACTIVATE_USER_FAILURE,
    ACTIVATE_USER_SUCCESS,
    ACTIVATE_USER_LOADING,
  } from "../../actions/types";
  
  const INITIAL_STATE = {
    activate: {
      post: {
        loading: false,
        reset: false,
        success: {
          ok: false,
          data: null,
        },
        failure: {
          error: false,
          message: "",
        },
      },
    },
  };
  
  const activateReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ACTIVATE_USER_LOADING:
        return {
          ...state,
          activate: {
            ...state.activate,
            post: {
              ...state.activate.post,
              loading: true,
              reset: false,
              success: {
                ...state.activate.post.success,
                ok: false,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
      case ACTIVATE_USER_SUCCESS:
        return {
          ...state,
          activate: {
            ...state.activate,
            post: {
              ...state.activate.post,
              loading: false,
              reset: false,
              success: {
                ...state.activate.post.success,
                ok: true,
                data: action.payload,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
      case  ACTIVATE_USER_FAILURE:
        return {
          ...state,
          activate: {
            ...state.activate,
            post: {
              ...state.activate.post,
              loading: false,
              reset: false,
              success: {
                ...state.activate.post.success,
                ok: false,
                data: null,
              },
              failure: {
                error: true,
                message: action.payload.message,
              },
            },
          },
        };
    }
    return INITIAL_STATE;
  };
  
  export default activateReducers;
  