export const electricalDetailsList = [
  {
    id: 0,
    name: "Basic Details",
    items: [
      "solar_pv_interconnected_voltage",
      "contracted_load",
      "grid_voltage_level",
    ],
  },
  {
    id: 1,
    name: "Express/Dedicated Line",
    items: ["express_dedicated_line"],
  },

  {
    id: 2,
    name: "Transformer Details",
    items: ["transformer_details_array"],
  },
  {
    id: 3,
    name: "DG Details",
    items: ["dg_details_array", "dg_synchronisation_panel"],
  },
  {
    id: 4,
    name: "LT Panel Details",
    items: ["lt_panel_details_array"],
  },

  {
    id: 5,
    name: "Are the Spare Feeders available ?",
    items: "check",
    item: "is_spare_feeder",
    item1: ["spare_feeder_details_array", "is_spare_feeder"],
    item2: ["is_spare_feeder"],
  },
  {
    id: 6,
    name: "is Vacant Chamber available ?",
    items: "check",
    item: "vacant_chamber_available",
    item1: ["vacant_chamber_available", "vacant_chamber_image"],
    item2: ["vacant_chamber_available"],
  },
  {
    id: 7,
    name: "is Bus Bar extension allowed ?",
    items: "check",
    item: "bus_bar_extension_allowed",
    item1: ["bus_bar_extension_allowed", "bus_bar_extension_image"],
    item2: ["bus_bar_extension_allowed"],
  },
  {
    id: 8,
    name: "Capacitor Bank Rating",
    items: ["capacitor_bank_rating"],
  },
  {
    id: 9,
    name: "Cabling Mode Available",
    items: ["cabling_mode"],
  },

  {
    id: 10,
    name: "Machinery Type",
    items: ["type_of_machinery"],
  },

  {
    id: 11,
    name: "Load Type",
    items: ["type_of_load"],
  },

  {
    id: 12,
    name: "Electrical Load Details",
    items: [
      "load_shedding",
      "working_avg_load",
      "holiday_avg_load",
      "non_working_days_nos",
    ],
  },
  {
    id: 13,
    name: "Net Metering Requirement",
    items: ["net_meter_requirement"],
  },
  {
    id: 14,
    name: "Internet Availability",
    items: ["internet_availability"],
  },
  {
    id: 15,
    name: "Single Phase Supply Availability",
    items: ["single_phase_supply_availability"],
  },
];

export const rccBasicBuildings = [
  {
    id: 0,
    name: "Building/Area Details",
  },
  {
    id: 1,
    name: "Basic Building Details",
    items: ["building_age", "rooftop_image"],
  },
  {
    id: 2,
    name: "Roof Details",
    items: [
      "building_height",
      "building_height_image",
      "is_parapet_wall",
      "is_existing_columns",
      "is_water_proofing_layer",
    ],
  },
  {
    id: 3,
    name: "Access",
    items: ["roof_access", "roof_access_type"],
  },
  {
    id: 4,
    name: "Obstructions on the roof top",
    items: "check",
    item: "any_trees_surrounding_building",
    item1: [
      "is_chimney_on_rooftop",
      "any_trees_surrounding_building",
      "any_trees_surrounding_building_image",
      "tree_height",
      "any_shadow_causing_objects",
    ],
    item2: [
      "is_chimney_on_rooftop",
      "any_trees_surrounding_building",
      "any_trees_surrounding_building_image",
      "any_shadow_causing_objects",
    ],
  },

  {
    id: 5,
    name: "Dust,Heat and Smoke impact on rooftop",
    items: ["dust_impact", "smoke_impact", "heat_impact", "chemical_type"],
  },
  {
    id: 6,
    name: "LA Details",
    items: "check",
    item: "existing_la",
    item1: ["rcc_building_la_detail_array", "existing_la"],
    item2: ["existing_la"],
  },
  {
    id: 7,
    name: "Water availability for O&M",
    items: ["is_water_tapping", "water_tds"],
  },
];

export const metalSheetBuildings = [
  {
    id: 0,
    name: "Building/Area Details",
  },
  {
    id: 1,
    name: "Basic Building Details",
    items: [
      "shed_type",
      "sheet_age",
      "sheet_thickness",
      "shed_age",
      "building_image",
    ],
  },
  {
    id: 2,
    name: "Roof Details",
    items: [
      "metal_sheet_profile",
      "purlins_type",
      "building_height",
      "roof_inclination",
      "roof_orientation",
      "roof_structure_condition",
      "roof_remarks",
      "roof_insurance",
      "is_drainage_existing",
      "metal_sheet_profile_image",
      "purlins_type_image",
      "building_height_image",
    ],
  },
  {
    id: 3,
    name: "Access",
    items: [
      "roof_access",
      "roof_access_type",
      "roof_access_image",
      "roof_access_type_image",
    ],
  },
  {
    id: 4,
    name: "Obstructions on the roof top",
    items: "check",
    item: "any_trees_surrounding_building",
    item1: [
      "is_chimney_on_rooftop",
      "any_trees_surrounding_building",
      "tree_height",
      "any_shadow_causing_objects",
      "is_skylight_on_rooftop",
    ],
    item2: [
      "is_chimney_on_rooftop",
      "any_trees_surrounding_building",
      "any_shadow_causing_objects",
      "is_skylight_on_rooftop",
    ],
  },

  {
    id: 5,
    name: "Dust,Heat and Smoke impact on rooftop",
    items: ["dust_impact", "smoke_impact", "heat_impact", "chemical_type"],
  },
  {
    id: 6,
    name: "LA Details",
    items: "check",
    item: "existing_la",
    item1: ["metal_sheet_la_detail_array", "existing_la"],
    item2: ["existing_la"],
  },
  {
    id: 7,
    name: "Water availability for O&M",
    items: ["is_water_tapping", "water_tds", "is_water_tapping_image"],
  },
];

export const groundMountAreas = [
  {
    id: 0,
    name: "Building/Area Details",
  },
  {
    id: 1,
    name: "Ground details",
    items: [
      "highlight_designated_ground_image",
      "area_image",
      "soil_type_image",
      "ground_condition_image",
      "ground_remarks",
      "area",
      "ground_remarks",
      "soil_type",
      "ground_condition",
    ],
  },
  {
    id: 2,
    name: "Obstructions on the ground",
    items: ["is_trees_inside_area", "any_shadow_surrounding_area"],
  },

  {
    id: 3,
    name: "Water availability for maintence",
    items: ["is_water_tapping", "water_tds"],
  },
];

export const carBusPortAreas = [
  {
    id: 0,
    name: "Building/Area Details",
  },
  {
    id: 1,
    name: "Area details",
    items: "multiple",
    item1: [
      "area_type",
      "soil_type",
      "soil_type_image",
      "is_land_levelling_required",
      "area_type_image",
    ],
    item2: [
      "area_type",
      "is_water_proofing_layer",
      "water_proofing_layer_type",
    ],
    item3: ["area_type", "area_type_image"],
  },
  {
    id: 2,
    name: "Existing cable routing upto the location",
    items: "check",
    item1: [
      "is_metal_sheet_required",
      "profile_sheet",
      "existing_cable_routing",
      "existing_cable_routing_image",
    ],
    item2: [
      "is_metal_sheet_required",
      "existing_cable_routing",
      "existing_cable_routing_image",
    ],
  },
  {
    id: 3,
    name: "Shadow causing objects nearby",
    items: [
      "is_proposed_ground_area",
      "any_trees_surrounding_building",
      "any_shadow_surrounding_area",
    ],
  },

  {
    id: 4,
    name: "Water availability for O&M",
    items: ["is_water_tapping", "water_tds"],
  },
];
export const proposedLocationList = [
  {
    id: 0,
    name: "Inverter Details",
    items: ["inverters_proposed_locations_array"],
  },
  {
    id: 1,
    name: "Satellite images showing roofs, proposed locations for inverters and ACDBs, cable routing, LT panel locations, and proposed locations for earth pits.",
    items: ["satellite_images"],
  },
  {
    id: 2,
    name: "Proposed location for ACDB",
    items: ["proposed_location_of_acdb"],
  },
  {
    id: 3,
    name: "Proposed earthing location",
    items: ["proposed_location_of_earthing"],
  },
  {
    id: 4,
    name: "Proposed AC cable routing",
    items: ["proposed_ac_cable_routing"],
  },
];
export const projectSpecList = [
  {
    id: 0,
    name: "Select all the confirmations",
  },
  {
    id: 1,
    name: "As built SLD of the plant",
  },
  {
    id: 2,
    name: "Electrical Bills of last 3-6 months",
  },
  {
    id: 3,
    name: "Roof Plan and Elevation",
  },
  {
    id: 4,
    name: "Roof stability / structural stability documents",
  },
  {
    id: 5,
    name: "Quality of water for cleaning",
  },
  {
    id: 6,
    name: "Soil test report",
  },
  {
    id: 7,
    name: "Topographical survey",
  },

  {
    id: 8,
    name: "Ground drawing",
  },
  {
    id: 9,
    name: "Satellite Image",
  },
  {
    id: 10,
    name: "Others",
  },
];
