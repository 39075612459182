import * as crmActions from "../../actions/types";

const INITIAL_STATE = {
  board: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  teamMembers: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  companies: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  specificCompanyDetails: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  leadDetails: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  channelPartners: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  specificChannelPartnerDetails: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  comments: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  activities: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  proposals: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  proposalTemplates: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  proposalTemplate: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  pastProjects: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const crmReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //BOARD
    case crmActions.GET_CRM_BOARD_LOADING:
      return {
        ...state,
        board: {
          ...state.board,
          get: {
            ...state.board.get,
            loading: true,
            reset: false,
            success: {
              ...state.board.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_BOARD_SUCCESS:
      return {
        ...state,
        board: {
          ...state.board,
          get: {
            ...state.board.get,
            loading: false,
            reset: false,
            success: {
              ...state.board.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_BOARD_FAILURE:
      return {
        ...state,
        board: {
          ...state.board,
          get: {
            ...state.board.get,
            loading: false,
            reset: false,
            success: {
              ...state.board.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_CRM_BOARD_RESET:
      return {
        ...state,
        board: {
          ...state.board.get,
          get: {
            ...INITIAL_STATE.board.post,
            reset: true,
          },
        },
      };
    //TEAM MEMBERS
    case crmActions.GET_CRM_TEAM_MEMBERS_LOADING:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          get: {
            ...state.teamMembers.get,
            loading: true,
            reset: false,
            success: {
              ...state.teamMembers.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          get: {
            ...state.teamMembers.get,
            loading: false,
            reset: false,
            success: {
              ...state.teamMembers.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          get: {
            ...state.teamMembers.get,
            loading: false,
            reset: false,
            success: {
              ...state.teamMembers.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_CRM_TEAM_MEMBERS_RESET:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers.get,
          get: {
            ...INITIAL_STATE.teamMembers.post,
            reset: true,
          },
        },
      };
    //ALL COMPANIES
    case crmActions.GET_ALL_COMPANIES_LOADING:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: true,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: false,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: false,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_ALL_COMPANIES_RESET:
      return {
        ...state,
        companies: {
          ...state.companies.get,
          get: {
            ...INITIAL_STATE.companies.post,
            reset: true,
          },
        },
      };
    //SPECIFIC COMPANY DETAILS
    case crmActions.GET_CRM_COMPANY_DETAILS_LOADING:
      return {
        ...state,
        specificCompanyDetails: {
          ...state.specificCompanyDetails,
          get: {
            ...state.specificCompanyDetails.get,
            loading: true,
            reset: false,
            success: {
              ...state.specificCompanyDetails.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        specificCompanyDetails: {
          ...state.specificCompanyDetails,
          get: {
            ...state.specificCompanyDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.specificCompanyDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        specificCompanyDetails: {
          ...state.specificCompanyDetails,
          get: {
            ...state.specificCompanyDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.specificCompanyDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_CRM_COMPANY_DETAILS_RESET:
      return {
        ...state,
        specificCompanyDetails: {
          ...state.specificCompanyDetails.get,
          get: {
            ...INITIAL_STATE.specificCompanyDetails.post,
            reset: true,
          },
        },
      };
    //LEAD DETAILS
    case crmActions.GET_LEAD_DETAILS_LOADING:
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          get: {
            ...state.leadDetails.get,
            loading: true,
            reset: false,
            success: {
              ...state.leadDetails.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          get: {
            ...state.leadDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.leadDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_LEAD_DETAILS_FAILURE:
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          get: {
            ...state.leadDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.leadDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_LEAD_DETAILS_RESET:
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails.get,
          get: {
            ...INITIAL_STATE.leadDetails.post,
            reset: true,
          },
        },
      };
    //CHANNEL PARTNERS
    case crmActions.GET_CHANNEL_PARTNERS_LOADING:
      return {
        ...state,
        channelPartners: {
          ...state.channelPartners,
          get: {
            ...state.channelPartners.get,
            loading: true,
            reset: false,
            success: {
              ...state.channelPartners.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CHANNEL_PARTNERS_SUCCESS:
      return {
        ...state,
        channelPartners: {
          ...state.channelPartners,
          get: {
            ...state.channelPartners.get,
            loading: false,
            reset: false,
            success: {
              ...state.channelPartners.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CHANNEL_PARTNERS_FAILURE:
      return {
        ...state,
        channelPartners: {
          ...state.channelPartners,
          get: {
            ...state.channelPartners.get,
            loading: false,
            reset: false,
            success: {
              ...state.channelPartners.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_CHANNEL_PARTNERS_RESET:
      return {
        ...state,
        channelPartners: {
          ...state.channelPartners.get,
          get: {
            ...INITIAL_STATE.channelPartners.post,
            reset: true,
          },
        },
      };
    //SPECIFIC CHANNEL PARTNER DETAILS
    case crmActions.GET_SPECIFIC_CHANNEL_PARTNER_LOADING:
      return {
        ...state,
        specificChannelPartnerDetails: {
          ...state.specificChannelPartnerDetails,
          get: {
            ...state.specificChannelPartnerDetails.get,
            loading: true,
            reset: false,
            success: {
              ...state.specificChannelPartnerDetails.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_SPECIFIC_CHANNEL_PARTNER_SUCCESS:
      return {
        ...state,
        specificChannelPartnerDetails: {
          ...state.specificChannelPartnerDetails,
          get: {
            ...state.specificChannelPartnerDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.specificChannelPartnerDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_SPECIFIC_CHANNEL_PARTNER_FAILURE:
      return {
        ...state,
        specificChannelPartnerDetails: {
          ...state.specificChannelPartnerDetails,
          get: {
            ...state.specificChannelPartnerDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.specificChannelPartnerDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_SPECIFIC_CHANNEL_PARTNER_RESET:
      return {
        ...state,
        specificChannelPartnerDetails: {
          ...state.specificChannelPartnerDetails.get,
          get: {
            ...INITIAL_STATE.specificChannelPartnerDetails.post,
            reset: true,
          },
        },
      };
    //CRM LEAD COMMENTS
    case crmActions.GET_CRM_LEAD_COMMENTS_LOADING:
      return {
        ...state,
        comments: {
          ...state.comments,
          get: {
            ...state.comments.get,
            loading: true,
            reset: false,
            success: {
              ...state.comments.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_LEAD_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          get: {
            ...state.comments.get,
            loading: false,
            reset: false,
            success: {
              ...state.comments.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_CRM_LEAD_COMMENTS_FAILURE:
      return {
        ...state,
        comments: {
          ...state.comments,
          get: {
            ...state.comments.get,
            loading: false,
            reset: false,
            success: {
              ...state.comments.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_CRM_LEAD_COMMENTS_RESET:
      return {
        ...state,
        comments: {
          ...state.comments.get,
          get: {
            ...INITIAL_STATE.comments.post,
            reset: true,
          },
        },
      };
    //CRM ACTIVITIES
    case crmActions.GET_ALL_ACTIVITIES_LOADING:
      return {
        ...state,
        activities: {
          ...state.activities,
          get: {
            ...state.activities.get,
            loading: true,
            reset: false,
            success: {
              ...state.activities.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          get: {
            ...state.activities.get,
            loading: false,
            reset: false,
            success: {
              ...state.activities.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          get: {
            ...state.activities.get,
            loading: false,
            reset: false,
            success: {
              ...state.activities.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_ALL_ACTIVITIES_RESET:
      return {
        ...state,
        activities: {
          ...state.activities.get,
          get: {
            ...INITIAL_STATE.activities.post,
            reset: true,
          },
        },
      };
    //PROPOSALS
    case crmActions.GET_ALL_PROPOSALS_LOADING:
      return {
        ...state,
        proposals: {
          ...state.proposals,
          get: {
            ...state.proposals.get,
            loading: true,
            reset: false,
            success: {
              ...state.proposals.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: {
          ...state.proposals,
          get: {
            ...state.proposals.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposals.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSALS_FAILURE:
      return {
        ...state,
        proposals: {
          ...state.proposals,
          get: {
            ...state.proposals.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposals.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSALS_RESET:
      return {
        ...state,
        proposals: {
          ...state.proposals.get,
          get: {
            ...INITIAL_STATE.proposals.post,
            reset: true,
          },
        },
      };
    //PROPOSAL TEMPLATES
    case crmActions.GET_ALL_PROPOSAL_TEMPLATES_LOADING:
      return {
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          get: {
            ...state.proposalTemplates.get,
            loading: true,
            reset: false,
            success: {
              ...state.proposalTemplates.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSAL_TEMPLATES_SUCCESS:
      return {
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          get: {
            ...state.proposalTemplates.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposalTemplates.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSAL_TEMPLATES_FAILURE:
      return {
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          get: {
            ...state.proposalTemplates.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposalTemplates.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_ALL_PROPOSAL_TEMPLATES_RESET:
      return {
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates.get,
          get: {
            ...INITIAL_STATE.proposalTemplates.post,
            reset: true,
          },
        },
      };
    //PROPOSAL TEMPLATE
    case crmActions.GET_PROPOSAL_TEMPLATE_LOADING:
      return {
        ...state,
        proposalTemplate: {
          ...state.proposalTemplate,
          get: {
            ...state.proposalTemplate.get,
            loading: true,
            reset: false,
            success: {
              ...state.proposalTemplate.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_PROPOSAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        proposalTemplate: {
          ...state.proposalTemplate,
          get: {
            ...state.proposalTemplate.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposalTemplate.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_PROPOSAL_TEMPLATE_FAILURE:
      return {
        ...state,
        proposalTemplate: {
          ...state.proposalTemplate,
          get: {
            ...state.proposalTemplate.get,
            loading: false,
            reset: false,
            success: {
              ...state.proposalTemplate.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case crmActions.GET_PROPOSAL_TEMPLATE_RESET:
      return {
        ...state,
        proposalTemplate: {
          ...state.proposalTemplate.get,
          get: {
            ...INITIAL_STATE.proposalTemplate.get,
            reset: true,
          },
        },
      };
    //PAST PROJECTS
    case crmActions.GET_PAST_PROJECTS_CRM_LOADING:
      return {
        ...state,
        pastProjects: {
          ...state.pastProjects,
          get: {
            ...state.pastProjects.get,
            loading: true,
            reset: false,
            success: {
              ...state.pastProjects.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_PAST_PROJECTS_CRM_SUCCESS:
      return {
        ...state,
        pastProjects: {
          ...state.pastProjects,
          get: {
            ...state.pastProjects.get,
            loading: false,
            reset: false,
            success: {
              ...state.pastProjects.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case crmActions.GET_PAST_PROJECTS_CRM_FAILURE:
      return {
        ...state,
        pastProjects: {
          ...state.pastProjects,
          get: {
            ...state.pastProjects.get,
            loading: false,
            reset: false,
            success: {
              ...state.pastProjects.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
  }
  return state;
};

export default crmReducers;
