import {
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Upload,
  Radio,
  Space,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSiteVisitProjectSpecifications,
  getSiteVisitDetails,
} from "../../actions/get-site-visit-action";
import CardPercentage from "./CardPercentage";
import "./project-spec.style.less";
import SiteVistContext from "./SiteVisitContext";
import FloatInput from "./FloatInput";

function ProjectSpec({ siteVisitId }) {
  const [docs, setDocs] = useState({
    electrical: [],
    building_and_area: [],
    ground_mount: [],
    satellite_images: [],
    others: [],
  });
  const [considerations, setConsiderations] = useState();
  const [title, setTitle] = useState();

  const siteVisitContext = useContext(SiteVistContext);

  const {
    projectSpecRef,
    tabsList,
    editTabsHandler,
    saveLaterText,
    setSaveLaterText,
    specs,
    setSpecs,
  } = siteVisitContext;
  const hanlder = () => {
    message.success("Details saved successfully");
    setSaveLaterText();
  };
  useEffect(() => {
    if (saveLaterText === "Project Specifications") {
      hanlder();
    }
  }, [saveLaterText]);
  const dispatch = useDispatch();

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);

  const getDocumentsHandler = (docsList, type) => {
    return docsList
      .filter((i) => i.type === type)
      ?.map((doc, index) => {
        const { id, title, file } = doc;
        return {
          id: id,
          name: title,
          url: file,
          is_uploaded: id === null ? false : true,
        };
      });
  };

  useEffect(() => {
    if (getSiteVisitDetailsReducer.success.data) {
      let docsData =
        getSiteVisitDetailsReducer.success.data
          ?.project_specifications_documents;
      let projectSpecs =
        getSiteVisitDetailsReducer.success.data?.project_specifications;
      if (projectSpecs && Object.keys(projectSpecs)?.length > 0) {
        setSpecs(
          getSiteVisitDetailsReducer.success.data?.project_specifications
        );
      }

      setDocs({
        electrical: getDocumentsHandler(docsData, "electrical"),
        building_and_area: getDocumentsHandler(docsData, "building_and_area"),
        ground_mount: getDocumentsHandler(docsData, "ground_mount"),
        satellite_images: getDocumentsHandler(docsData, "satellite_images"),
        others: getDocumentsHandler(docsData, "others"),
      });

      let keyLength = docsData?.length;

      let count = docsData?.reduce((acc, val) => {
        if (val.id == null) {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0);
      let temp = tabsList;

      if (temp.length > 0) {
        temp[temp?.length - 1].progress = Math.round((count / keyLength) * 100);
        editTabsHandler(temp);
      }
    }
  }, [
    getSiteVisitDetailsReducer.success.data?.project_specifications_documents,
  ]);

  const fileHandler = (file, title) => {
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);
    formData.append("file", file);
    formData.append("title", title);
    Promise.resolve(dispatch(addSiteVisitProjectSpecifications(formData))).then(
      (res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
        }
      }
    );
  };

  const renderProgress = (type, length) => {
    let count =
      getSiteVisitDetailsReducer.success.data?.project_specifications_documents
        .filter((i) => i.type === type)
        .reduce((acc, val) => {
          if (val.id) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);

    if (count == length) {
      return 100;
    } else {
      return (count / length) * 100;
    }
  };

  const onChangeHandler = (e) => {
    setSpecs({
      ...specs,
      [e.target.name]: e.target.value,
    });
  };

  const renderRadioButton = (objkey, title, index, type) => {
    let options = type === "area" ? ["Soil", "RCC"] : ["Yes", "No", "NA"];
    return (
      <div
        className="document-wrapper"
        ref={(element) => {
          projectSpecRef.current[index] = element;
        }}
      >
        <h1 className="inner-text">{title}</h1>
        <Radio.Group
          name={objkey}
          onChange={onChangeHandler}
          value={specs[objkey]}
          card="Roof Details"
        >
          <Space direction="vertical">
            {options.map((i) => {
              return (
                <Radio value={i} key={i}>
                  {i}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    );
  };

  const renderDocsCards = (title, type, scrollIndex) => {
    return (
      <>
        <CardPercentage
          title={title}
          dataMargin={true}
          statusDataBank={renderProgress(type, docs[type]?.length)}
        />
        <hr />
        <div className="documents-container">
          {docs[type]?.map((doc, index) => (
            <Row
              className="document-wrapper"
              key={index}
              align="middle"
              ref={(element) => {
                projectSpecRef.current[index + scrollIndex] = element;
              }}
            >
              <Col span={10} className="left-col">
                {doc?.is_uploaded ? (
                  <Checkbox
                    disabled={doc.is_uploaded}
                    checked={doc.is_uploaded}
                  >
                    <p>
                      <s>{doc?.name}</s>
                    </p>
                  </Checkbox>
                ) : (
                  <Checkbox disabled={false} checked={false}>
                    <p>{doc?.name}</p>
                  </Checkbox>
                )}
              </Col>
              <Col flex="auto" />

              <Col span={5} className="right-col">
                {doc?.is_uploaded ? (
                  <a href={doc?.url} target="_blank">
                    <Button className="view-btn">VIEW</Button>
                  </a>
                ) : (
                  <Upload
                    accept=".doc,.docx,.pdf,.xls,.xlsx,.txt,.png,.jpeg,.webp,.svg"
                    name="file"
                    showUploadList={false}
                    beforeUpload={(file, FileList) =>
                      fileHandler(file, doc.name)
                    }
                  >
                    <Button className="upload-btn">Upload</Button>
                  </Upload>
                )}
              </Col>
            </Row>
          ))}
        </div>
      </>
    );
  };

  const titleList = [
    "Is Roof puncturing allowed",
    "Can trees surrounding the building be removed or cut upto roof level by client",
    "Will site leveling be performed by the client",
    "Will site grading be performed by the client",
    "Type of area",
  ];

  const saveHandler = () => {
    if (specs?.area_type) {
      const formData = new FormData();
      formData.append("site_visit_id", siteVisitId);
      formData.append("project_specifications", JSON.stringify(specs));
      dispatch(addSiteVisitProjectSpecifications(formData));
    } else {
      message.warning("Please Select Area type");
    }
  };

  let data = getSiteVisitDetailsReducer?.success.data;

  const renderEmpty = (title) => {
    return (
      <>
        <h1 className="upper-text">{title}</h1>
        <p style={{ margin: "2rem", color: "#3d87f1", fontSize: "1.5rem" }}>
          Please add respective building/area to confirm the following details
        </p>
      </>
    );
  };

  return (
    <div className="data-bank-container">
      <div
        className="card-container"
        ref={(element) => {
          projectSpecRef.current[0] = element;
        }}
      >
        <CardPercentage
          cardName="Select all the confirmations"
          title="Confirmations"
          items={
            specs?.area_type === "Soil"
              ? [
                  specs?.is_metal_sheet_roof_puncturing_allowed,
                  specs?.can_trees_cut_metal_sheet,
                  specs?.is_rcc_roof_puncturing_allowed,
                  specs?.can_trees_cut_rcc,
                  specs?.is_site_grading_ground_mount,
                  specs?.is_site_leveling_ground_mount,
                  specs?.area_type,
                  specs?.is_site_grading_carport,
                  specs?.is_site_leveling_carport,
                ]
              : [
                  specs?.is_metal_sheet_roof_puncturing_allowed,
                  specs?.can_trees_cut_metal_sheet,
                  specs?.is_rcc_roof_puncturing_allowed,
                  specs?.can_trees_cut_rcc,
                  specs?.is_site_grading_ground_mount,
                  specs?.is_site_leveling_ground_mount,
                  specs?.area_type,
                  specs?.is_carport_puncturing_allowed,
                  specs?.can_trees_cut_carport,
                ]
          }
        />
        {data?.metal_sheet_details_array?.length > 0 ? (
          <>
            <h1 className="upper-text">Metal Sheet</h1>
            {renderRadioButton(
              "is_metal_sheet_roof_puncturing_allowed",
              titleList[0],
              0
            )}
            {renderRadioButton("can_trees_cut_metal_sheet", titleList[1], 1)}
          </>
        ) : (
          renderEmpty("Metal Sheet")
        )}
        {data?.rcc_building_details_array?.length > 0 ? (
          <>
            {" "}
            <h1 className="upper-text">RCC</h1>
            {renderRadioButton(
              "is_rcc_roof_puncturing_allowed",
              titleList[0],
              2
            )}
            {renderRadioButton("can_trees_cut_rcc", titleList[1], 3)}
          </>
        ) : (
          renderEmpty("RCC")
        )}
        {data?.ground_mount_details_array?.length > 0 ? (
          <>
            <h1 className="upper-text">Ground Mount</h1>
            {renderRadioButton(
              "is_site_leveling_ground_mount",
              titleList[2],
              4
            )}
            {renderRadioButton("is_site_grading_ground_mount", titleList[3], 5)}
          </>
        ) : (
          renderEmpty("Ground Mount")
        )}
        {data?.car_port_details_array?.length > 0 ? (
          <>
            <h1 className="upper-text">Carport</h1>
            {renderRadioButton("area_type", titleList[4], 6, "area")}
            {specs?.area_type === "Soil" && (
              <>
                {renderRadioButton("is_site_leveling_carport", titleList[2], 7)}
                {renderRadioButton("is_site_grading_carport", titleList[3], 8)}
              </>
            )}
            {specs?.area_type === "RCC" && (
              <>
                {renderRadioButton(
                  "is_carport_puncturing_allowed",
                  titleList[0],
                  9
                )}
                {renderRadioButton("can_trees_cut_carport", titleList[1], 10)}
              </>
            )}
          </>
        ) : (
          renderEmpty("Carport")
        )}

        <div className="btn-container" onClick={() => saveHandler()}>
          <Button>Save</Button>
        </div>
      </div>
      <div className="card-container">
        <h1 className="overall-text">
          Please Find the list of documents required below
        </h1>
        {renderDocsCards("Electrical Documents", "electrical", 1)}
        {renderDocsCards("Building and Area Documents", "building_and_area", 3)}

        {renderDocsCards("Ground Mount Documents", "ground_mount", 6)}
        {renderDocsCards("Satellite Documents", "satellite_images", 9)}
        {renderDocsCards("Other Documents", "others", 10)}
      </div>
      <div
        className="card-container"
        ref={(element) => {
          projectSpecRef.current[11] = element;
        }}
      >
        <CardPercentage
          cardName="Key Considerations & Extra Files"
          // title="Key Considerations & Extra Files"
          items={[]}
        />
        <h1 className="overall-text">
          Key Considerations
        </h1>
        <TextArea
          card="Key Considerations & Extra Files"
          rows={12}
          bordered={false}
          placeholder="Key Considerations"
          onChange={(e) => setConsiderations(e.target.value)}
          value={considerations}
        />
        <h1 className="overall-text">Upload Any additional attachments</h1>
        <FloatInput
          required={true}
          label="Enter title of the attachment you want to upload"
          placeholder="Enter title of the attachment you want to upload"
          onChange={(e) => setTitle(e.target.value)}
          card="Key Considerations & Extra Files"
          value={title}
        />
      </div>
    </div>
  );
}

export default ProjectSpec;
