import {
  GET_GANTT_EVENTS_LOADING,
  GET_GANTT_EVENTS_SUCCESS,
  GET_GANTT_EVENTS_FAILURE,
  GET_GANTT_EVENT_STAGE_LOADING,
  GET_GANTT_EVENT_STAGE_SUCCESS,
  GET_GANTT_EVENT_STAGE_FAILURE,
  GET_GANTT_EVENT_STAGE_INITIAL_VALUES,
  GET_EVENT_DATA_LOADING,
  GET_EVENT_DATA_SUCCESS,
  GET_EVENT_DATA_FAILURE,
  GET_VIEW_SPECIFICATION_LOADING,
  GET_VIEW_SPECIFICATION_SUCCESS,
  GET_VIEW_SPECIFICATION_FAILURE,
  GET_TICKET_LOADING,
  GET_TICKET_SUCCESS,
  GET_TICKET_FAILURE,
  GET_SPOC_LOADING,
  GET_SPOC_SUCCESS,
  GET_SPOC_FAILURE,
  GET_UPDATE_LOADING,
  GET_UPDATE_SUCCESS,
  GET_UPDATE_FAILURE,
  GET_UPLOAD_DOCS_LOADING,
  GET_UPLOAD_DOCS_SUCCESS,
  GET_UPLOAD_DOCS_FAILURE,
  GET_GANTT_EVENTS_MENU_LOADING,
  GET_GANTT_EVENTS_MENU_SUCCESS,
  GET_GANTT_EVENTS_MENU_FAILURE,
  GET_GANTT_DATA_LOADING,
  GET_GANTT_DATA_SUCCESS,
  GET_GANTT_DATA_FAILURE,
  GET_GANTT_DATA_RESET,
  GET_DRAWING_LOADING,
  GET_DRAWING_SUCCESS,
  GET_DRAWING_FAILURE,
  GET_SAMPLE_GANTTS_LOADING,
  GET_SAMPLE_GANTTS_SUCCESS,
  GET_SAMPLE_GANTTS_FAILURE,
  GET_SAMPLE_GANTT_DETAILS_LOADING,
  GET_SAMPLE_GANTT_DETAILS_SUCCESS,
  GET_SAMPLE_GANTTS_DETAILS_FAILURE,
  GET_SAMPLE_GANTTS_PROJECT_DETAILS_LOADING,
  GET_SAMPLE_GANTTS_PROJECT_DETAILS_SUCCESS,
  GET_SAMPLE_GANTTS_PROJECT_DETAILS_FAILURE,
  GET_GANTTS_PROJECT_DETAILS_LOADING,
  GET_GANTTS_PROJECT_DETAILS_SUCCESS,
  GET_GANTTS_PROJECT_DETAILS_FAILURE,
  GET_SAMPLE_GANTTS_DETAILS_RESET,
  GET_SAMPLE_EVENT_LOADING,
  GET_SAMPLE_EVENT_SUCCESS,
  POST_SAMPLE_EVENT_LOADING,
  POST_SAMPLE_EVENT_SUCCESS,
  POST_SAMPLE_EVENT_FAILURE,
  GET_ALL_SAMPLE_EVENT_LOADING,
  GET_ALL_SAMPLE_EVENT_SUCCESS,
  CREATE_NEW_CHECKLIST_ITEM_LOADING,
  CREATE_NEW_CHECKLIST_ITEM_SUCCESS,
  CREATE_NEW_CHECKLIST_ITEM_FAILURE,
  TOGGLE_TASK_LOADING,
  TOGGLE_TASK_SUCCESS,
  TOGGLE_TASK_FAILURE,
  POST_CUSTOMER_EVENT_APPROVAL_LOADING,
  POST_CUSTOMER_EVENT_APPROVAL_SUCCESS,
  POST_CUSTOMER_EVENT_APPROVAL_FAILURE,
  POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_LOADING,
  POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_SUCCESS,
  POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_FAILURE,
  GET_EVENT_INITIAL_VALUES,
  GET_ALL_EVENT_LOADING,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_EVENT_FAILURE,
} from "../types";
import { Mixpanel } from "../../Mixpanel";
import axios from "axios";
import { message } from "antd";
import { getItem, setItem } from "../../SecureStorage";
import * as Actions from "../types";
import * as actionCreators1 from "../../actions/project-report-action/index";
import { getEventOverview, getStageOverview } from "../work-table-action";
import { getScmDetails } from "../get-scm-details-action";

/**
 * Dispatches the actions related to stage and event data.
 *
 * If the current page is work table, then work table related actions will be dispatched.
 *
 * Else the actions related to detailed event page will be dispatched.
 * @param {Dispatch} dispatch The dispatch function of the Redux store
 */
export const getEvent = (dispatch) => {
  const eventId = JSON.parse(getItem("eventId"));
  const stageName = JSON.parse(getItem("stageName"));
  const projectId = JSON.parse(getItem("projectId"));
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  if (window.location.pathname?.includes("work-table")) {
    getWorkTableData(projectId, stageName, eventId, dispatch);
  } else {
    dispatch(getGantEventDetails(stageName, isSubContractor));
    dispatch(getEventData(eventId, isSubContractor));
  }
  setItem("eventId", eventId);
};

const getWorkTableData = (projectId, stageName, eventId, dispatch) => {
  dispatch(getStageOverview(projectId, stageName));
  dispatch(getEventOverview(eventId));
};

export const getGanttEvents = () => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_GANTT_EVENTS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}gantt_view/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_GANTT_EVENTS_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_GANTT_EVENTS_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const getGantEventDetails =
  (stageName, subContractorMode) => (dispatch) => {
    let userData;
    let projectId;

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }

    if (getItem("projectId")) {
      projectId = JSON.parse(getItem("projectId"));
    }
    const isSubContractor = JSON.parse(getItem("isSubContractor"));

    dispatch({ type: GET_GANTT_EVENT_STAGE_LOADING, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}gantt_view_events/`, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          stage_name: stageName,
          project_id: projectId,
          sub_contractor_mode: subContractorMode,
          is_sub_contractor: isSubContractor,
        },
      })
      .then((result) => {
        dispatch({ type: GET_GANTT_EVENT_STAGE_SUCCESS, payload: result.data });
      })
      .catch((err) => {
        dispatch({
          type: GET_GANTT_EVENT_STAGE_FAILURE,
          payload: { message: err.message },
        });
      });
  };
export const getGantEventDetailsInitialValues = () => (dispatch) => {
  dispatch({ type: GET_GANTT_EVENT_STAGE_INITIAL_VALUES, payload: null });
};

export const getGantEventDetailsMenu =
  (stageName, subContractorMode) => (dispatch) => {
    let userData;
    let projectId;

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }

    if (getItem("projectId")) {
      projectId = JSON.parse(getItem("projectId"));
    }

    const isSubContractor = JSON.parse(getItem("isSubContractor"));

    dispatch({ type: GET_GANTT_EVENTS_MENU_LOADING, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}gantt_view_events/`, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          stage_name: stageName,
          project_id: projectId,
          sub_contractor_mode: subContractorMode,
          is_sub_contractor: isSubContractor,
        },
      })
      .then((result) => {
        dispatch({ type: GET_GANTT_EVENTS_MENU_SUCCESS, payload: result.data });
      })
      .catch((err) => {
        dispatch({
          type: GET_GANTT_EVENTS_MENU_FAILURE,
          payload: { message: err.message },
        });
      });
  };

export const getCustomEventMenuDetails = (stageName) => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  dispatch({ type: GET_GANTT_EVENTS_MENU_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_stage_events_basic_details/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        stage: stageName,
        project_id: projectId,
      },
    })
    .then((result) => {
      dispatch({ type: GET_GANTT_EVENTS_MENU_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_GANTT_EVENTS_MENU_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const getEventData = (eventId, subContractorMode) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_EVENT_DATA_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_event/${eventId}/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        sub_contractor_mode: isSubContractor ? true : false,
        // is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_EVENT_DATA_SUCCESS, payload: result.data });
      if (result.data && result.data.event && result.data.event.first_time) {
        setItem("firstTime", true);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_EVENT_DATA_FAILURE,
        payload: { message: err.message },
      });
    });
};
export const getEventInitialValues = () => (dispatch) => {
  dispatch({ type: GET_EVENT_INITIAL_VALUES, payload: null });
};

export const getAllEvents = () => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  dispatch({ type: GET_ALL_EVENT_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_all_events/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
      },
    })
    .then((result) => {
      dispatch({ type: GET_ALL_EVENT_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({ type: GET_ALL_EVENT_FAILURE, err: { message: err.message } });
      return message.error("Some error occurred!");
    });
};

export const ganttChartData = () => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  dispatch({ type: GET_GANTT_DATA_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_gantt_data/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
      },
    })
    .then((result) => {
      dispatch({ type: GET_GANTT_DATA_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({ type: GET_GANTT_DATA_FAILURE, err: { message: err.message } });
      return message.error("Some error occurred!");
    });
};

export const checkListTask = (data) => (dispatch) => {
  let userData;
  let isSubContractor;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  // const isSubContractor = JSON.parse(getItem('isSubContractor'))

  axios
    .post(`${process.env.REACT_APP_BASE_URL}change/checklist_task/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
      return message.info("Updated successfully!");
    })
    .catch((err) => {
      return message.error("Some error occurred!");
    });
};

export const createNewChecklistItem = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: CREATE_NEW_CHECKLIST_ITEM_LOADING, payload: null });

  axios
    .post(`${process.env.REACT_APP_BASE_URL}add/checklist_task/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({
        type: CREATE_NEW_CHECKLIST_ITEM_SUCCESS,
        payload: { result, checklistItemCreated: true },
      });
    })
    .catch((err) => {
      dispatch({ type: CREATE_NEW_CHECKLIST_ITEM_FAILURE, payload: err });
      return message.error("Some error occurred!");
    });
};

export const preRequisiteTask = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(`${process.env.REACT_APP_BASE_URL}change/prerequiste_task/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
      return message.info("Updated successfully!");
    })
    .catch((err) => {
      return message.error("Some error occurred!");
    });
};

export const getTickets = (eventId) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_TICKET_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_tickets/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        event_id: eventId,
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_TICKET_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({ type: GET_TICKET_FAILURE, payload: { message: err.message } });
    });
};

export const postTickets = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_ticket/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    message.success("Ticket added successfully!");
    return result;
  } catch (e) {
    return message.error("Some error occurred!");
  }
};

export const changeTicketStatus = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(`${process.env.REACT_APP_BASE_URL}change_ticket_status/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
      window.location.reload();
      return message.info("Status changed successfully!");
    })
    .catch((err) => {
      return message.error("Some error occurred!");
    });
};

export const getSpoc = (stage, projectPlanning) => (dispatch) => {
  let projectId;
  let userData;
  let params;

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  if (!projectPlanning) {
    params = {
      stage: stage,
      project_id: projectId,
      is_sub_contractor: isSubContractor,
    };
  }

  if (projectPlanning) {
    params = {
      stage: stage,
      project_id: projectId,
      project_planning: projectPlanning,
    };
  }
  //  else {
  //   params = {
  //     stage: stage,
  //     project_id: projectId,
  //     project_planning: projectPlanning,
  //   };
  // }

  dispatch({ type: GET_SPOC_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_spoc/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: params,
    })
    .then((result) => {
      dispatch({ type: GET_SPOC_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({ type: GET_SPOC_FAILURE, payload: { message: err.message } });
    });
};

export const changeEventSpoc = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(`${process.env.REACT_APP_BASE_URL}change_event_spoc/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!", 0.5);
      }
    })
    .catch((err) => {});
};

export const getDocs = (eventId) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_UPLOAD_DOCS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_event_docs/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        event_id: eventId,
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPLOAD_DOCS_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_UPLOAD_DOCS_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const postDocs = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_event_doc/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          is_sub_contractor: isSubContractor,
        },
      }
    );
    message.success("File uploaded successfully!");
    return result;
  } catch (e) {
    return message.error("Error while uploading file!");
  }
};

export const postSCMDocs = (data, eventId) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_scm_status/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch(getScmDetails(eventId));

    return result;
  } catch (e) {
    return message.error("Error while updating!");
  }
};

export const getDocsList = () => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.GET_DOCS_LIST_LOADING, payload: null });

  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_docs_list`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
        is_epc: userData?.isProjectManager ? false : true,
      },
    })
    .then((result) => {
      dispatch({ type: Actions.GET_DOCS_LIST_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: Actions.GET_DOCS_LIST_FAILURE,
        payload: { message: err.message },
      });
      return message.error(
        "Error occurred while retrieving list of documents!"
      );
    });
};

export const postLinkedTo = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(`${process.env.REACT_APP_BASE_URL}checklist_linked_to/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
      return message.info("Action completed successfully!");
    })
    .catch((err) => {
      return message.error("Error while completing action!");
    });
};

export const followUp = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  axios
    .post(`${process.env.REACT_APP_BASE_URL}follow_up_for_task/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      return message.info("Follow-up completed successfully!");
    })
    .catch((err) => {
      return message.error("Error while completing follow-up!");
    });
};

export const editTaskListOfDrawings = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}list_of_drawings_edit_spec/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      return message.info("Updated successfully!");
    })
    .catch((err) => {});
};

export const editTaskListOfCable = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}cable_calculation_edit_spec/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      return message.info("Updated successfully!");
    })
    .catch((err) => {});
};

export const editTaskListOfInternalApproval = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}internal_design_review_edit_spec/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      return message.info("Updated successfully!");
    })
    .catch((err) => {});
};

export const editTaskListOfProcurementPlan = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}procurement_plan_edit_spec/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      return message.info("Updated successfully!");
    })
    .catch((err) => {});
};

export const editTaskListOfProcurementSpecification = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}procurement_spec_edit_spec/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.info("Updated successfully!");
      }
    })
    .catch((err) => {});
};

export const editTaskListOfFinalSpecification = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.ADD_MATERIAL_LOADING, payload: null });

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}procurement_final_edit_spec/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        Mixpanel.identify(JSON.parse(getItem("userEmail")));
        Mixpanel.track(`Procurement form was filled`, {
          project_id: JSON.parse(getItem("projectId")),
        });
        const addedMaterial = result?.data?.specifications;
        dispatch({
          type: Actions.ADD_MATERIAL_SUCCESS,
          payload: addedMaterial,
        });
        getEvent(dispatch);
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {
      dispatch({
        type: Actions.ADD_MATERIAL_FAILURE,
        payload: err.message,
      });
    });
};

export const updateProcuredMaterial = async (data) => {
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_procured_material/`,
      data,
      {
        headers: {
          Authorization: `Token ${userData.authToken}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const deleteMaterial = async (data) => {
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_material/`,
      data,
      {
        headers: {
          Authorization: `Token ${userData.authToken}`,
        },
      }
    );
    if (res.status === 200) {
      message.success("Deleted Successfully");
      return res;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const editTaskListOfOEMGuideLines = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}installation_of_walkways_edit_spec/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.response.error);
      } else {
        Mixpanel.identify(JSON.parse(getItem("userEmail")));
        Mixpanel.track(`Construction form was filled`, {
          project_id: JSON.parse(getItem("projectId")),
        });
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {});
};

export const editPreDispatchInspection = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}pre_dispatch_inspection_edit_spec/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      return message.info("Updated successfully!");
    })
    .catch((err) => {
      return message.info("Error while updating!");
    });
};

export const viewSpecifications =
  ({ eventId, checkListId }) =>
  (dispatch) => {
    let userData;

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }
    const isSubContractor = JSON.parse(getItem("isSubContractor"));

    dispatch({ type: GET_VIEW_SPECIFICATION_LOADING, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}view_specifications/`, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          event_id: eventId,
          checklist_id: checkListId,
          is_sub_contractor: isSubContractor,
        },
      })
      .then((result) => {
        dispatch({
          type: GET_VIEW_SPECIFICATION_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_VIEW_SPECIFICATION_FAILURE,
          payload: err.message,
        });
      });
  };

export const viewDrawingSpecifications =
  ({ eventId, checkListId }) =>
  (dispatch) => {
    let userData;

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }
    const isSubContractor = JSON.parse(getItem("isSubContractor"));

    dispatch({ type: GET_VIEW_SPECIFICATION_LOADING, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}view_specifications_for_lod/`, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          event_id: eventId,
          checklist_id: checkListId,
          is_sub_contractor: isSubContractor,
        },
      })
      .then((result) => {
        dispatch({
          type: GET_VIEW_SPECIFICATION_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_VIEW_SPECIFICATION_FAILURE,
          payload: err.message,
        });
      });
  };

export const requestApproval = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  axios
    .post(`${process.env.REACT_APP_BASE_URL}is_event_requested/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
      return message.info("Approval request sent successfully!");
    })
    .catch((err) => {
      return message.error("Error while sending approval request!");
    });
};

export const postApproval = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  axios
    .post(`${process.env.REACT_APP_BASE_URL}event_approval/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });

      setTimeout(() => {
        window.location.reload();
      }, 500);

      return message.success("Event status approved succesfully!");
    })
    .catch((err) => {
      return message.error("Error while approving status!");
    });
};

export const getDrawings = () => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  dispatch({ type: GET_DRAWING_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}list_of_drawings/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
      },
    })
    .then((result) => {
      dispatch({ type: GET_DRAWING_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      return message.error("Error while getting drawings!");
    });
};

export const postBoq = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  axios
    .post(`${process.env.REACT_APP_BASE_URL}upload_boq_file/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      return message.info("BOQ Uploaded successfully!");
    })
    .catch((err) => {
      return message.error("Error while uploading BOQ!");
    });
};

export const postBoM = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.POST_BOQ_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}upload_boq_file/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    await dispatch({ type: Actions.POST_BOQ_SUCCESS, payload: result.data });
    message.success("BoM file uploaded successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while uploading BoM!", 1);
    dispatch({ type: Actions.POST_BOQ_FAILURE, payload: e });
    return e;
  }
};

export const deleteBoM = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.DELETE_BOQ_LOADING, payload: data });

  await message.loading("Deleting...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_boq/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: Actions.DELETE_BOQ_SUCCESS, payload: result.data });
    await message.success("BoQ deleted successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while deleting BoQ!", 1);
    dispatch({ type: Actions.DELETE_BOQ_FAILURE, payload: e });
    return e;
  }
};

export const downloadGantt =
  (report_type, start_date, setLoading) => (dispatch) => {
    let userData;
    let projectId;

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }

    if (getItem("projectId")) {
      projectId = JSON.parse(getItem("projectId"));
    }

    let formdata = new FormData();
    formdata.append("project_id", projectId);
    formdata.append("report_type", report_type);
    if (start_date) {
      formdata.append("date", start_date);
    }
    const hide = message.loading("Generating Report..", 0);
    setTimeout(hide, 1000);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}download_gantt/`, formdata, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      })
      .then((result) => {
        setLoading(false);
        if (result?.data?.status == "FAIL") {
          return message.error(result?.data?.response);
        }
        dispatch(actionCreators1.getProjectReports());
        message.success("Generated the File Successfully!");
        var win = window.open(result.data.report_url, "_blank");
        win.focus();
      })
      .catch((err) => {
        setLoading(false);
        return message.error(err.message);
      });
  };

export const getSampleGantts = () => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_SAMPLE_GANTTS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_sample_gantt/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({ type: GET_SAMPLE_GANTTS_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({ type: GET_SAMPLE_GANTTS_FAILURE, payload: err.message });
    });
};

export const getSampleGanttSpecifications = (id, projectType) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_SAMPLE_GANTT_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_gantt_spec/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        gantt_id: id,
        type: projectType,
      },
    })
    .then((result) => {
      dispatch({
        type: GET_SAMPLE_GANTT_DETAILS_SUCCESS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SAMPLE_GANTTS_DETAILS_FAILURE,
        payload: err.message,
      });
    });
};

export const resetSampleGanttSpecifications = (id) => (dispatch) => {
  dispatch({ type: GET_SAMPLE_GANTTS_DETAILS_RESET, payload: null });
};

export const getSampleGanttEvents = (ganttId, stage) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_SAMPLE_GANTT_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_gantt_events/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        gantt_id: ganttId,
        stage: stage,
      },
    })
    .then((result) => {
      dispatch({
        type: GET_SAMPLE_GANTT_DETAILS_SUCCESS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SAMPLE_GANTTS_DETAILS_FAILURE,
        payload: err.message,
      });
    });
};

export const getSampleGanttProjectDetails = (id) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_SAMPLE_GANTTS_PROJECT_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_gantt_details_for_a_project/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        gantt_id: id,
      },
    })
    .then((result) => {
      dispatch({
        type: GET_SAMPLE_GANTTS_PROJECT_DETAILS_SUCCESS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SAMPLE_GANTTS_PROJECT_DETAILS_FAILURE,
        payload: err.message,
      });
    });
};

export const updateSpecification = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(`${process.env.REACT_APP_BASE_URL}update_project_spec/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
    })
    .catch((err) => {
      return message.error("Somethig went wrong");
    });
};

export const deleteTaksHandler = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(`${process.env.REACT_APP_BASE_URL}delete_task/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
    })
    .catch((err) => {
      return message.error("Some error ocurred!");
    });
};

export const ganttDetailsForProject = () => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: GET_GANTTS_PROJECT_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_sample_gantt/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        is_sub_contractor: isSubContractor,
      },
    })
    .then((result) => {
      dispatch({
        type: GET_GANTTS_PROJECT_DETAILS_SUCCESS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_GANTTS_PROJECT_DETAILS_FAILURE,
        payload: err.message,
      });
      return message.error("Some error occurred!");
    });
};

export const deleteSampleGantt = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(`${process.env.REACT_APP_BASE_URL}delete_gantt/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        dispatch({ type: GET_UPDATE_SUCCESS, payload: err.response.status });
      } else {
        return message.error("Some error occurred!");
      }
    });
};

export const changeStatus = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}change_event_status/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          is_sub_contractor: isSubContractor,
        },
      }
    );
    dispatch({ type: GET_UPDATE_SUCCESS, payload: null });
    return result;
  } catch (e) {
    return message.error("Some error occurred!");
  }
};

export const getSampleEvents = () => (dispatch) => {
  let projectId;
  let userData;

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_ALL_SAMPLE_EVENT_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_all_sample_events/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        project_id: projectId,
      },
    })
    .then((result) => {
      dispatch({ type: GET_ALL_SAMPLE_EVENT_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      return message.error("Some error occurred!");
    });
};

export const getSampleEvent =
  ({ sampleEventId }) =>
  (dispatch) => {
    let projectId;
    let userData;

    if (getItem("projectId")) {
      projectId = JSON.parse(getItem("projectId"));
    }

    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }

    dispatch({ type: GET_SAMPLE_EVENT_LOADING, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}get_sample_event/`, {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          sample_event_id: sampleEventId,
          project_id: projectId,
        },
      })
      .then((result) => {
        dispatch({ type: GET_SAMPLE_EVENT_SUCCESS, payload: result.data });
      })
      .catch((err) => {
        return message.error("Some error occurred!");
      });
  };

export const addEvent = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: POST_SAMPLE_EVENT_LOADING, payload: null });

  await message.loading("Saving...", 2);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_new_event/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: POST_SAMPLE_EVENT_SUCCESS, payload: result.data });
    await message.success("Event created successfully", 2);
    return result;
  } catch (e) {
    message.error("Error occurred while saving");
    return e;
  }
};

export const toggleTask = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));

  dispatch({ type: TOGGLE_TASK_LOADING, payload: null });
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}toggle_task/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
        params: {
          is_sub_contractor: isSubContractor,
        },
      }
    );
    dispatch({ type: TOGGLE_TASK_SUCCESS, payload: result.data });
    await message.success("Event status updated", 0.5);
    return result;
  } catch (e) {
    return message.error("Some error occurred!", 1);
  }
};

export const customerEventApproval = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: POST_CUSTOMER_EVENT_APPROVAL_LOADING, payload: null });

  await message.loading("Loading...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}customer_event_approval/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: POST_CUSTOMER_EVENT_APPROVAL_SUCCESS,
      payload: result.data,
    });
    await message.success("Approved!", 0.5);
    return result;
  } catch (e) {
    if (e?.status === "FAIL") {
      message.error(e.response, 2);
    } else {
      message.error("Error occurred while approving event!", 1);
    }
    dispatch({ type: POST_CUSTOMER_EVENT_APPROVAL_FAILURE, payload: e });
    return e;
  }
};
export const updateCustomerApprovalRequest = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({
    type: POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_LOADING,
    payload: null,
  });

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}update_customer_approval_request/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      dispatch({
        type: POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_SUCCESS,
        payload: result.data,
      });
      // message.info("Task has changed!");
      return result;
    })
    .catch((err) => {
      dispatch({
        type: POST_UPDATE_CUSTOMER_APPROVAL_REQUEST_FAILURE,
        payload: err,
      });
      return message.error("Some error occurred!");
    });
};

export const plannedStartDate = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  axios
    .post(`${process.env.REACT_APP_BASE_URL}change_event_start_date/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {
      return message.error("Error occurred while updating event start date!");
    });
};

export const updateEventTodo = (data, eventId) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  const isSubContractor = JSON.parse(getItem("isSubContractor"));
  axios
    .post(`${process.env.REACT_APP_BASE_URL}update_event_todo/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (eventId) {
        dispatch(getEventData(eventId, isSubContractor));
      }
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {
      return message.error("Error occurred while updating!");
    });
};

export const plannedEndDate = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}change_event_complete_date/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    )
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {
      return message.error("Error occurred while updating event end date!");
    });
};

export const eventMarkedMandatory = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(`${process.env.REACT_APP_BASE_URL}event_marked_mandatory/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {});
};

export const todoMarkedComplete = (data) => (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  axios
    .post(`${process.env.REACT_APP_BASE_URL}todo_marked_complete/`, data, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (result.data.status === "FAIL") {
        return message.error(result.data.error);
      } else {
        return message.success("Updated successfully!");
      }
    })
    .catch((err) => {});
};

export const raisePO = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.RAISE_PO_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}raise_purchased_order/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({ type: Actions.RAISE_PO_SUCCESS, payload: result.data });
    await message.success("PO raised successfully", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while raising PO!", 1);
    dispatch({ type: Actions.RAISE_PO_FAILURE, payload: e });
    return e;
  }
};

export const addTerminationAndStringDetails = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({
    type: Actions.ADD_TERMINATION_AND_STRING_DETAILS_LOADING,
    payload: data,
  });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_termination_and_string_details/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.ADD_TERMINATION_AND_STRING_DETAILS_SUCCESS,
      payload: result.data,
    });
    // getEvent(dispatch);
    await message.success("Saved successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({
      type: Actions.ADD_TERMINATION_AND_STRING_DETAILS_FAILURE,
      payload: e,
    });
    return e;
  }
};

export const updateTerminationAndStringDetails = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({
    type: Actions.UPDATE_TERMINATION_AND_STRING_DETAILS_LOADING,
    payload: data,
  });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_termination_and_string_details/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.UPDATE_TERMINATION_AND_STRING_DETAILS_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Saved successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({
      type: Actions.UPDATE_TERMINATION_AND_STRING_DETAILS_FAILURE,
      payload: e,
    });
    return e;
  }
};

/**
 * Updates the material status for the Material Status event.
 *
 * Status values: Dispatched, Delivered, Unloaded, Stored and Shifting
 *
 * Form keys: inventory_id, stage(status of the material)
 * @param {FormData} data
 */
export const updateMaterialStatus = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({ type: Actions.UPDATE_MATERIAL_STATUS_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_material_status/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.UPDATE_MATERIAL_STATUS_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Updated successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({ type: Actions.UPDATE_MATERIAL_STATUS_FAILURE, payload: e });
    return e;
  }
};

/**
 * Updates the component status for the Plant Equipment Testing event.
 *
 * Status values: Tested, Not Tested and Failed
 *
 * Form keys: project_id, status and component_index
 * @param {FormData} data
 */
export const updateComponentStatus = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({ type: Actions.UPDATE_COMPONENT_STATUS_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_component_status/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.UPDATE_COMPONENT_STATUS_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Updated successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({ type: Actions.UPDATE_COMPONENT_STATUS_FAILURE, payload: e });
    return e;
  }
};

/**
 * Updates the component progress for the Marking of the Solar Power Plant event.
 *
 * Progress values: Done and Not Done
 *
 * Form keys: project_id, status, is_bulk and component_index
 * @param {FormData} data
 */
export const updateComponentProgress = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({ type: Actions.UPDATE_COMPONENT_PROGRESS_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_component_progress/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.UPDATE_COMPONENT_PROGRESS_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Updated successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({ type: Actions.UPDATE_COMPONENT_PROGRESS_FAILURE, payload: e });
    return e;
  }
};

/**
 * Updates the component progress for the Marking of the Solar Power Plant event.
 *
 * Status values: Pass and Fail
 *
 * Form keys: checklist_id, status, picture and remarks
 * @param {FormData} data
 */
export const updateInstallationChecklist = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({ type: Actions.UPDATE_COMPONENT_PROGRESS_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_installation_checklists/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.UPDATE_COMPONENT_PROGRESS_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Updated successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({ type: Actions.UPDATE_COMPONENT_PROGRESS_FAILURE, payload: e });
    return e;
  }
};

/**
 * Edits the specification for the "Termination of Strings on Inverter Side" event.
 *
 * Form keys: event_id, date, number_of_termination, comment and image
 * @param {FormData} data
 */
export const editTerminationOfStringsSpec = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  data.append("event_id", JSON.parse(getItem("eventId")));

  dispatch({
    type: Actions.EDIT_TERMINATION_OF_STRINGS_SPEC_LOADING,
    payload: data,
  });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}termination_of_strings_edit_spec/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.EDIT_TERMINATION_OF_STRINGS_SPEC_SUCCESS,
      payload: result.data,
    });
    getEvent(dispatch);
    await message.success("Updated successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred!", 1);
    dispatch({
      type: Actions.EDIT_TERMINATION_OF_STRINGS_SPEC_FAILURE,
      payload: e,
    });
    return e;
  }
};

export const uploadProjectInventory = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.UPLOAD_PROJECT_INVENTORY_LOADING, payload: data });

  await message.loading("Saving...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}upload_bom/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    await dispatch({
      type: Actions.UPLOAD_PROJECT_INVENTORY_SUCCESS,
      payload: result.data,
    });
    message.success("Project inventory uploaded successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while uploading project inventory!", 1);
    dispatch({ type: Actions.UPLOAD_PROJECT_INVENTORY_FAILURE, payload: e });
    return e;
  }
};

export const deleteProjectInventory = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: Actions.DELETE_PROJECT_INVENTORY_LOADING, payload: data });

  await message.loading("Deleting...", 0.5);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_bom/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    dispatch({
      type: Actions.DELETE_PROJECT_INVENTORY_SUCCESS,
      payload: result.data,
    });
    await message.success("Project inventory deleted successfully.", 0.5);
    return result;
  } catch (e) {
    message.error("Error occurred while deleting project inventory!", 1);
    dispatch({ type: Actions.DELETE_PROJECT_INVENTORY_FAILURE, payload: e });
    return e;
  }
};

// approve  doc

export const approveDoc = (data,type) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  let url;
  if (type === "engineering") {
    url = "approve_engineering_doc";
  } else {
    url = "approve_mdcc_doc";
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${url}/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    message.info(result?.data?.message);
    return result;
  } catch (e) {
    return message.error("Something went wrong");
  }
};
