import {
    GET_DRAWING_LOADING,
    GET_DRAWING_SUCCESS,
    GET_DRAWING_FAILURE
} from '../../actions/types';

const INITIAL_STATE = {
    drawing: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};

const drawingReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DRAWING_LOADING:
            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    get: {
                        ...state.drawing.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.drawing.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_DRAWING_SUCCESS:
            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    get: {
                        ...state.drawing.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.drawing.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_DRAWING_FAILURE:
            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    get: {
                        ...state.drawing.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.drawing.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
    }
    return state;
}

export default drawingReducers;    