import {
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Progress, Table, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import {
  carBusPortAreas,
  projectSpecList,
  electricalDetailsList,
  metalSheetBuildings,
  groundMountAreas,
  rccBasicBuildings,
  proposedLocationList,
} from "./scrollToCard.js";
import "./site-left-card.style.less";
import SiteVistContext from "./SiteVisitContext";
import {
  generateSiteVisitReport,
  getSiteVisitDetails,
} from "../../actions/get-site-visit-action/index.js";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function SiteLeftCard({
  title,
  collapseTabs,
  isCollapse,
  buildingAreaType,
  selectedBuildingArea,
  siteVisitId,
}) {
  const siteVisitContext = useContext(SiteVistContext);

  const {
    activeCollapseTab,
    handleActiveCollapseTab,
    activeTab,
    handleInnerCollapseId,
    activeCollapseInnerId,
    siteRef,
    electricalRef,
    rccRef,
    rccBuildingDetails,
    metalSheetRef,
    metalSheetDetails,
    groundMountRef,
    groundMountDetails,
    carPortRef,
    carPortDetails,
    electricalDetails,
    searchElement,
    setSearchElement,
    searchData,
    setSearchData,
    tabsList,
    projectSpecRef,
    totalDocuments,
    proposedLocationDetails,
    proposedLocationRef,
    specs,
  } = siteVisitContext;
  const onChange = (e) => {
    setSearchElement(e.target.value);
  };

  const [file, setFile] = useState(null);
  const [date, setDate] = useState("");

  const summarySearchData = [
    { id: 0, name: "Company Details", ref: siteRef },
    { id: 1, name: "Contact Details", ref: siteRef },
    { id: 2, name: "Project Details", ref: siteRef },
  ];
  useEffect(() => {
    if (searchElement) {
      if (title === "Client Details") {
        setSearchData(summarySearchData);
      } else if (title === "Electrical Details") {
        setSearchData(electricalDetailsList);
      } else if (title === "Proposed Locations") {
        setSearchData(proposedLocationList);
      } else if (selectedBuildingArea === "RCC buildings") {
        setSearchData([...rccBasicBuildings]);
      } else if (selectedBuildingArea === "Metal Sheet") {
        setSearchData([...metalSheetBuildings]);
      } else if (selectedBuildingArea === "Ground Mount") {
        setSearchData([...groundMountAreas]);
      } else if (selectedBuildingArea === "Car/Bus Port") {
        setSearchData([...carBusPortAreas]);
      } else {
        setSearchData([...projectSpecList]);
      }
    }
  }, [title]);

  const dispatch = useDispatch();
  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  useEffect(() => {
    if (getSiteVisitDetailsReducer.success.data) {
      setFile(getSiteVisitDetailsReducer.success.data?.report);
      setDate(
        moment(
          getSiteVisitDetailsReducer.success.data?.report_date,
          "DD-MM-YYYY"
        ).format("DD MMM, YYYY")
      );
    }
  }, [getSiteVisitDetailsReducer.success.data]);
  const searchHandler = (data) => {
    setSearchData(
      data
        ?.filter((i) => {
          return i.name.toLowerCase().includes(searchElement?.toLowerCase());
        })
        .map((i) => {
          return {
            id: i.id,
            title: i.name,
            ref: i.ref,
          };
        })
    );
  };

  useEffect(() => {
    if (searchElement) {
      if (title === "Client Details") {
        searchHandler(summarySearchData);
      } else if (title === "Electrical Details") {
        searchHandler(
          electricalDetailsList.map((obj) => ({ ...obj, ref: electricalRef }))
        );
      } else if (title === "Proposed Locations") {
        searchHandler(
          proposedLocationList.map((obj) => ({
            ...obj,
            ref: proposedLocationRef,
          }))
        );
      } else if (selectedBuildingArea === "RCC buildings") {
        searchHandler([
          ...rccBasicBuildings.map((obj) => ({ ...obj, ref: rccRef })),
        ]);
      } else if (selectedBuildingArea === "Metal Sheet") {
        searchHandler([
          ...metalSheetBuildings.map((obj) => ({
            ...obj,
            ref: metalSheetRef,
          })),
        ]);
      } else if (selectedBuildingArea === "Ground Mount") {
        searchHandler([
          ...groundMountAreas.map((obj) => ({ ...obj, ref: groundMountRef })),
        ]);
      } else if (selectedBuildingArea === "Car/Bus Port") {
        searchHandler([
          ...carBusPortAreas.map((obj) => ({ ...obj, ref: carPortRef })),
        ]);
      } else {
        searchHandler([
          ...projectSpecList.map((obj) => ({ ...obj, ref: projectSpecRef })),
        ]);
      }
    }
  }, [searchElement]);

  const calPercentage = (values, key, cardName) => {
    const el = document.activeElement;
    let items = values?.map((i) => key[i]);

    let count = items?.reduce((acc, val) => {
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val && val.hasOwnProperty("showFile")) {
        if (val?.existing_files?.length > 0 || val?.files?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    if (count == items?.length) {
      return [100, true];
    } else {
      if (el.getAttribute("card") === cardName) {
        return [(count / items?.length) * 100, true];
      } else {
        return [(count / items?.length) * 100, false];
      }
    }
  };

  const searchColumns = [
    {
      title: "Section name",
      dataIndex: "title",
      width: "75%",
    },

    {
      title: "",
      dataIndex: "redirect",
      width: "25%",
    },
  ];

  const handleScroll = (items, ref, key) => {
    return items.map((k, index) => {
      let temp =
        items[index]?.items === "multiple"
          ? key[items[index]?.item] == "Soil"
            ? calPercentage(items[index]?.item1, key, items[index]?.name)
            : key[items[index]?.item] == "RCC"
            ? calPercentage(items[index]?.item2, key, items[index]?.name)
            : calPercentage(items[index]?.item3, key, items[index]?.name)
          : items[index]?.items === "check"
          ? key[items[index]?.item]
            ? calPercentage(items[index]?.item1, key, items[index]?.name)
            : calPercentage(items[index]?.item2, key, items[index]?.name)
          : calPercentage(items[index]?.items, key, items[index]?.name);

      return (
        <div
          className={
            activeCollapseInnerId === k.id
              ? "inner-collapse-text active"
              : "inner-collapse-text"
          }
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleInnerCollapseId(k.id);
            let element = ref.current[index];
            if (element) {
              element.scrollIntoView();
            }
          }}
        >
          <span>
            {temp[0] === 100 ? (
              <img src={require("../../assets/logos/ic-actions-check.svg")} />
            ) : temp[1] == true ? (
              <img src={require("../../assets/sitevisit/progress.png")} />
            ) : (
              <div
                className="img-container"
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  border: "1px dotted #ffb300",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            )}
          </span>{" "}
          <p>{k.name}</p>
        </div>
      );
    });
  };

  const handleProjectSpec = (items) => {
    return items.map((k, index) => {
      return (
        <div
          className={
            activeCollapseInnerId === index
              ? "inner-collapse-text active"
              : "inner-collapse-text"
          }
          onClick={() => {
            handleInnerCollapseId(index);
            let element = projectSpecRef.current[k.index + 1];
            if (element) {
              element.scrollIntoView();
            }
          }}
        >
          <span>
            {k.is_uploaded === true ? (
              <img src={require("../../assets/logos/ic-actions-check.svg")} />
            ) : (
              <div
                className="img-container"
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  border: "1px dotted #ffb300",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            )}
          </span>{" "}
          <p>{k.name}</p>
        </div>
      );
    });
  };
  const generateReport = () => {
    Promise.resolve(dispatch(generateSiteVisitReport(siteVisitId))).then(
      (res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
        }
      }
    );
  };
  return (
    <div className="site-left-card">
      <div className="upper-section">
        <div style={{ borderBottom: "1px solid #eaeaea" }}>
          <div className="first-row">
            <div className="progress-wrapper">
              <Progress
                type="circle"
                status="active"
                showInfo
                percent={tabsList[activeTab]?.progress}
                strokeWidth={10}
                strokeColor={
                  tabsList[activeTab]?.progress === 100 ? "#00b680" : "#f1a73d"
                }
                trailColor={"#f1f1f1"}
              />
            </div>
            <div className="title">
              <h1>{title}</h1>
              <p>Your Progress will be automatically saved for later.</p>
            </div>
          </div>
          <div className="second-row">
            <p>SPOC-Kaustubh</p>
          </div>
          <div className="third-row">
            <div className="search-wrapper">
              <div className="search-input">
                <input
                  type="search"
                  onChange={onChange}
                  placeholder={"Search for section name or question"}
                  value={searchElement}
                />
                <div
                  className="search-icon"
                  onClick={() => document.querySelector("#searchInput").focus()}
                >
                  <img
                    src={require("../../assets/logos/Search.png")}
                    alt="search-logo"
                  />
                </div>
              </div>
            </div>

            <Table
              className={
                searchData?.length > 0 ? "search-table" : "search-table-empty"
              }
              scroll={{ y: "150px" }}
              columns={searchColumns}
              dataSource={searchData}
              pagination={false}
              onRow={(r) => ({
                onClick: () => {
                  let element = r.ref.current[r.id];
                  if (element) {
                    element.scrollIntoView();
                  }

                  setSearchData([]);
                },
              })}
            />
          </div>
          <div className="fourth-row">
            <Button onClick={() => generateReport()}>Generate Report</Button>
            <p>
              Last Generated:<span>{file ? date : "Not Generated yet"}</span>
            </p>
          </div>
          <div className="fifth-row">
            <div className="file-container">
              <p>
                {file ? (
                  <a href={file} target="_blank">
                    {file.slice(0, 40)}
                  </a>
                ) : (
                  "Not Generated yet"
                )}
              </p>
              {file ? (
                <div className="icon-container">
                  <div className="icon-box">
                    <a href={file} target="_blank">
                      {" "}
                      <DownloadOutlined />{" "}
                    </a>
                  </div>
                  <div
                    className="icon-box"
                    onClick={() => {
                      window.location = `mailto:https://pmt.safearth.in?subject=${file}`;
                    }}
                  >
                    <SendOutlined />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="collapse-tab-section">
        {collapseTabs.map((i, index) => {
          return (
            <>
              <div
                className={
                  activeCollapseTab === i
                    ? "each-collapse-tab active"
                    : "each-collapse-tab"
                }
                onClick={() => {
                  handleActiveCollapseTab(i);
                  if (title === "Client Details") {
                    siteRef.current[index].scrollIntoView();
                  }
                }}
              >
                <p>{i}</p>
                {isCollapse ? (
                  activeCollapseTab === i ? (
                    <MinusOutlined />
                  ) : (
                    <PlusOutlined />
                  )
                ) : null}
              </div>
              {activeCollapseTab === "Basic Details" &&
              isCollapse &&
              activeTab == 1
                ? handleScroll(
                    electricalDetailsList,
                    electricalRef,
                    electricalDetails
                  )
                : null}
              {selectedBuildingArea === "RCC buildings" &&
              activeCollapseTab === "Basic Details"
                ? handleScroll(rccBasicBuildings, rccRef, rccBuildingDetails)
                : null}

              {selectedBuildingArea === "Metal Sheet" &&
              activeCollapseTab === "Basic Details" &&
              activeCollapseTab == i
                ? handleScroll(
                    metalSheetBuildings,
                    metalSheetRef,
                    metalSheetDetails
                  )
                : null}

              {selectedBuildingArea === "Ground Mount" &&
              activeCollapseTab === "Basic Details" &&
              activeCollapseTab == i
                ? handleScroll(
                    groundMountAreas,
                    groundMountRef,
                    groundMountDetails
                  )
                : null}
              {selectedBuildingArea === "Car/Bus Port" &&
              activeCollapseTab === "Basic Details" &&
              activeCollapseTab == i
                ? handleScroll(carBusPortAreas, carPortRef, carPortDetails)
                : null}
              {title === "Proposed Locations" &&
                handleScroll(
                  proposedLocationList,
                  proposedLocationRef,
                  proposedLocationDetails
                )}
              {title == "Project Specifications" ? (
                <>
                  {handleScroll(
                    projectSpecList.slice(0, 11),
                    projectSpecRef,
                    specs
                  )}
                  {handleProjectSpec(totalDocuments)}
                </>
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SiteLeftCard;
