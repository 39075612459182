import { EditOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext } from "react";
import "./building-area-top-card.style.less";
import FloatInput from "./FloatInput";
import SiteVistContext from "./SiteVisitContext";

function BuildingAreaTopCard({
  label1,
  label2,
  placeholder1,
  placeholder2,
  name1,
  name2,
  onChange,
  value1,
  value2,
}) {
  const siteVisitContext = useContext(SiteVistContext);

  const { handleActiveTabHandler } = siteVisitContext;
  return (
    <div className="top-card">
      <Row>
        <h1 className="inner-text">
          Building/Area Details{" "}
          <span
            onClick={() => handleActiveTabHandler(2)}
            style={{ cursor: "pointer" }}
          >
            <EditOutlined />
          </span>
        </h1>
        <Col span={22}>
          <FloatInput
            label={label1}
            placeholder={placeholder1}
            name={name1}
            onChange={onChange}
            value={value1}
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <FloatInput
            label={label2}
            placeholder={placeholder2}
            name={name2}
            onChange={onChange}
            value={value2}
            readOnly={true}
          />
        </Col>
      </Row>
    </div>
  );
}

export default BuildingAreaTopCard;
