import {
  SCM_DASHBOARD_LOADING,
  SCM_DASHBOARD_SUCCESS,
  SCM_DASHBOARD_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  loading: false,
  success: {
    ok: false,
    data: null,
  },
  failure: {
    error: false,
    message: "",
  },
};

const getSCMDashboardReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SCM_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
        success: {
          ok: false,
          data: null,
        },
        failure: {
          error: false,
          message: "",
        },
      };
    case SCM_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: {
          ...state.success,
          ok: true,
          data: action.payload,
        },
        failure: {
          error: false,
          message: "",
        },
      };
    case SCM_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        success: {
          ...state.success,
          ok: false,
          data: null,
        },
        failure: {
          error: true,
          message: action.payload.message,
        },
      };
  }
  return state;
};

export default getSCMDashboardReducers;
