import {
    GET_VENDOR_DOC_DETAILS_FAILURE,
    GET_VENDOR_DOC_DETAILS_LOADING,
    GET_VENDOR_DOC_DETAILS_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    vendorDocDetails: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};




const vendorDocDetailsReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case  GET_VENDOR_DOC_DETAILS_LOADING:
            return {
                ...state,
                vendorDocDetails: {
                    ...state.vendorDocDetails,
                    get: {
                        ...state.vendorDocDetails.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.vendorDocDetails.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_VENDOR_DOC_DETAILS_SUCCESS:
            return {
                ...state,
                vendorDocDetails: {
                    ...state.vendorDocDetails,
                    get: {
                        ...state.vendorDocDetails.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.vendorDocDetails.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_VENDOR_DOC_DETAILS_FAILURE:
            return {
                ...state,
                vendorDocDetails: {
                    ...state.vendorDocDetails,
                    get: {
                        ...state.vendorDocDetails.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.vendorDocDetails.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };

    }
    return state;
}

export default vendorDocDetailsReducers;