import axios from "axios";
import { getBaseUrl } from "../components/util";
import { setItem } from "../SecureStorage";

export const SSO_AUTHENTICATE_LOADING = "SSO_AUTHENTICATE_LOADING";
export const SSO_AUTHENTICATE_SUCCESS = "SSO_AUTHENTICATE_SUCCESS";
export const SSO_AUTHENTICATE_FAILURE = "SSO_AUTHENTICATE_FAILURE";
export const SSO_AUTHENTICATE_RESET = "SSO_AUTHENTICATE_RESET";

/**
 * Authenticates Single Sign-On.
 *
 * Based on the type of platform, it sends a post request to the respective server for authentication.
 * @param {Object} user The details of the user.
 * @param {String} token The unique token of the user.
 * @param {String} toPlatform
 * @param {String} password
 * The name of the platform from where the token needs to be retrieved.
 *
 * Accepts only two values: "auction" for Project Marketplace and "ecom" for Component Marketplace.
 * @returns
 */
export const authenticateSSO =
  (user, token, toPlatform, password) => async (dispatch) => {
    dispatch({
      type: SSO_AUTHENTICATE_LOADING,
      payload: null,
    });

    const formData = new FormData();
    formData.append("email", user?.username);
    formData.append("token", token);
    formData.append("from_platform", "pmt");
    formData.append("company_name", user?.company_name);
    formData.append("name", user?.username);
    formData.append("password", password);
    if (toPlatform === "ecom") {
      if (!user?.is_consumer) {
        formData.append("is_buyer", true);
      } else {
        formData.append("is_buyer", false);
      }
    } else if (toPlatform === "auction") {
      if (user?.is_consumer) {
        formData.append("is_consumer", true);
        formData.append("is_vendor", false);
      } else {
        formData.append("is_consumer", false);
        formData.append("is_vendor", true);
      }

      formData.append("website", user?.website);
      formData.append("phone", user?.phone);
    }

    const BASE_URL = getBaseUrl(toPlatform);

    try {
      const result = await axios.post(
        `${BASE_URL}sso_authenticate/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result?.data?.status === "FAIL") {
        throw result.data;
      }
      setItem(
        `${toPlatform}Token`,
        result?.data?.pending_vendor_id
          ? result?.data?.pending_vendor_id
          : result?.data?.token
      );
      setItem(`is_new_${toPlatform}_user`, result?.data?.is_new || false);

      const userType = result?.data?.is_buyer ? "isBuyer" : "isSeller";
      if (toPlatform === "ecom") {
        setItem("ecom_user_type", userType);
        dispatch({
          type: SSO_AUTHENTICATE_SUCCESS,
          payload: {
            [toPlatform]: {
              token: result?.data?.token,
              is_new: result?.data?.is_new,
              userType: userType,
            },
          },
        });
      } else {
        if (result?.data?.pending_vendor_id) {
          setItem("pending_vendor_id", result?.data?.pending_vendor_id);
        }
        dispatch({
          type: SSO_AUTHENTICATE_SUCCESS,
          payload: {
            [toPlatform]: {
              token: result?.data?.pending_vendor_id
                ? result?.data?.pending_vendor_id
                : result?.data?.token,
              is_new: result?.data?.is_new,
              pending_vendor_id: result?.data?.pending_vendor_id,
            },
          },
        });
      }
      return result;
    } catch (e) {
      if (e?.status === "FAIL") {
        dispatch({
          type: SSO_AUTHENTICATE_FAILURE,
          payload: e.response,
        });
      } else {
        dispatch({ type: SSO_AUTHENTICATE_FAILURE, payload: e });
      }
      return e;
    }
  };

/**
 * Handler function to authenticate Single Sign-On.
 * @param {Object} user The details of the user.
 * @param {String} token The unique token of the user.
 * @param {Function} dispatch
 * @param {String} password
 */
export const ssoAuthenticator = (user, token, dispatch, password) => {
  Promise.resolve(
    dispatch(authenticateSSO(user, token, "auction", password))
  ).then((result) => {
    if (result?.status === 200) {
      Promise.resolve(
        dispatch(authenticateSSO(user, token, "ecom", password))
      ).then((result) => {
        //success
      });
    }
  });
};
