import {
  GET_ALL_LOAN_LEADS_LOADING,
  GET_ALL_LOAN_LEADS_SUCCESS,
  GET_ALL_LOAN_LEADS_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  loanLeads: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const getLoanLeadsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOAN_LEADS_LOADING:
      return {
        ...state,
        loanLeads: {
          ...state.loanLeads,
          get: {
            ...state.loanLeads.get,
            loading: true,
            reset: false,
            success: {
              ...state.loanLeads.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_ALL_LOAN_LEADS_SUCCESS:
      return {
        ...state,
        loanLeads: {
          ...state.loanLeads,
          get: {
            ...state.loanLeads.get,
            loading: false,
            reset: false,
            success: {
              ...state.loanLeads.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_ALL_LOAN_LEADS_FAILURE:
      return {
        ...state,
        loanLeads: {
          ...state.loanLeads,
          get: {
            ...state.loanLeads.get,
            loading: false,
            reset: false,
            success: {
              ...state.loanLeads.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
  }
  return state;
};

export default getLoanLeadsReducer;
