import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import "./site-tabs-header.style.less";
import SiteVistContext from "./SiteVisitContext";

function SiteTabsHeader({ tabs }) {
  const siteVisitContext = useContext(SiteVistContext);
  const {
    activeTab,
    handleActiveTabHandler,
    tabsList,
    startIndex,
    endIndex,
    handleStartIndex,
    handleEndIndex,
  } = siteVisitContext;

  const moveLeft = () => {
    if (startIndex != 0) {
      handleEndIndex(endIndex - 1);
      handleStartIndex(startIndex - 1);
    }
  };
  const moveRight = () => {
    if (endIndex != tabsList.length) {
      handleEndIndex(endIndex + 1);
      handleStartIndex(startIndex + 1);
    }
  };
  return (
    <>
      <div className="site-tabs-header">
        <div className="tabs-container">
          <LeftOutlined
            onClick={() => moveLeft()}
            className={startIndex == 0 ? "disabled" : "enabled"}
          />
          <div className="tabs-grp">
            {tabsList.slice(startIndex, endIndex).map((i) => {
              return (
                <span
                  className={
                    activeTab === i.id ? "tab-name active" : "tab-name"
                  }
                  onClick={() => handleActiveTabHandler(i.id)}
                >
                  {i.name}
                </span>
              );
            })}
          </div>
          <RightOutlined
            onClick={() => moveRight()}
            className={endIndex == tabsList.length ? "disabled" : "enabled"}
          />
        </div>
      </div>
    </>
  );
}

export default SiteTabsHeader;
