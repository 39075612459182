import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { placeholder } from "@babel/types";

const placesLibrary = ["places"];
const containerStyle = {
  width: "500px",
  height: "400px",
};

const zoom = 10;
const defaultLocation = {
  lat: 20.5937,
  lng: 78.9629,
};

const GetLocation = ({
  value,
  setValue,
  name,
  isShowMap,
  setIsShowMap,
  state,
}) => {
  const [location, setLocation] = useState();
  const [map, setMap] = React.useState(null);
  const [searchResult, setSearchResult] = useState("");

  useEffect(() => {
    if (value && state && typeof value !== String) {
      setLocation(value);
    } else if (name && value[name] && typeof value[name] === "object") {
      setLocation(value[name]);
    } else {
      setLocation(defaultLocation);
    }
  }, [value, state]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBiC9QQMjZJR8lXreptG84hvlBXMfWK3ZY",
    libraries: placesLibrary,
  });

  const handleOk = () => {
    setIsShowMap(false);
  };
  const handleCancel = () => {
    setIsShowMap(false);
  };

  const onMapLoad = React.useCallback(
    function callback(map) {
      // const bounds = new window.google.maps.LatLngBounds(location);
      // map.fitBounds(bounds);

      setMap(map);
    },
    [location]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoaded = (autocomplete) => {
    setSearchResult(autocomplete);
  };
  const onClick = (e) => {

    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    if (state) {
      setValue({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    }
    setValue({
      ...value,
      [name]: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });

    setIsShowMap(false);
  };
  const onPlaceChanged = (place) => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
  
      setLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      if (state) {
        setValue({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      } else {
        setValue({
          ...value,
          [name]: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        });
      }

      setIsShowMap(false);
    } else {
      message.error("Please enter your search keyword!");
    }
  };

  if (!isLoaded) {
    return (
      <>
        <h1 style={{ textAlign: "center", fontSize: "2rem", color: "#3d87f1" }}>
          Loading....
        </h1>
      </>
    );
  } else {
    return (
      <>
        <Modal
          visible={isShowMap}
          className="site-visit-modal"
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          destroyOnClose
          width={600}
          footer={null}
        >
          <>
            <div id="searchColumn">
              <Autocomplete
                onPlaceChanged={(place) => onPlaceChanged(place)}
                onLoad={onLoaded}
              >
                <input
                  type="text"
                  placeholder="Search Your Location"
                  style={{
                    padding: "0 9px",
                    border: `1px solid #eaeaea`,
                    width: `500px`,
                    height: `3rem`,
                    borderRadius: `5px`,
                    fontSize: `14px`,
                    margin: "2rem 0",
                    outline: `none`,
                    textOverflow: `ellipses`,
                  }}
                />
              </Autocomplete>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={location}
              zoom={zoom}
              onLoad={onMapLoad}
              onUnmount={onUnmount}
              onClick={onClick}
            >
              <>
                <Marker position={location} draggable={true} />
              </>
            </GoogleMap>
          </>
        </Modal>
      </>
    );
  }
};

export default GetLocation;
