import { GET_SUPPLIERS_LOADING, GET_SUPPLIERS_SUCCESS,GET_SUPPLIERS_FAILURE } from "../../actions/types";

const initialState = {
  suppliers: {
    get: {
      loading: true,
      success: {
        ok: false,
        data: null,
    },
    failure: {
        error: false,
        message: '',
    },
    },
  },
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS_LOADING:
      return {
        ...state,
        suppliers: {
            ...state.suppliers,
            get: {
                ...state.suppliers.get,
                loading: true,
                success: {
                    ...state.suppliers.get.success,
                    ok: false,
                },
                failure: {
                    error: false,
                    message: '',
                },
            },
        },
    }
    case GET_SUPPLIERS_SUCCESS:
        return {
            ...state,
            suppliers:{
                ...state.suppliers,
                get:{
                    ...state.suppliers.get,
                    loading:false,
                    success:{
                        ...state.suppliers.get.success,
                        ok:true,
                        data:action.payload
                    },
                    failure:{
                        error:false,
                        message:'',
                    }
                }
            }
        }

        case GET_SUPPLIERS_FAILURE:
        return {
            ...state,
            suppliers:{
                ...state.suppliers,
                get:{
                    ...state.suppliers.get,
                    loading:false,
                    success:{
                        ...state.suppliers.get.success,
                        ok:false,
                        data:null,
   
                    },
                    failure:{
                        error:true,
                        message:action.payload.message,
                    }
                }
            }
        }


    default:
      return state;
  }
};

export default suppliersReducer;
