import {
    GET_PROJECT_PHOTOS_LOADING,
    GET_PROJECT_PHOTOS_SUCCESS,
    GET_PROJECT_PHOTOS_FAILURE,
  } from "../../actions/types";
  
  const INITIAL_STATE = {
    projectPhotos: {
      get: {
        loading: false,
        reset: false,
        success: {
          ok: false,
          data: null,
        },
        failure: {
          error: false,
          message: "",
        },
      },
    },
  };
  
  const projectPhotosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PROJECT_PHOTOS_LOADING:
        return {
          ...state,
          projectPhotos: {
            ...state.projectPhotos,
            get: {
              ...state.projectPhotos.get,
              loading: true,
              reset: false,
              success: {
                ...state.projectPhotos.get.success,
                ok: false,
                data: null,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
  
      case GET_PROJECT_PHOTOS_SUCCESS:
        return {
          ...state,
          projectPhotos: {
            ...state.projectPhotos,
            get: {
              ...state.projectPhotos.get,
              loading: false,
              reset: false,
              success: {
                ...state.projectPhotos.get.success,
                ok: true,
                data: action.payload,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
  
      case GET_PROJECT_PHOTOS_FAILURE:
        return {
          ...state,
          projectPhotos: {
            ...state.projectPhotos,
            get: {
              ...state.projectPhotos.get,
              loading: false,
              reset: false,
              success: {
                ...state.projectPhotos.get.success,
                ok: false,
                data: null,
              },
              failure: {
                error: true,
                message: action.payload.message,
              },
            },
          },
        };
  
      default:
          return state
    }
  };
  
  export default projectPhotosReducer;
  