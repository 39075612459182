import {
  GET_PROJECT_INVENTORY_LOADING,
  GET_PROJECT_INVENTORY_SUCCESS,
  GET_PROJECT_INVENTORY_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
    materialUsed: {
      get: {
        loading: false,
        reset: false,
        success: {
          ok: false,
          data: null,
        },
        failure: {
          error: false,
          message: "",
        },
      },
    },
  };
  
  const materialUsedReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PROJECT_INVENTORY_LOADING:
        return {
            ...state,
            materialUsed: {
              ...state.materialUsed,
              get: {
                ...state.materialUsed.get,
                loading: true,
                reset: false,
                success: {
                  ...state.materialUsed.get.success,
                  ok: false,
                  data: null,
                },
                failure: {
                  error: false,
                  message: "",
                },
              },
            },
            
        };
  
      case GET_PROJECT_INVENTORY_SUCCESS:
        return {
          ...state,
          materialUsed: {
            ...state.materialUsed,
            get: {
              ...state.materialUsed.get,
              loading: false,
              reset: false,
              success: {
                ...state.materialUsed.get.success,
                ok: true,
                data: action.payload,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
        };
  
      case GET_PROJECT_INVENTORY_FAILURE:
        return {
          ...state,
          materialUsed: {
            ...state.materialUsed,
            get: {
              ...state.materialUsed.get,
              loading: false,
              reset: false,
              success: {
                ...state.materialUsed.get.success,
                ok: false,
                data: null,
              },
              failure: {
                error: true,
                message: action.payload.message,
              },
            },
          },
        };
  
      default:
        return state;
    }
  };
  
  export default materialUsedReducer;