import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, message, Modal, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addSiteVisit,
  getSiteVisit,
  updateSiteVisit,
} from "../../actions/get-site-visit-action";
import "./create-site-visit-modal.style.less";
import moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router-dom";

const { Option } = Select;

const CreateSiteVisitModal = ({
  visible,
  setVisible,
  editSiteVisitId,
  editSiteVisitData,
  history,
}) => {
  const [companyName, setCompanyName] = useState();
  const [siteVisitDate, setSiteVistDate] = useState();
  const [address, setAddress] = useState();
  const [spoc, setSpoc] = useState();

  const getCompanySpocsReducer = useSelector((state) => {
    return state.getCompanySpocsReducers.company.get;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(editSiteVisitData)) {
      setCompanyName(editSiteVisitData?.company_name);
      setAddress(editSiteVisitData?.location);
      setSiteVistDate(editSiteVisitData?.site_visit_date);
      setSpoc(editSiteVisitData?.spoc_id);
    } else {
      resetFields();
    }
  }, [editSiteVisitData]);

  const resetFields = () => {
    setCompanyName();
    setAddress();
    setSiteVistDate();
    setSpoc();
  };

  const handleSpoc = (id) => {
    setSpoc(id);
  };

  const handleDate = (date, dateString) => {
    setSiteVistDate(dateString);
  };

  const addSiteVisitHandler = () => {
    if (companyName && address && siteVisitDate && spoc) {
      const formData = new FormData();

      formData.append("company_name", companyName);
      formData.append("location", address);
      formData.append("site_visit_date", siteVisitDate);
      formData.append("spoc_id", spoc);
      Promise.resolve(dispatch(addSiteVisit(formData))).then((res) => {
        if (res) {
          const siteVisitId = res?.data?.id;
          history.push({
            pathname: "/sitetabs",
            siteVisitId: siteVisitId,
          });
          setVisible(false);
        }
      });
    } else {
      message.warning("Please fill all the fields!");
    }
  };

 
  const editSiteVisitHandler = () => {
    if (companyName && address && siteVisitDate && spoc && editSiteVisitId) {
      const formData = new FormData();
      formData.append("site_visit_id", editSiteVisitId);
      formData.append("company_name", companyName);
      formData.append("location", address);
      formData.append("site_visit_date", siteVisitDate);
      formData.append("spoc_id", spoc);
      Promise.resolve(dispatch(updateSiteVisit(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisit());
          setVisible(false);
        }
      });
    } else {
      message.warning("Please fill all the fields!");
    }
  };

  return (
    <Modal
      visible={visible}
      className="site-visit-modal"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      destroyOnClose
      footer={null}
    >
      <h1>Start new Site Visit</h1>
      <Row>
        <Input
          bordered={false}
          placeholder="Company Name*"
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          className="input-row"
        />
      </Row>
      <Row>
        <div className="location-row">
          <Input
            bordered={false}
            placeholder="Address"
            value={address}
            style={{ width: "100%" }}
            className="purchased-input"
            onChange={(e) => setAddress(e.target.value)}
            suffix={
              <img
                src={require("../../assets/logos/ic-contact-map-pin.svg")}
                alt="image"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
              />
            }
          />
        </div>
      </Row>
      <Row>
        <Select
          style={{ width: "100%" }}
          placeholder="Select Site Survey SPOC"
          value={
            spoc
              ? getCompanySpocsReducer.success.data?.spocs?.filter(
                  (i) => i.id === spoc
                )[0]?.name
              : null
          }
          onChange={handleSpoc}
        >
          {getCompanySpocsReducer.success.data?.spocs.map((i, index) => {
            return (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            );
          })}
        </Select>
      </Row>
      <Row>
        <DatePicker
          placeholder="Date of Survey"
          format="DD/MM/YYYY"
          value={siteVisitDate ? moment(siteVisitDate, "DD/MM/YYYY") : null}
          onChange={handleDate}
          className="date-row"
        />
      </Row>

      <div className="site-visit-create-btn-container">
        <Button
          className="site-visit-create-btn"
          onClick={() =>
            !editSiteVisitId ? addSiteVisitHandler() : editSiteVisitHandler()
          }
        >
          {!_.isEmpty(editSiteVisitData) ? "SAVE" : "CREATE"}
        </Button>
      </div>
    </Modal>
  );
};

export default withRouter(CreateSiteVisitModal);
