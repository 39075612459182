import React from 'react';
import './search.style.less';

const Search = (props) => {
    return (
        <div className="search-wrapper">
            <div className="search-input">
                <div className="search-icon" onClick={() => document.querySelector("#searchInput").focus()}>
                    <img src={require('../../assets/logos/Search.png')} alt="search-logo" />
                </div>
                <input type="search" onMouseDown={props.onMouseDown} onChange={props.onChange} placeholder={props.placeholder || "Search"} value={props.searchElement} />
            </div>
        </div>
    )
};

export default Search;