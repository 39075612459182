import {
  Col,
  message,
  Row,
  Button,
  Checkbox,
  Radio,
  Space,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSiteSummary,
  getSiteVisitDetails,
} from "../../actions/get-site-visit-action";
import CardPercentage from "./CardPercentage";
import FloatInput from "./FloatInput";
import "./client-details.style.less";
import SiteVistContext from "./SiteVisitContext";
import GetLocation from "./GetLocation";
import { statesAndUTsList } from "../constants";

const { Option } = Select;

function ClientDetails({ siteVisitId }) {
  // Company Details
  const [companyName, setCompanyName] = useState();
  const [industryType, setIndustryType] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [gpsCoordinates, setGpsCoordinates] = useState();
  const [showGpsLocation, setShowGpsLocation] = useState(false);

  // Contact Details
  const [contactName, setContactName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [designation, setDesignation] = useState();
  const [emailId, setEmailId] = useState();

  // Project Details
  const [projectTypes, setProjectTypes] = useState([]);
  const [installationTypes, setInstallationTypes] = useState([]);
  const [projectFeasibility, setProjectFeasibility] = useState(null);
  const [capacity, setCapacity] = useState();

  const dispatch = useDispatch();
  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });
  const siteVisitContext = useContext(SiteVistContext);
  const {
    siteRef,
    tabsList,
    editTabsHandler,
    saveLaterText,
    setSaveLaterText,
    handleActiveTabHandler,
    activeTab,
  } = siteVisitContext;

  useEffect(() => {
    if (getSiteVisitDetailsReducer?.success.data) {
      let data = getSiteVisitDetailsReducer?.success.data;

      setCompanyName(data?.company_name);
      setIndustryType(data?.industry_type);
      setCity(data?.city);
      setState(data?.state);
      setContactName(data?.contact_person_name);
      setPhoneNumber(data?.contact_person_name_phone);
      setDesignation(data?.designation);
      setEmailId(data?.email);
      setGpsCoordinates(data?.gps_coordinates);
      setProjectTypes(data?.project_types);
      setInstallationTypes(data?.installation_types);
      setProjectFeasibility(data?.project_feasibility);
      setCapacity(data?.capacity);
    }
  }, [getSiteVisitDetailsReducer?.success.data]);

  useEffect(() => {
    if (saveLaterText === "Client Details") {
      handleSiteSummary();
    }
  }, [saveLaterText]);
  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);

  useEffect(() => {
    let keys = [
      companyName,
      industryType,
      city,
      state,
      gpsCoordinates,
      contactName,
      phoneNumber,
      designation,
      emailId,
      projectTypes,
      installationTypes,
      projectFeasibility,
      capacity,
    ];

    let count = keys?.reduce((acc, val) => {
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    let temp = tabsList;
    temp[0].progress = Math.round((count / keys?.length) * 100);
    editTabsHandler(temp);
  }, [
    companyName,
    industryType,
    city,
    state,
    gpsCoordinates,
    contactName,
    phoneNumber,
    designation,
    emailId,
    projectTypes,
    installationTypes,
    projectFeasibility,
    capacity,
  ]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSiteSummary = () => {
    if (
      siteVisitId &&
      industryType &&
      city &&
      state &&
      gpsCoordinates &&
      contactName &&
      phoneNumber &&
      projectTypes?.length > 0 &&
      installationTypes?.length > 0 &&
      typeof projectFeasibility === "boolean" &&
      capacity
    ) {
      const formData = new FormData();

      formData.append("site_visit_id", siteVisitId);
      formData.append("industry_type", industryType);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("gps_coordinates", JSON.stringify(gpsCoordinates));
      formData.append("contact_person_name", contactName);
      formData.append("contact_person_name_phone", phoneNumber);
      if (designation) {
        formData.append("designation", designation);
      }
      if (emailId) {
        if (validateEmail(emailId)) {
          formData.append("email", emailId);
        } else {
          message.error("Please Enter Valid Email address");
          setSaveLaterText();
        }
      }
      formData.append("project_types", JSON.stringify(projectTypes));
      formData.append("installation_types", JSON.stringify(installationTypes));
      formData.append("project_feasibility", projectFeasibility);
      formData.append("capacity", capacity);

      Promise.resolve(dispatch(addSiteSummary(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the fields");
      setSaveLaterText();
    }
  };
  const onFocusHandler = (e) => {
    setShowGpsLocation(true);
  };
  return (
    <div className="client-details-container">
      <h1> Company Details</h1>

      <div
        className="card-container"
        ref={(element) => {
          siteRef.current[0] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              status={50}
              cardName="Company Details"
              items={[companyName, industryType, gpsCoordinates, city, state]}
            />
            <FloatInput
              name="companyName"
              bordered={false}
              placeholder="Name of the Company*"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
              label="Name of the Company"
              className="input-row"
              required={true}
              card="Company Details"
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              placeholder="Type of Industry*"
              label="Type of Industry"
              onChange={(e) => setIndustryType(e.target.value)}
              value={industryType}
              className="input-row"
              required={true}
              card="Company Details"
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <GetLocation
              value={gpsCoordinates}
              state={true}
              setValue={setGpsCoordinates}
              isShowMap={showGpsLocation}
              setIsShowMap={setShowGpsLocation}
            />
            <FloatInput
              bordered={false}
              placeholder="Site GPS Coordinates*"
              onFocus={(e) => onFocusHandler(e)}
              onChange={(e) => setGpsCoordinates(e.target.value)}
              value={
                gpsCoordinates?.lat
                  ? gpsCoordinates?.lat + "," + gpsCoordinates?.lng
                  : ""
              }
              className="input-row"
              required={true}
              label="Site GPS Coordinates"
              card="Company Details"
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              placeholder="City*"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              className="input-row"
              required={true}
              label="City"
              card="Company Details"
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <Select
              showSearch
              allowClear
              showArrow
              className="select"
              placeholder="State*"
              name="state"
              onSelect={(value) => setState(value)}
              card="Company Details"
              value={state}
            >
              {statesAndUTsList.map((i) => {
                return (
                  <Option value={i} key={i}>
                    {i}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </div>
      <h1 style={{ marginTop: "2%" }}>Contact Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          siteRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              status={50}
              cardName="Contact Details"
              items={[contactName, phoneNumber, designation, emailId]}
            />
            <FloatInput
              bordered={false}
              placeholder="Contact Person Name*"
              onChange={(e) => setContactName(e.target.value)}
              value={contactName}
              className="input-row"
              required={true}
              label="Contact Person Name"
              card="Contact Details"
            />
          </Col>
        </Row>

        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              placeholder="Designation"
              onChange={(e) => setDesignation(e.target.value)}
              value={designation}
              className="input-row"
              label="Designation"
              card="Contact Details"
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              type="number"
              placeholder="Contact Person Phone Number*"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              className="input-row"
              required={true}
              label="Contact Person Phone Number"
              card="Contact Details"
            />
          </Col>
        </Row>

        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              type="email"
              placeholder="Email Id"
              onChange={(e) => setEmailId(e.target.value)}
              value={emailId}
              className="input-row"
              label="Email Id"
              card="Contact Details"
            />
          </Col>
        </Row>
      </div>
      <h1 style={{ marginTop: "2%" }}>Project Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          siteRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              status={50}
              cardName="Project Details"
              items={[
                projectTypes,
                installationTypes,
                projectFeasibility,
                capacity,
              ]}
            />
            <h1 className="inner-text">Project Type</h1>
            <Checkbox.Group
              onChange={(values) => setProjectTypes(values)}
              value={projectTypes}
              card="Project Details"
            >
              <div className="checkbox-group-border">
                <Checkbox value="CAPEX">CAPEX</Checkbox>
                <Checkbox value="OPEX">OPEX</Checkbox>
                <Checkbox value="Open Access - Captive">
                  Open Access - Captive
                </Checkbox>
                <Checkbox value="Open Access - Group Captive">
                  Open Access - Group Captive
                </Checkbox>
              </div>
            </Checkbox.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Installation Type</h1>
            <Checkbox.Group
              onChange={(values) => setInstallationTypes(values)}
              value={installationTypes}
              card="Project Details"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Rooftop"> Rooftop</Checkbox>
                <Checkbox value="Ground Mount">Ground Mount</Checkbox>
                <Checkbox value="Floating Solar">Floating Solar</Checkbox>
              </div>
            </Checkbox.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Feasibility of the Project</h1>
            <Radio.Group
              name="projectFeasibility"
              onChange={(e) => setProjectFeasibility(e.target.value)}
              value={projectFeasibility}
              card="Project Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              bordered={false}
              type="number"
              placeholder="Client Required Capacity*"
              onChange={(e) => setCapacity(e.target.value)}
              value={capacity}
              className="input-row"
              required={true}
              label="Client Required Capacity"
              card="Project Details"
            />
          </Col>
        </Row>
      </div>
      <div className="btn-container">
        <Button onClick={() => handleSiteSummary()}>Save</Button>
      </div>
    </div>
  );
}

export default ClientDetails;
