import React from "react";
import { Select, Tag, Tooltip } from "antd";
import { materialStatusList } from "./constants";
import moment from "moment";

export const getStatusTag = (status) => {
    switch (status) {
        case "FINISHED":
            return <Tag color="#00b680">FINISHED</Tag>;
        case "IN-PROGRESS":
            return <Tag color="#f1a73d">IN-PROGRESS</Tag>;
        case "PENDING":
            return <Tag color="#9ec3f8">PENDING</Tag>;
        default:
            return null;
    }
};

/**
 * Returns the file name including file extension from a URL
 * @param {String} urlString The complete URL string
 * @returns {String} File name with file extension
 */
export const getFileNameFromURL = (urlString) => {
    return urlString?.toString()?.split("/")?.pop();
};

/**
 * Truncates the string according to the provided length.
 * Wraps the string with an Antd Tooltip component if the length of the string exceeds the required length.
 * @param {String} text The string to perform the truncation on.
 * @param {number} length The total required length of the string after truncation.
 * @return {String} The truncated string wrapped with an Antd Tooltip component
 * OR
 * The string without any truncation if the length of the string is less than the required length.
 */
export const truncateText = (text, length) => {
    if (text !== undefined && text !== null && text.length > length) {
        return (
            <Tooltip title={text}>
                {text.trim().slice(0, length) + "... "}
            </Tooltip>
        );
    } else {
        return text;
    }
};

/**
 * Returns a list of Antd Select Options based on the Material Status List constant
 */
export const getMaterialStatusOptions = () => {
    return materialStatusList.map((status) => (
        <Select.Option value={status}>{status}</Select.Option>
    ));
};

/**
 * Checks whether the Main Company Nav Bar is disabled or not
 * @returns {Boolean} true if disabled, else false
 */
export const isMainHeaderDisabled = () => {
  const mainHeader = document.querySelector(".main-header-wrapper");

  return mainHeader && mainHeader.style.display === "none";
};

/**
 * Validates an email address using regex
 * Returns true if email is valid, else false

 * @param {string} email - Pathname of the current page in question
 * @returns {Boolean}
 */
export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

/**
 * Returns the location suffix icon
 * @returns {JSX}
 */
export const LocationSuffixIcon = () => {
    return (
        <img
            src={require("../assets/logos/ic-contact-map-pin.svg")}
            alt="map"
            className="location-suffix-icon"
        />
    );
};

/**
 * Scrolls to a child element within a parent element
 * @param {String} parentClassName The className of the parent element
 * @param {String} elementClassName  The className of the child element
 */
export const scrollToElementWithinParent = (parentClassName, elementClassName) => {
    const myElement = document.querySelector(`.${elementClassName}`);
    const parentElement = document.querySelector(`.${parentClassName}`);

    const topPos = myElement.offsetTop;

    return (
        parentElement &&
        myElement &&
        parentElement.scrollTo({
            top: topPos,
            behavior: "smooth",
        })
    );
};

/**
 * Returns the base URL for the respective platform.
 * @param {String} toPlatform
 * The name of the platform.
 * 
 * Accepts only three values: "auction" for Project Marketplace, "pmt" for Solarfow and "ecom" for Component Marketplace.
 * @returns {String}
 */
export const getBaseUrl = (toPlatform) => {
    const AUCTION_BASE_URL = process.env.REACT_APP_AUCTION_BASE_URL;
    const SOLARFLOW_BASE_URL = process.env.REACT_APP_BASE_URL;
    const ECOM_BASE_URL = process.env.REACT_APP_ECOM_BASE_URL;

    return toPlatform === "pmt" ? SOLARFLOW_BASE_URL : toPlatform === "ecom" ? ECOM_BASE_URL : AUCTION_BASE_URL;
};

/**
 * Checks whether the given date is within the date range
 * 
 * Start date and end date are inclusive
 * 
 * @param {String} date The date to check
 * @param {String} startDate The start date of the range
 * @param {String} endDate  The end date of the range
 * @returns {Boolean} true if the given date is within the range, else false
 */
export const isDateWithinRange = (date, startDate, endDate) => {
    const when = moment(date, "DD-MM-YYYY");
    const start = moment(startDate, "DD-MM-YYYY");
    const end = moment(endDate, "DD-MM-YYYY");

    if (when.isSame(start) || when.isSame(end)) {
        return true;
    }

    return when.isBetween(start, end);
};
