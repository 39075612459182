import { message } from "antd";
import { getItem, setItem } from "../../SecureStorage";
import {
  GET_SITE_VISIT_FAILURE,
  GET_SITE_VISIT_LOADING,
  GET_SITE_VISIT_SUCCESS,
  GET_SITE_VISIT_DETAILS_FAILURE,
  GET_SITE_VISIT_DETAILS_LOADING,
  GET_SITE_VISIT_DETAILS_SUCCESS,
  GET_COMPANY_SPOC_FAILURE,
  GET_COMPANY_SPOC_LOADING,
  GET_COMPANY_SPOC_SUCCESS,
  GET_SITE_VISIT_FORM_DETAILS_LOADING,
  GET_SITE_VISIT_FORM_DETAILS_SUCCESS,
  GET_SITE_VISIT_FORM_DETAILS_FAILURE,
} from "../types";
import axios from "axios";
import * as Actions from "../types";

export const getSiteVisit = (data) => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  dispatch({ type: GET_SITE_VISIT_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_all_site_visit/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        site_visit_id: data,
      },
    })
    .then((result) => {
      dispatch({ type: GET_SITE_VISIT_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SITE_VISIT_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const getSiteVisitDetails = (data) => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  dispatch({ type: GET_SITE_VISIT_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_site_visit/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        site_visit_id: data,
      },
    })
    .then((result) => {
      dispatch({ type: GET_SITE_VISIT_DETAILS_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SITE_VISIT_DETAILS_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const getSiteVisitformDetails = (data, keyType, index) => (dispatch) => {
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }
  dispatch({ type: GET_SITE_VISIT_FORM_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_site_visit_form_details/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        site_visit_id: data,
        [keyType]: index,
      },
    })
    .then((result) => {
      dispatch({
        type: GET_SITE_VISIT_FORM_DETAILS_SUCCESS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SITE_VISIT_FORM_DETAILS_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const getCompanySpocs = (data) => (dispatch) => {
  let userData;
  let projectId;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  dispatch({ type: GET_COMPANY_SPOC_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_company_spocs/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      dispatch({ type: GET_COMPANY_SPOC_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_COMPANY_SPOC_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const addSiteVisit = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_site_visit/`,
      form,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      message.success("Created Successfully");
      const siteVisitId = res?.data?.id;
      setItem("siteVisitId", siteVisitId);
      return res;
    }
  } catch (error) {
    message.error("Something went wrong!");
  }
};

export const updateSiteVisit = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_site_visit/`,
      form,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      message.success("Updated Successfully");
      return res;
    }
  } catch (error) {
    message.error("Something went wrong!");
  }
};

export const deleteSiteVisit = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_site_visit/`,
      form,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      message.success("Deleted Successfully");
      return res;
    }
  } catch (error) {
    message.error("Something went wrong!");
  }
};

export const addSiteSummary = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_site_visit_summary/`,
      form,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      message.success("Summary added Successfully");
      return res;
    }
  } catch (error) {
    message.error("Something went wrong!");
  }
};

export const addElectricalDetails = (form) => async (dispatch) => {
  axios.defaults.headers = {
    "Content-Type": "application/json",
  };
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_site_visit_electrical_details/`,
      form,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );

    if (res?.data?.status === "FAIL") {
      message.error(res?.data?.response);
    } else if (res.status === 200) {
      message.success("Electrical details added Successfully");
      return res;
    }
  } catch (error) {
    message.error("Something went wrong!");
  }
};

export const buildingAreaApi =
  (form, type, siteVisitId, keyType, index) => async (dispatch) => {
    let userData;
    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }
    let url;
    if (type === "add") {
      // add Site visit Building/area
      url = "add_building_and_area";
    } else if (type === "edit") {
      // edit Site Visit Building/area
      url = "update_building_and_area";
    } else if (type === "delete") {
      // delete Site Visit Building/area
      url = "delete_site_visit_building_and_area";
    }

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${url}/`,
        form,
        {
          headers: {
            Authorization: "Token " + userData.authToken,
          },
        }
      );

      if (res?.data?.status === "FAIL") {
        message.error(res?.data?.response);
      }
      dispatch(getSiteVisitformDetails(siteVisitId, keyType, index));
      return res;
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

export const electricalDetailsApi =
  (form, type, siteVisitId) => async (dispatch) => {
    let userData;
    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }
    let url;
    if (type === "add") {
      // add Site visit Electrical Details
      url = "add_site_visit_electrical_details";
    } else if (type === "edit") {
      // edit Site Visit Electrical Details
      url = "update_site_visit_electrical_details";
    } else if (type === "delete") {
      // delete Site Visit Electrical Details
      url = "delete_site_visit_electrical_details";
    }

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${url}/`,
        form,
        {
          headers: {
            Authorization: "Token " + userData.authToken,
          },
        }
      );

      if (res?.data?.status === "FAIL") {
        message.error(res?.data?.response);
      }
      dispatch(getSiteVisitformDetails(siteVisitId));
      return res;
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

export const proposedInverterLocationApi =
  (form, type, siteVisitId) => async (dispatch) => {
    let userData;
    let url;
    if (getItem("userData")) {
      userData = JSON.parse(getItem("userData"));
    }
    if (type === "add") {
      // add Site visit Proposed Location
      url = "add_site_visit_proposed_locations";
    } else if (type === "edit") {
      // edit Site Visit Proposed Inverter
      url = "update_site_visit_proposed_inverter";
    } else if (type === "delete") {
      // delete Site Visit Proposed Inverter
      url = "delete_site_visit_proposed_inverter";
    }

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${url}/`,
        form,
        {
          headers: {
            Authorization: "Token " + userData.authToken,
          },
        }
      );

      if (res?.data?.status === "FAIL") {
        message.error(res?.data?.response);
      }
      dispatch(getSiteVisitDetails(siteVisitId));
      return res;
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

export const addSiteVisitProjectSpecifications = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_site_visit_project_specifications/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    return result;
  } catch (e) {
    return message.error("Error while uploading file!");
  }
};

export const deleteFile = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_site_visit_file/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    return result;
  } catch (e) {
    return message.error("Something went wrong!");
  }
};

export const addBuildingArea = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_building_and_area/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    message.success("Added Successfully");
    return result;
  } catch (e) {
    return message.error("Something went wrong!");
  }
};

export const updateBuildingArea = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}update_building_and_area/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    message.success("Updated Successfully");
    return result;
  } catch (e) {
    return message.error("Something went wrong!");
  }
};

export const generateSiteVisitReport = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({
    type: Actions.GENERATE_SITE_VISIT_REPORT_LOADING,
    payload: data,
  });

  await message.loading("Generating report...", 1);

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}generate_site_visit_report/`,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
        params: {
          site_visit_id: data,
        },
      }
    );

    if (result?.data?.status === "FAIL") {
      throw result.data;
    }

    if (result?.data?.file) {
      window.open(result?.data?.file, "_blank");
    }

    dispatch({
      type: Actions.GENERATE_SITE_VISIT_REPORT_SUCCESS,
      payload: result.data,
    });
    await message.success("Site Visit report generated successfully!", 1);
    return result;
  } catch (e) {
    if (e?.status === "FAIL") {
      message.error(e.response, 2);
    } else {
      message.error("Error occurred while generating site visit report!", 1);
    }
    dispatch({ type: Actions.GENERATE_SITE_VISIT_REPORT_FAILURE, payload: e });
    return e;
  }
};

export const sendDataBankEmail = (data) => async (dispatch) => {
  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}send_databank_email/`,
      data,
      {
        headers: {
          Authorization: "Token " + userData.authToken,
        },
      }
    );
    message.success("Email sent Successfully");
    return result;
  } catch (e) {
    return message.error("Something went wrong!");
  }
};
