import { Col, Row, Radio, Space, Button, Checkbox, message } from "antd";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSiteVisitDetails,
  updateBuildingArea,
} from "../../actions/get-site-visit-action";
import "./building-area.style.less";
import { carPortObject } from "./buildingAreaConstants";
import BuildingAreaTopCard from "./BuildingAreaTopCard";
import CardPercentage from "./CardPercentage";
import FloatInput from "./FloatInput";
import FloatInputSuffix from "./FloatInputSuffix";
import SiteVistContext from "./SiteVisitContext";
import UploadFile from "./UploadFile";
import { mergeFiles, processFiles, sendFiles } from "../constants";

function CarBusPortArea({ selectedBuildingArea, title, siteVisitId }) {
  const siteVisitContext = useContext(SiteVistContext);
  const {
    handleActiveTabHandler,
    carPortDetails,
    setCarPortDetails,
    carPortRef,
    buildingAreaIndex,
    saveLaterText,
    setSaveLaterText,
    activeTab,
  } = siteVisitContext;

  useEffect(() => {
    setCarPortDetails({ ...carPortDetails, name: title });
  }, [title]);

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  let imageKeys = [
    "area_type_image",
    "soil_type_image",
    "existing_cable_routing_image",
    "is_proposed_ground_area_image",
    "any_shadow_surrounding_area_image",
    "shadow_height_image",
  ];

  useEffect(() => {
    if (
      getSiteVisitDetailsReducer?.success.data?.car_port_details_array.length >
      0
    ) {
      const details =
        getSiteVisitDetailsReducer?.success.data?.car_port_details_array[
          buildingAreaIndex
        ];

      const processedImages = imageKeys.reduce((acc, key) => {
        acc[key] = processFiles(details[key], details, `${key}_ids`);
        return acc;
      }, {});

      let {
        area_type_image,
        soil_type_image,
        existing_cable_routing_image,
        is_proposed_ground_area_image,
        any_shadow_surrounding_area_image,
        shadow_height_image,
        ...existingData
      } = details;

      setCarPortDetails({
        ...existingData,
        ...processedImages,
      });
    }
  }, [getSiteVisitDetailsReducer?.success.data, buildingAreaIndex]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);
  const onChangeHandler = (e) => {
    const value = e.target.value;

    setCarPortDetails({
      ...carPortDetails,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (saveLaterText === "Car/Bus Port") {
      saveHandler();
    }
  }, [saveLaterText]);
  const saveHandler = () => {
    let keys = Object.keys(carPortObject);

    let count = keys?.reduce((acc, v) => {
      let val = carPortDetails[v];
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);

    formData.append("index", buildingAreaIndex);

    const {
      area_type_image,
      soil_type_image,
      existing_cable_routing_image,
      is_proposed_ground_area_image,
      any_shadow_surrounding_area_image,
      shadow_height_image,
      ...existingData
    } = carPortDetails;

    formData.append("car_port_details", JSON.stringify(existingData));
    for (let i = 0; i < imageKeys?.length; i++) {
      sendFiles(carPortDetails[imageKeys[i]]?.files, imageKeys[i], formData);
    }

    if (count == keys.length) {
      Promise.resolve(dispatch(updateBuildingArea(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the fields");
      setSaveLaterText();
    }
  };

  const getAreaDetailsItems = () => {
    let items = [
      carPortDetails?.area_type,
      mergeFiles(carPortDetails?.area_type_image),
    ];
    if (carPortDetails?.area_type === "Soil") {
      items.push(carPortDetails?.soil_type);
      items.push(mergeFiles(carPortDetails?.soil_type_image));
      items.push(carPortDetails?.is_land_levelling_required);
    } else if (carPortDetails?.area_type === "RCC") {
      items.push(carPortDetails?.is_water_proofing_layer);
      if (carPortDetails?.is_water_proofing_layer) {
        items.push(carPortDetails?.water_proofing_layer_type);
      }
    }
    return items;
  };

  const getShadowCausingItems = () => {
    let items = [
      carPortDetails?.is_proposed_ground_area,
      carPortDetails?.any_trees_surrounding_building,
      carPortDetails?.any_shadow_surrounding_area,
    ];
    if (carPortDetails?.is_proposed_ground_area) {
      items.push(mergeFiles(carPortDetails?.is_proposed_ground_area_image));
    }
    if (carPortDetails?.any_trees_surrounding_building) {
      items.push(carPortDetails?.height_of_trees);
    }
    if (carPortDetails?.any_shadow_surrounding_area) {
      items.push(mergeFiles(carPortDetails?.any_shadow_surrounding_area_image));
      items.push(carPortDetails?.shadow_height);
      items.push(mergeFiles(carPortDetails?.shadow_height_image));
    }
    return items;
  };

  return (
    <div className="building-area-container">
      <div
        className="card-container card-container-top"
        ref={(element) => {
          carPortRef.current[0] = element;
        }}
      >
        <BuildingAreaTopCard
          label1="Name"
          label2="Type"
          placeholder1="Name"
          placeholder2="Type"
          name1="name"
          name2="type"
          onChange={onChangeHandler}
          value1={title}
          value2={selectedBuildingArea}
        />
      </div>
      <h1 className="upper-text">Area details</h1>
      <div
        className="card-container"
        ref={(element) => {
          carPortRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Area details"
              items={getAreaDetailsItems()}
            />
            <h1 className="inner-text">Type of area</h1>
            <Radio.Group
              name="area_type"
              onChange={onChangeHandler}
              value={carPortDetails?.area_type}
              card="Area details"
            >
              <Space direction="vertical">
                <Radio value="Soil">Soil</Radio>
                <Radio value="RCC">RCC</Radio>
                <Radio value="Bricks">Bricks</Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={carPortDetails}
              setState={setCarPortDetails}
              objKey="area_type_image"
              fileType="is_car_port_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        {carPortDetails?.area_type === "Soil" && (
          <>
            {" "}
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Type of soil</h1>
                <Radio.Group
                  name="soil_type"
                  onChange={onChangeHandler}
                  value={carPortDetails?.soil_type}
                  className="horizontal-radio"
                  card="Area details"
                >
                  <Space direction="horizontal" wrap={true}>
                    <Radio value="Sandy Soil">
                      <span className="text-image">
                        {" "}
                        a) Sandy Soil
                        <img
                          src={require("../../assets/sitevisit/sandySoil.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="Clay Soil">
                      <span className="text-image">
                        {" "}
                        b) Clay Soil
                        <img
                          src={require("../../assets/sitevisit/claySoil.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="Silt Soil">
                      <span className="text-image">
                        {" "}
                        c) Silt Soil
                        <img
                          src={require("../../assets/sitevisit/siltSoil.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                    <Radio value="Loamy Soil">
                      <span className="text-image">
                        {" "}
                        d) Loamy Soil
                        <img
                          src={require("../../assets/sitevisit/loamySoil.jpg")}
                          alt="image"
                        />
                      </span>
                    </Radio>
                  </Space>
                </Radio.Group>
                <UploadFile
                  state={carPortDetails}
                  setState={setCarPortDetails}
                  objKey="soil_type_image"
                  fileType="is_car_port_image"
                  imageIndex={buildingAreaIndex}
                />
              </Col>
            </Row>
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Land Levelling requirement</h1>
                <Radio.Group
                  name="is_land_levelling_required"
                  onChange={onChangeHandler}
                  value={carPortDetails?.is_land_levelling_required}
                  card="Area details"
                >
                  <Space direction="vertical">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </>
        )}
        {carPortDetails?.area_type === "RCC" && (
          <>
            {" "}
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Water proofing layer</h1>
                <Radio.Group
                  name="is_water_proofing_layer"
                  onChange={onChangeHandler}
                  value={carPortDetails?.is_water_proofing_layer}
                  card="Area details"
                >
                  <Space direction="vertical">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
            {carPortDetails?.is_water_proofing_layer && (
              <Row>
                <Col span={22}>
                  <h1 className="inner-text">Type of water proofing layer</h1>
                  <Radio.Group
                    name="water_proofing_layer_type"
                    onChange={onChangeHandler}
                    value={carPortDetails?.water_proofing_layer_type}
                    className="horizontal-radio"
                    cardName="Area details"
                  >
                    <Space direction="horizontal" wrap={true}>
                      <Radio value="Brick bat coba with Cement layer on top">
                        <span className="text-image">
                          {" "}
                          a) Brick bat coba with Cement layer on top{" "}
                          <img
                            src={require("../../assets/sitevisit/cement.jpg")}
                            alt="image"
                          />
                        </span>
                      </Radio>
                      <Radio value="Stone Tiles">
                        <span className="text-image">
                          {" "}
                          b) Stone Tiles
                          <img
                            src={require("../../assets/sitevisit/stoneTiles.jpg")}
                            alt="image"
                          />
                        </span>
                      </Radio>
                      <Radio value="Brick Mortar with exposed bricks">
                        <span className="text-image">
                          {" "}
                          c) Brick Mortar with exposed bricks
                          <img
                            src={require("../../assets/sitevisit/brickMortar.jpg")}
                            alt="image"
                          />
                        </span>
                      </Radio>
                      <Radio value="China Mosiac">
                        <span className="text-image">
                          {" "}
                          d) China Mosiac
                          <img
                            src={require("../../assets/sitevisit/chinaMosiac.jpg")}
                            alt="image"
                          />
                        </span>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={22}>
                <h1 className="inner-text">Land Levelling requirement</h1>
                <Radio.Group
                  name="is_land_levelling_required"
                  onChange={onChangeHandler}
                  value={carPortDetails?.is_land_levelling_required}
                  card="Area details"
                >
                  <Space direction="vertical">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </>
        )}
      </div>
      <h1 className="upper-text">Existing cable routing upto the location</h1>
      <div
        className="card-container"
        ref={(element) => {
          carPortRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Existing cable routing upto the location"
              items={
                carPortDetails?.is_metal_sheet_required
                  ? [
                      carPortDetails?.is_metal_sheet_required,
                      carPortDetails?.profile_sheet,
                      carPortDetails?.existing_cable_routing,
                      mergeFiles(carPortDetails?.existing_cable_routing_image),
                    ]
                  : [
                      carPortDetails?.is_metal_sheet_required,
                      carPortDetails?.existing_cable_routing,
                      mergeFiles(carPortDetails?.existing_cable_routing_image),
                    ]
              }
            />
            <h1 className="inner-text">
              Existing cable routing upto the location
            </h1>
            <Checkbox.Group
              onChange={(e) =>
                setCarPortDetails({
                  ...carPortDetails,
                  existing_cable_routing: e,
                })
              }
              value={carPortDetails?.existing_cable_routing}
              cardName="Existing cable routing upto the location"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Cable Trenches">Cable Trenches</Checkbox>
                <Checkbox value="Walls">Walls</Checkbox>
                <Checkbox value=" Cable Trays">Cable Trays</Checkbox>
              </div>
            </Checkbox.Group>
            <UploadFile
              state={carPortDetails}
              setState={setCarPortDetails}
              objKey="existing_cable_routing_image"
              fileType="is_car_port_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Requirement of metal sheet</h1>
            <Radio.Group
              name="is_metal_sheet_required"
              onChange={onChangeHandler}
              value={carPortDetails?.is_metal_sheet_required}
              card="Existing cable routing upto the location"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        {carPortDetails?.is_metal_sheet_required && (
          <Row>
            <Col span={22}>
              <h1 className="inner-text">Profile of the sheet required</h1>
              <Radio.Group
                name="profile_sheet"
                onChange={onChangeHandler}
                value={carPortDetails?.profile_sheet}
                card="Existing cable routing upto the location"
              >
                <Space direction="vertical">
                  <Radio value="Trapazoidal">Trapazoidal</Radio>
                  <Radio value="Cliplock">Cliplock</Radio>
                  <Radio value="Standing seam">Standing seam</Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        )}
      </div>
      <h1 className="upper-text">Shadow causing objects nearby</h1>
      <div
        className="card-container"
        ref={(element) => {
          carPortRef.current[3] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Shadow causing objects nearby"
              items={getShadowCausingItems()}
            />
            <h1 className="inner-text">
              Any trees inside or surrounding the proposed ground area.
            </h1>
            <Radio.Group
              name="is_proposed_ground_area"
              onChange={onChangeHandler}
              value={carPortDetails?.is_proposed_ground_area}
              card="Shadow causing objects nearby"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {carPortDetails?.is_proposed_ground_area && (
              <UploadFile
                state={carPortDetails}
                setState={setCarPortDetails}
                objKey="is_proposed_ground_area_image"
                fileType="is_car_port_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <Radio.Group
              name="any_trees_surrounding_building"
              onChange={onChangeHandler}
              value={carPortDetails?.any_trees_surrounding_building}
              card="Shadow causing objects nearby"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        {carPortDetails?.any_trees_surrounding_building && (
          <Row>
            <Col span={22}>
              <h1 className="inner-text">Height of the trees or vegetation</h1>

              <FloatInputSuffix
                type="number"
                label="Height of the trees or vegetation"
                placeholder="Height of the trees or vegetation"
                onChange={onChangeHandler}
                card="Shadow causing objects nearby"
                name="height_of_trees"
                value={carPortDetails?.height_of_trees}
                suffix={"m"}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={22}>
            <h1 className="inner-text">
              Any other Shadow causing objects surrounding the area
            </h1>
            <FloatInput
              label="Enter Something"
              placeholder="Enter Something"
              onChange={onChangeHandler}
              required={true}
              card="Shadow causing objects nearby"
              name="any_shadow_surrounding_area"
              value={carPortDetails?.any_shadow_surrounding_area}
            />
            {carPortDetails?.any_shadow_surrounding_area && (
              <UploadFile
                state={carPortDetails}
                setState={setCarPortDetails}
                objKey="any_shadow_surrounding_area_image"
                fileType="is_car_port_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        {carPortDetails?.any_shadow_surrounding_area && (
          <Row>
            <Col span={22}>
              <h1 className="inner-text">Height of shadow causing object</h1>
              <FloatInputSuffix
                type="number"
                label="Height of shadow causing object"
                placeholder="Height of shadow causing object"
                onChange={onChangeHandler}
                card="Shadow causing objects nearby"
                required={true}
                name="shadow_height"
                value={carPortDetails?.shadow_height}
                suffix={"m"}
              />
              <UploadFile
                state={carPortDetails}
                setState={setCarPortDetails}
                objKey="shadow_height_image"
                fileType="is_car_port_image"
                imageIndex={buildingAreaIndex}
              />
            </Col>
          </Row>
        )}
      </div>
      <h1 className="upper-text">Water availability for O&M</h1>
      <div
        className="card-container"
        ref={(element) => {
          carPortRef.current[4] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Water availability for O&M"
              items={[
                carPortDetails?.is_water_tapping,
                carPortDetails?.water_tds,
              ]}
            />
            <h1 className="inner-text">
              Water tapping point available near the area
            </h1>
            <Radio.Group
              name="is_water_tapping"
              onChange={onChangeHandler}
              value={carPortDetails?.is_water_tapping}
              className="horizontal-radio"
              card="Water availability for O&M"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">TDS of water available</h1>
            <FloatInputSuffix
              type="number"
              label="TDS of water available"
              placeholder="TDS of water available"
              onChange={onChangeHandler}
              card="Water availability for O&M"
              name="water_tds"
              value={carPortDetails?.water_tds}
              suffix={"ppm"}
            />
          </Col>
        </Row>
      </div>

      <div
        className="add-container"
        onClick={() => handleActiveTabHandler(2)}
        style={{ cursor: "pointer" }}
      >
        <p>+Add new Building/Area</p>
      </div>
      <div className="btn-container" onClick={() => saveHandler()}>
        <Button>Save</Button>
      </div>
    </div>
  );
}

export default CarBusPortArea;
