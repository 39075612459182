import {
  Col,
  Row,
  Select,
  Radio,
  Space,
  Button,
  Checkbox,
  message,
} from "antd";
import React, { useState, useContext, useEffect } from "react";
import "./building-area.style.less";
import BuildingAreaTopCard from "./BuildingAreaTopCard";
import FloatInput from "./FloatInput";
import FloatInputSuffix from "./FloatInputSuffix";
import SiteVistContext from "./SiteVisitContext";
import CardPercentage from "./CardPercentage";
import { useDispatch, useSelector } from "react-redux";
import {
  getSiteVisitDetails,
  getSiteVisitformDetails,
  updateBuildingArea,
} from "../../actions/get-site-visit-action";
import { mapDetails, metalSheetObject } from "./buildingAreaConstants";
import UploadFile from "./UploadFile";
import MultipleForms from "./MultipleForms";
import {
  getSiteVisitFormFields,
  mergeFiles,
  processFiles,
  sendFiles,
} from "../constants";
const { Option } = Select;
function MetalSheetBuilding({ selectedBuildingArea, title, siteVisitId }) {
  const siteVisitContext = useContext(SiteVistContext);
  const {
    handleActiveTabHandler,
    metalSheetRef,
    metalSheetDetails,
    setMetalSheetDetails,
    buildingAreaIndex,
    saveLaterText,
    setSaveLaterText,
    activeTab,
  } = siteVisitContext;

  //  LA Details
  const [la, setLa] = useState(0);
  const [laData, setLaData] = useState([]);
  const [laLength, setLaLength] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setMetalSheetDetails({ ...metalSheetDetails, name: title });
  }, [title]);

  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
      dispatch(getSiteVisitformDetails(siteVisitId, "metal_la_index",buildingAreaIndex));
    }
  }, [siteVisitId]);

  const getSiteVisitFormDetailsReducer = useSelector((state) => {
    return state.getSiteVisitFormDetailsReducers.siteVisitFormDetails.get;
  });

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });

  let imageKeys = [
    "building_image",
    "metal_sheet_profile_image",
    "purlins_type_image",
    "building_height_image",
    "roof_access_image",
    "roof_access_type_image",
    "is_skylight_on_rooftop_image",
    "chimney_on_rooftop_image",
    "any_trees_surrounding_building_image",
    "any_shadow_causing_objects_image",
    "is_water_tapping_image",
  ];

  useEffect(() => {
    if (getSiteVisitFormDetailsReducer.success.data) {
      let data = getSiteVisitFormDetailsReducer.success.data;
      setLa(data?.metal_sheet_la_detail_array?.length);
      setLaLength(data?.metal_sheet_la_detail_array?.length);
      const temp1 = mapDetails(data?.metal_sheet_la_detail_array, [
        "id",
        "range",
        "type",
        "file",
      ]);
      setLaData(temp1);
      setMetalSheetDetails({
        ...metalSheetDetails,
        metal_sheet_la_detail_array: temp1,
      });
    }
  }, [getSiteVisitFormDetailsReducer.success.data]);

  useEffect(() => {
    if (
      getSiteVisitDetailsReducer?.success.data?.metal_sheet_details_array
        .length > 0
    ) {
      let data = getSiteVisitDetailsReducer?.success.data;

      const details = data?.metal_sheet_details_array[buildingAreaIndex];

      const processedImages = imageKeys.reduce((acc, key) => {
        acc[key] = processFiles(details[key], details, `${key}_ids`);
        return acc;
      }, {});
      let {
        building_image,
        metal_sheet_profile_image,
        purlins_type_image,
        building_height_image,
        roof_access_image,
        roof_access_type_image,
        is_skylight_on_rooftop_image,
        chimney_on_rooftop_image,
        any_trees_surrounding_building_image,
        any_shadow_causing_objects_image,
        is_water_tapping_image,
        metal_sheet_la_detail_array,
        ...existingData
      } = details;

      setMetalSheetDetails({
        ...existingData,
        ...processedImages,
      });
    }
  }, [getSiteVisitDetailsReducer?.success.data, buildingAreaIndex]);

  const onChangeHandler = (e) => {
    const value = e.target.value;

    setMetalSheetDetails({
      ...metalSheetDetails,
      [e.target.name]: value,
    });
  };

  const onChangeSelectHandler = (value, key) => {
    setMetalSheetDetails({
      ...metalSheetDetails,
      [key]: value,
    });
  };

  const onChangeCheckHandler = (values, key) => {
    setMetalSheetDetails({
      ...metalSheetDetails,
      [key]: values,
    });
  };

  const incrementHandler = (key) => {
    const dataMap = {
      la_details: {
        data: laData,
        setData: setLaData,
        count: la,
        length: laLength,
        setCount: setLa,
        newItem: {
          range: "",
          type: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
    };

    const { data, setData, setCount, newItem, count, length } = dataMap[key];
    if (count > length) {
      message.warning("Please add a new one after saving the first one!");
      return;
    }
    const val = data.length === 0 ? 0 : data[data.length - 1].id;
    const newItemWithId = { ...newItem, id: val + 1 };

    setCount((prevCount) => prevCount + 1);
    setData([...data, newItemWithId]);
  };

  const decrementHandler = (key) => {
    const dataMap = {
      la_details: {
        count: la,
        setCount: setLa,
        data: laData,
        setData: setLaData,
        length: laLength,
      },
    };

    const { count, setCount, data, setData, length } = dataMap[key];

    if (count - 1 < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    if (count <= 1) {
      setData([]);
      setCount(0);
    } else {
      setCount(count - 1);
      setData(data.slice(0, -1));
    }
  };

  const handleValues = (value, key) => {
    const dataMap = {
      la_details: {
        setCount: setLa,
        setData: setLaData,
        data: laData,
        apiKey: "metal_sheet_la_detail_array",
        fields: ["id", "range", "type", "file"],
        length: laLength,
        newItem: {
          range: "",
          type: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
    };

    const { setCount, setData, data, newItem, length, apiKey, fields } =
      dataMap[key];

    if (value < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    const arr = mapDetails(
      getSiteVisitDetailsReducer.success.data[apiKey],
      fields
    );

    for (let i = arr.length === 0 ? 0 : data.length; i < value; i++) {
      arr.push({
        id: i + 1,
        ...newItem,
      });
    }

    setCount(value);
    setData(arr);
  };

  useEffect(() => {
    if (saveLaterText === "Metal Sheet") {
      saveHandler();
    }
  }, [saveLaterText]);

  const saveHandler = () => {
    let keys = Object.keys(metalSheetObject);
    let count = keys?.reduce((acc, v) => {
      let val = metalSheetDetails[v];
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val && val.hasOwnProperty("showFile")) {
        if (val?.existing_files?.length > 0 || val?.files?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);
    formData.append("index", buildingAreaIndex);

    const {
      building_image,
      metal_sheet_profile_image,
      purlins_type_image,
      building_height_image,
      roof_access_image,
      roof_access_type_image,
      is_skylight_on_rooftop_image,
      chimney_on_rooftop_image,
      any_trees_surrounding_building_image,
      any_shadow_causing_objects_image,
      is_water_tapping_image,
      metal_sheet_la_detail_array,
      ...existingData
    } = metalSheetDetails;

    formData.append("metal_sheet_details", JSON.stringify(existingData));
    for (let i = 0; i < imageKeys?.length; i++) {
      sendFiles(metalSheetDetails[imageKeys[i]]?.files, imageKeys[i], formData);
    }

    if (count == keys.length) {
      Promise.resolve(dispatch(updateBuildingArea(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the fields");
      setSaveLaterText();
    }
  };

  const getRoofDetailsItems = () => {
    return [
      metalSheetDetails?.metal_sheet_profile,
      metalSheetDetails?.purlins_type,
      metalSheetDetails?.building_height,
      metalSheetDetails?.roof_inclination,
      metalSheetDetails?.roof_orientation,
      metalSheetDetails?.roof_structure_condition,
      metalSheetDetails?.roof_remarks,
      metalSheetDetails?.roof_insurance,
      metalSheetDetails?.is_drainage_existing,
      mergeFiles(metalSheetDetails?.metal_sheet_profile_image),
      mergeFiles(metalSheetDetails?.purlins_type_image),
      mergeFiles(metalSheetDetails?.building_height_image),
    ];
  };
  const getRoofObstructionItems = () => {
    let arr = [
      metalSheetDetails?.is_skylight_on_rooftop,
      metalSheetDetails?.is_chimney_on_rooftop,
      metalSheetDetails?.any_trees_surrounding_building,
      metalSheetDetails?.chimney_height,
      metalSheetDetails?.any_shadow_causing_objects,
    ];

    if (metalSheetDetails?.is_skylight_on_rooftop) {
      arr.push(mergeFiles(metalSheetDetails?.is_skylight_on_rooftop_image));
    }
    if (metalSheetDetails?.is_chimney_on_rooftop) {
      arr.push(mergeFiles(metalSheetDetails?.chimney_on_rooftop_image));
    }
    if (metalSheetDetails?.any_trees_surrounding_building) {
      arr.push(
        mergeFiles(metalSheetDetails?.any_trees_surrounding_building_image)
      );
      arr.push(metalSheetDetails?.tree_height);
    }
    if (metalSheetDetails?.any_shadow_causing_objects) {
      arr.push(mergeFiles(metalSheetDetails?.any_shadow_causing_objects_image));
    }
    return arr;
  };

  return (
    <div className="building-area-container">
      <div
        className="card-container card-container-top"
        ref={(element) => {
          metalSheetRef.current[0] = element;
        }}
      >
        <BuildingAreaTopCard
          label1="Name"
          label2="Type"
          placeholder1="Name"
          placeholder2="Type"
          name1="name"
          name2="type"
          onChange={onChangeHandler}
          value1={title}
          value2={selectedBuildingArea}
        />
      </div>
      <h1 className="upper-text">Basic Building Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Basic Building Details"
              items={[
                metalSheetDetails?.shed_type,
                metalSheetDetails?.sheet_age,
                metalSheetDetails?.sheet_thickness,
                metalSheetDetails?.shed_age,
                mergeFiles(metalSheetDetails?.building_image),
              ]}
            />
            <Select
              allowClear
              showArrow
              card="Basic Building Details"
              className="select"
              placeholder="Type of shed"
              name="shed_type"
              onSelect={(value) => onChangeSelectHandler(value, "shed_type")}
              value={metalSheetDetails?.shed_type}
            >
              <Option value="PEB">PEB</Option>
              <Option value="North Light">North Light</Option>
              <Option value="RCC Building with Metal sheet">
                RCC Building with Metal sheet
              </Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              label="Roof Sheet Age"
              placeholder="Roof Sheet Age"
              name="sheet_age"
              card="Basic Building Details"
              onChange={onChangeHandler}
              value={metalSheetDetails?.sheet_age}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              label="Roof Sheet Thickness"
              placeholder="Roof Sheet Thickness"
              name="sheet_thickness"
              card="Basic Building Details"
              onChange={onChangeHandler}
              value={metalSheetDetails?.sheet_thickness}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              label="Building/Shed Age(in years)"
              placeholder="Building/Shed Age(in years)"
              name="shed_age"
              card="Basic Building Details"
              onChange={onChangeHandler}
              value={metalSheetDetails?.shed_age}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Upload Building Images</h1>
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="building_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Roof Details</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Roof Details"
              items={getRoofDetailsItems()}
            />
            <h1 className="inner-text">Profile of metal sheet</h1>
            <Radio.Group
              name="metal_sheet_profile"
              onChange={onChangeHandler}
              card="Roof Details"
              value={metalSheetDetails?.metal_sheet_profile}
              className="horizontal-radio"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value="Trapezoidal">
                  <span className="text-image">
                    {" "}
                    a) Trapezoidal{" "}
                    <img
                      src={require("../../assets/sitevisit/trapezoidal.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Standing Seam">
                  <span className="text-image">
                    {" "}
                    b) Standing Seam
                    <img
                      src={require("../../assets/sitevisit/standingSeam.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Klip Lok">
                  <span className="text-image">
                    {" "}
                    c) Klip Lok{" "}
                    <img
                      src={require("../../assets/sitevisit/klipLok.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="metal_sheet_profile_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Type of purlin</h1>
            <Radio.Group
              name="purlins_type"
              onChange={onChangeHandler}
              value={metalSheetDetails?.purlins_type}
              className="horizontal-radio"
              card="Roof Details"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value="Z Section">
                  <span className="text-image">
                    {" "}
                    a) Z Section{" "}
                    <img
                      src={require("../../assets/sitevisit/zsection.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="L Angle">
                  <span className="text-image">
                    {" "}
                    b) L Angle
                    <img
                      src={require("../../assets/sitevisit/langle.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="C Section">
                  <span className="text-image">
                    {" "}
                    c) C Section
                    <img
                      src={require("../../assets/sitevisit/csection.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Tubes">
                  <span className="text-image">
                    {" "}
                    c) Tubes(rectangular/circular/square)
                    <img
                      src={require("../../assets/sitevisit/tubes.png")}
                      alt="image"
                    />
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="purlins_type_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              label="Height of Shed/Building"
              placeholder="Height of Shed/Building"
              name="building_height"
              card="Roof Details"
              onChange={onChangeHandler}
              value={metalSheetDetails?.building_height}
              type="number"
              suffix={"m"}
            />
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="building_height_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              card="Roof Details"
              label="Roof inclination ( tilt in deg)"
              placeholder="Roof inclination ( tilt in deg)"
              name="roof_inclination"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_inclination}
              type="number"
              suffix={"deg"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              card="Roof Details"
              label="Roof orientation (e.g. North, Northeast, East, etc.) ( in deg)"
              placeholder="Roof orientation (e.g. North, Northeast, East, etc.) ( in deg)"
              name="roof_orientation"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_orientation}
              suffix={"deg"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Roof and structure condition</h1>
            <Radio.Group
              name="roof_structure_condition"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_structure_condition}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value="Poor">Poor</Radio>
                <Radio value="Fair">Fair</Radio>
                <Radio value="Excellent">Excellent</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              card="Roof Details"
              label="Specific remarks about the roof and structure stability"
              placeholder="Specific remarks about the roof and structure stability"
              name="roof_remarks"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_remarks}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Roof insurance</h1>
            <Radio.Group
              name="roof_insurance"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_insurance}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Existing drainage on rooftop</h1>
            <Radio.Group
              name="is_drainage_existing"
              onChange={onChangeHandler}
              value={metalSheetDetails?.is_drainage_existing}
              card="Roof Details"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>

      <h1 className="upper-text">Access</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[3] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Access"
              items={[
                metalSheetDetails?.roof_access,
                metalSheetDetails?.roof_access_type,
                mergeFiles(metalSheetDetails?.roof_access_image),
                mergeFiles(metalSheetDetails?.roof_access_type_image),
              ]}
            />
            <h1 className="inner-text">Roof Access</h1>
            <Radio.Group
              name="roof_access"
              onChange={onChangeHandler}
              value={metalSheetDetails?.roof_access}
              card="Access"
              placeholder="Roof Access"
            >
              <Space direction="vertical">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Indirect roof access from RCC">
                  Indirect roof access from RCC
                </Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="roof_access_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Roof Access Type</h1>
            <Checkbox.Group
              onChange={(e) => onChangeCheckHandler(e, "roof_access_type")}
              value={metalSheetDetails?.roof_access_type}
              card="Access"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Monkey Cage Ladder">
                  Monkey Cage Ladder
                </Checkbox>
                <Checkbox value="Step ladder">Step ladder</Checkbox>
                <Checkbox value="Indirect roof access from RCC">
                  Indirect roof access from RCC
                </Checkbox>
              </div>
            </Checkbox.Group>
            <UploadFile
              state={metalSheetDetails}
              setState={setMetalSheetDetails}
              objKey="roof_access_type_image"
              fileType="is_metal_sheet_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Obstructions on the roof top</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[4] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Obstructions on the roof top"
              items={getRoofObstructionItems()}
            />
            <h1 className="inner-text">Skylights / turbo on roof top</h1>
            <Radio.Group
              name="is_skylight_on_rooftop"
              onChange={onChangeHandler}
              value={metalSheetDetails?.is_skylight_on_rooftop}
              card="Obstructions on the roof top"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {metalSheetDetails?.is_skylight_on_rooftop && (
              <UploadFile
                state={metalSheetDetails}
                setState={setMetalSheetDetails}
                objKey="is_skylight_on_rooftop_image"
                fileType="is_metal_sheet_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Chimney on the roof or nearby</h1>
            <Radio.Group
              name="is_chimney_on_rooftop"
              onChange={onChangeHandler}
              value={metalSheetDetails?.is_chimney_on_rooftop}
              card="Obstructions on the roof top"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {metalSheetDetails?.is_chimney_on_rooftop && (
              <UploadFile
                state={metalSheetDetails}
                setState={setMetalSheetDetails}
                objKey="chimney_on_rooftop_image"
                fileType="is_metal_sheet_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              label="Height of Chimney"
              placeholder="Height of Chimney"
              name="chimney_height"
              card="Obstructions on the roof top"
              onChange={onChangeHandler}
              value={metalSheetDetails?.chimney_height}
              type="number"
              suffix={"m"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Any trees surrounding the building</h1>
            <Radio.Group
              name="any_trees_surrounding_building"
              onChange={onChangeHandler}
              value={metalSheetDetails?.any_trees_surrounding_building}
              card="Obstructions on the roof top"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {metalSheetDetails?.any_trees_surrounding_building && (
              <UploadFile
                state={metalSheetDetails}
                setState={setMetalSheetDetails}
                objKey="any_trees_surrounding_building_image"
                fileType="is_metal_sheet_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        {metalSheetDetails?.any_trees_surrounding_building === true && (
          <Row>
            <Col span={22}>
              <FloatInputSuffix
                type="number"
                label="Height of the tree from rooftop"
                placeholder="Height of the tree from rooftop"
                onChange={onChangeHandler}
                card="Obstructions on the roof top"
                name="tree_height"
                value={metalSheetDetails?.tree_height}
                suffix={"m"}
              />{" "}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={22}>
            <h1 className="inner-text">
              Any other Shadow causing obejcts on roof and surrounding to the
              roof/building.
            </h1>
            <FloatInput
              required={true}
              label="Enter value"
              placeholder="Enter value"
              name="any_shadow_causing_objects"
              onChange={onChangeHandler}
              card="Obstructions on the roof top"
              value={metalSheetDetails?.any_shadow_causing_objects}
            />
            {metalSheetDetails?.any_shadow_causing_objects && (
              <UploadFile
                state={metalSheetDetails}
                setState={setMetalSheetDetails}
                objKey="any_shadow_causing_objects_image"
                fileType="is_metal_sheet_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Dust,Heat and Smoke impact on rooftop</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[5] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Dust,Heat and Smoke impact on rooftop"
              items={[
                metalSheetDetails?.dust_impact,
                metalSheetDetails?.heat_impact,
                metalSheetDetails?.smoke_impact,
                metalSheetDetails?.chemical_type,
              ]}
            />
            <h1 className="inner-text">Dust Impact on Rooftop</h1>
            <Radio.Group
              name="dust_impact"
              onChange={onChangeHandler}
              value={metalSheetDetails?.dust_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Smoke Impact on Rooftop</h1>
            <Radio.Group
              name="smoke_impact"
              onChange={onChangeHandler}
              value={metalSheetDetails?.smoke_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Heat Impact on Rooftop</h1>
            <Radio.Group
              name="heat_impact"
              onChange={onChangeHandler}
              value={metalSheetDetails?.heat_impact}
              card="Dust,Heat and Smoke impact on rooftop"
            >
              <Space direction="vertical">
                <Radio value="Low">Low</Radio>
                <Radio value="Moderate">Moderate</Radio>
                <Radio value="High">High</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Type of chemical in the smoke</h1>
            <FloatInput
              required={true}
              label="Type of chemical in the smoke"
              placeholder="Type of chemical in the smoke"
              name="chemical_type"
              onChange={onChangeHandler}
              card="Dust,Heat and Smoke impact on rooftop"
              value={metalSheetDetails?.chemical_type}
            />
          </Col>
        </Row>
      </div>

      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[6] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="LA Details"
              items={
                metalSheetDetails?.existing_la
                  ? [
                      la,
                      metalSheetDetails?.metal_sheet_la_detail_array,
                      metalSheetDetails?.existing_la,
                    ]
                  : [metalSheetDetails?.existing_la]
              }
              title="LA Details"
            />
            <h1 className="inner-text">Existing LA s on the rooftop</h1>
            <Radio.Group
              name="existing_la"
              onChange={onChangeHandler}
              value={metalSheetDetails?.existing_la}
              className="horizontal-radio"
              cardName="LA Details"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInputSuffix
              label="No of Las"
              placeholder="No of Las*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "la_details")
              }
              value={la}
              objKey="la_details"
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="La Details"
            />
            {laData?.map((i, index) => {
              return (
                <MultipleForms
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={laData}
                  setItems={setLaData}
                  fields={getSiteVisitFormFields("LA")}
                  title="LA"
                  type="metal_sheet_la_details"
                  imageType="is_metal_sheet_la_image"
                  updateApiKey="la_image"
                  typeOptions={["Conventional", "ESE"]}
                  siteVisitId={siteVisitId}
                  laIndex={buildingAreaIndex}
                  isBuildingAndArea
                  length={laLength}
                  emptyFeilds={{
                    range: "",
                    type: "",
                    file: { showFile: false, existing_files: [], files: [] },
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Water availability for O&M</h1>
      <div
        className="card-container"
        ref={(element) => {
          metalSheetRef.current[7] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Water availability for O&M"
              items={
                metalSheetDetails?.is_water_tapping
                  ? [
                      metalSheetDetails?.is_water_tapping,
                      metalSheetDetails?.water_tds,
                      mergeFiles(metalSheetDetails?.is_water_tapping_image),
                    ]
                  : [
                      metalSheetDetails?.is_water_tapping,
                      metalSheetDetails?.water_tds,
                    ]
              }
            />
            <h1 className="inner-text">Water tapping availability near roof</h1>
            <Radio.Group
              name="is_water_tapping"
              onChange={onChangeHandler}
              value={metalSheetDetails?.is_water_tapping}
              className="horizontal-radio"
              card="Water availability for O&M"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            {metalSheetDetails?.is_water_tapping && (
              <UploadFile
                state={metalSheetDetails}
                setState={setMetalSheetDetails}
                objKey="is_water_tapping_image"
                fileType="is_metal_sheet_image"
                imageIndex={buildingAreaIndex}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">TDS of water available</h1>
            <FloatInputSuffix
              type="number"
              label="TDS of water available"
              placeholder="TDS of water available"
              onChange={onChangeHandler}
              card="Water availability for O&M"
              name="water_tds"
              value={metalSheetDetails?.water_tds}
              suffix={"ppm"}
            />
          </Col>
        </Row>
      </div>

      <div
        className="add-container"
        onClick={() => handleActiveTabHandler(2)}
        style={{ cursor: "pointer" }}
      >
        <p>+Add new Building/Area</p>
      </div>
      <div className="btn-container" onClick={() => saveHandler()}>
        <Button>Save</Button>
      </div>
    </div>
  );
}

export default MetalSheetBuilding;
