export const carBusPort = {
  name: "",
  type: "Area",
  buildingAreaType: "Car/Bus Port",
  area_type: "",
  soil_type: "",
  is_land_levelling_required: null,
  is_water_proofing_layer: null,
  water_proofing_layer_type: "",
  existing_cable_routing: "",
  is_metal_sheet_required: null,
  profile_sheet: "",
  is_proposed_ground_area: null,
  height_of_trees: "",
  any_shadow_surrounding_area: "",
  shadow_height: "",
  is_water_tapping: null,
  water_tds: "",
};

export const groundMount = {
  name: "",
  type: "Area",
  buildingAreaType: "Ground Mount",
  area: "",
  soil_type: "",
  ground_condition: "",
  ground_remarks: "",
  is_trees_inside_area: null,
  height_of_trees: "",
  any_shadow_surrounding_area: "",
  is_water_tapping: null,
  water_tds: "",
};

export const rccBuildings = {
  name: "",
  type: "Building",
  buildingAreaType: "RCC buildings",
  building_age: "",
  building_height: "",
  is_parapet_wall: null,
  parapet_wall_height: "",
  is_existing_columns: null,
  is_water_proofing_layer: null,
  water_proofing_layer_type: "",
  is_anchoring_allowed: null,
  roof_access: "",
  roof_access_type: [],
  is_chimney_on_rooftop: null,
  any_trees_surrounding_building: null,
  tree_height: "",
  any_shadow_causing_objects: "",
  dust_impact: "",
  heat_impact: "",
  smoke_impact: "",
  chemical_type: "",
  existing_la: null,
  rcc_building_la_detail_array: [],
  is_water_tapping: null,
  water_tds: "",
};

export const metalSheetDetails = {
  name: "",
  type: "Building",
  buildingAreaType: "Metal Sheet",
  shed_type: "",
  sheet_age: "",
  sheet_thickness: "",
  shed_age: "",
  metal_sheet_profile: [],
  purlins_type: [],
  building_height: "",
  roof_inclination: "",
  roof_orientation: "",
  roof_structure_condition: "",
  roof_remarks: "",
  roof_insurance: null,
  is_drainage_existing: null,
  roof_access: "",
  roof_access_type: [],
  is_skylight_on_rooftop: null,
  is_chimney_on_rooftop: null,
  chimney_height: "",
  any_trees_surrounding_building: null,
  tree_height: "",
  any_shadow_causing_objects: "",
  dust_impact: "",
  heat_impact: "",
  smoke_impact: "",
  chemical_type: "",
  existing_la: null,
  metal_sheet_la_detail_array: [],
  is_water_tapping: null,
  water_tds: "",
};

// whether object is empty or not

export const rccObject = {
  name: "",
  type: "Building",
  buildingAreaType: "RCC buildings",
  building_age: "",
  rooftop_image: {
    files: [],
    existing_files: [],
    showFile: false,
  },
  is_parapet_wall: null,
  is_existing_columns: null,
  is_water_proofing_layer: null,
  roof_access: "",
  roof_access_type: [],
  is_chimney_on_rooftop: null,
  any_trees_surrounding_building: null,
  any_shadow_causing_objects: "",
  dust_impact: "",
  heat_impact: "",
  smoke_impact: "",
  existing_la: null,
  is_water_tapping: null,
};

export const metalSheetObject = {
  name: "",
  type: "Building",
  buildingAreaType: "Metal Sheet",
  shed_type: "",
  sheet_age: "",
  shed_age: "",
  metal_sheet_profile: [],
  purlins_type: [],
  roof_structure_condition: "",
  roof_access: "",
  roof_access_type: [],
  is_skylight_on_rooftop: null,
  is_chimney_on_rooftop: null,
  any_trees_surrounding_building: null,
  any_shadow_causing_objects: "",
  dust_impact: "",
  heat_impact: "",
  smoke_impact: "",
  existing_la: null,
  is_water_tapping: null,
  building_image: {
    files: [],
    existing_files: [],
    showFile: false,
  },
};

export const groundMountObject = {
  name: "",
  type: "Area",
  buildingAreaType: "Ground Mount",
  highlight_designated_ground_image: {
    files: [],
    existing_files: [],
    showFile: false,
  },
  soil_type: "",
  ground_condition: "",
  is_trees_inside_area: null,
  any_shadow_surrounding_area: "",
  is_water_tapping: null,
};

export const carPortObject = {
  name: "",
  type: "Area",
  buildingAreaType: "Car/Bus Port",
  area_type: "",
  is_metal_sheet_required: null,
  is_proposed_ground_area: null,
  any_trees_surrounding_building: "",
  any_shadow_surrounding_area: "",
  shadow_height: "",
  is_water_tapping: null,
};

export const mapDetails = (array, fields) =>
  array?.map((item) => {
    let mappedItem = {};
    fields.forEach((field) => {
      if (field === "file") {
        mappedItem["file"] = {
          showFile: false,
          files: [],
          existing_files: item["image_ids"].map((i, index) => {
            return {
              id: i,
              file: item["files"][i],
            };
          }),
        };
      } else {
        mappedItem[field] = item[field];
      }
    });
    return mappedItem;
  });
