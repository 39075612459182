import axios from "axios";
import {
  GET_SCM_DETAILS_FAILURE,
  GET_SCM_DETAILS_LOADING,
  GET_SCM_DETAILS_SUCCESS,
} from "../types";
import { getItem } from "../../SecureStorage";
import { message } from "antd";

export const getScmDetails = (data) => (dispatch) => {
  let userData;
  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  dispatch({ type: GET_SCM_DETAILS_LOADING, payload: null });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}get_scm_details/`, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
      params: {
        event_id: data,
      },
    })
    .then((result) => {
      dispatch({ type: GET_SCM_DETAILS_SUCCESS, payload: result.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SCM_DETAILS_FAILURE,
        payload: { message: err.message },
      });
    });
};

export const generateSCMReport = () => (dispatch) => {
  let userData;
  let projectId;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }
  const formData = new FormData();

  formData.append("project_id", projectId);
  axios
    .post(`${process.env.REACT_APP_BASE_URL}generate_scm_report/`, formData, {
      headers: {
        Authorization: "Token " + userData.authToken,
      },
    })
    .then((result) => {
      if (result?.data?.status == "FAIL") {
        return message.error(result?.data?.response);
      }
      message.success("Generated the File Successfully!");
      var win = window.open(result?.data?.file_url, "_blank");
      win.focus();
    })
    .catch((err) => {
      return message.error(err.message);
    });
};
