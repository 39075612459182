import { EditOutlined, RightOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@material-ui/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBuildingArea,
  getSiteVisitDetails,
  updateBuildingArea,
} from "../../actions/get-site-visit-action";
import { setItem } from "../../SecureStorage";
import "./add-building-area.style.less";
import {
  carBusPort,
  metalSheetDetails,
  groundMount,
  rccBuildings,
} from "./buildingAreaConstants";
import SiteVistContext from "./SiteVisitContext";
const { Option } = Select;
function AddBuildingArea({ siteVisitId }) {
  const [isAddModal, setIsAddModal] = useState(false);

  const [title, setTitle] = useState();
  const [type, setType] = useState();
  const [values, setValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [prevSelectedValue, setPrevSelectedValue] = useState();
  const [modalId, setModalId] = useState();
  const [totalValues, setTotalValues] = useState();
  const [flag, setFlag] = useState(false);

  const siteVisitContext = useContext(SiteVistContext);
  const {
    defaultTabs,
    handleTabs,
    handleActiveTabHandler,
    activeTab,
    setBuildingAreaIndex,
  } = siteVisitContext;
  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);

  const tabData = (values) => {
    return values?.map((i, index) => {
      let keys = Object.keys(i);
      let count = keys?.reduce((acc, v) => {
        let val = i[v];
        if (Array.isArray(val)) {
          if (val?.length > 0) {
            return acc + 1;
          } else {
            return acc;
          }
        } else if (val || typeof val == "boolean") {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      return {
        oldIndex: index,
        title: i.name,
        selectedValue: i.buildingAreaType,
        progress: Math.round((count / keys?.length) * 100),
        type: i.type,
      };
    });
  };

  useEffect(() => {
    if (getSiteVisitDetailsReducer?.success.data) {
      let tab1 = tabData(
        getSiteVisitDetailsReducer?.success.data?.rcc_building_details_array
      );
      let tab2 = tabData(
        getSiteVisitDetailsReducer?.success.data?.metal_sheet_details_array
      );
      let tab3 = tabData(
        getSiteVisitDetailsReducer?.success.data?.ground_mount_details_array
      );

      let tab4 = tabData(
        getSiteVisitDetailsReducer?.success.data?.car_port_details_array
      );
      let newTabs = [...tab1, ...tab2, ...tab3, ...tab4];

      setTotalValues(
        newTabs?.map((i, index) => {
          return {
            id: index,
            index: i.oldIndex,
            title: i.title,
            selectedValue: i.selectedValue,
            progress: i.progress,
            type: i.type,
          };
        })
      );
    }
  }, [getSiteVisitDetailsReducer?.success.data]);

  useEffect(() => {
    if (totalValues) {
      let newTabs = totalValues.map((i, index) => {
        return {
          id: defaultTabs.length - 2 + index,
          tabIndex: i.index,
          name: i.title,
          collapseTabs: ["Basic Details"],
          progress: i.progress,
          isCollapse: true,
          type: i.type,
          selectedValue: i.selectedValue,
        };
      });

      handleTabs(newTabs);
    }
  }, [totalValues]);

  console.log(totalValues, "tttt");

  const addNewModal = () => {
    setTitle();
    setType();
    setSelectedValue();
    setValues([]);
    setModalId();
    setIsAddModal(true);
  };

  const handleOk = () => {
    setIsAddModal(false);
  };
  const handleCancel = () => {
    setIsAddModal(false);
  };

  const handleType = (value) => {
    setType(value);
    setSelectedValue();
    if (value == "Building") {
      setValues(["Metal Sheet", "RCC buildings"]);
    } else {
      setValues(["Ground Mount", "Car/Bus Port"]);
    }
  };
  const handleSelectedValue = (value) => {
    setSelectedValue(value);
    if (value != prevSelectedValue) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const addHandler = () => {
    if (modalId != undefined) {
      if (siteVisitId && title && type && selectedValue) {
        const formData = new FormData();
        formData.append("site_visit_id", siteVisitId);
        if (flag == true) {
          formData.append("old_building_index", modalId);
        } else {
          formData.append("index", modalId);
        }
        if (flag == true) {
          formData.append(
            "old_building_type",
            prevSelectedValue === "RCC buildings"
              ? "rcc_building_details"
              : prevSelectedValue === "Metal Sheet"
              ? "metal_sheet_details"
              : prevSelectedValue === "Ground Mount"
              ? "ground_mount_details"
              : prevSelectedValue === "Car/Bus Port"
              ? "car_port_details"
              : null
          );
        }

        if (selectedValue === "Metal Sheet") {
          let temp = metalSheetDetails;
          temp.name = title;
          temp.type = type;

          formData.append("metal_sheet_details", JSON.stringify(temp));
        } else if (selectedValue === "RCC buildings") {
          let temp = rccBuildings;
          temp.name = title;
          temp.type = type;

          formData.append("rcc_building_details", JSON.stringify(temp));
        } else if (selectedValue === "Ground Mount") {
          let temp = groundMount;
          temp.name = title;
          temp.type = type;

          formData.append("ground_mount_details", JSON.stringify(temp));
        } else if (selectedValue === "Car/Bus Port") {
          let temp = carBusPort;
          temp.name = title;
          temp.type = type;

          formData.append("car_port_details", JSON.stringify(temp));
        }
        Promise.resolve(dispatch(updateBuildingArea(formData))).then((res) => {
          if (res) {
            dispatch(getSiteVisitDetails(siteVisitId));
            setTitle();
            setType();
            setSelectedValue();
            setValues([]);
            setIsAddModal(false);
          }
        });
      } else {
        message.error("Please Select all the fields");
      }
    } else {
      if (siteVisitId && title && type && selectedValue) {
        const formData = new FormData();
        formData.append("site_visit_id", siteVisitId);

        if (selectedValue === "Metal Sheet") {
          let temp = metalSheetDetails;
          temp.name = title;
          temp.type = type;
          formData.append("metal_sheet_details", JSON.stringify(temp));
        } else if (selectedValue === "RCC buildings") {
          let temp = rccBuildings;
          temp.name = title;
          temp.type = type;
          formData.append("rcc_building_details", JSON.stringify(temp));
        } else if (selectedValue === "Ground Mount") {
          let temp = groundMount;
          temp.name = title;
          temp.type = type;
          formData.append("ground_mount_details", JSON.stringify(temp));
        } else if (selectedValue === "Car/Bus Port") {
          let temp = carBusPort;
          temp.name = title;
          temp.type = type;
          formData.append("car_port_details", JSON.stringify(temp));
        }
        Promise.resolve(dispatch(addBuildingArea(formData))).then((res) => {
          if (res) {
            dispatch(getSiteVisitDetails(siteVisitId));
            setTitle();
            setType();
            setSelectedValue();
            setValues([]);
            setIsAddModal(false);
          }
        });
      } else {
        message.error("Please Select all the fields");
      }
    }
  };

  const navigateHandler = (id, index) => {
    handleActiveTabHandler(id + 3);
    setItem("building_area_index", index);
    setBuildingAreaIndex(index);
  };

  const editHandler = (id) => {
    let arr = totalValues.filter((i) => i.id == id);
    let index = totalValues.findIndex((i) => i.id == id);
    setModalId(arr[0].index);
    setTitle(arr[0].title);
    setType(arr[0].type);
    setSelectedValue(arr[0].selectedValue);
    setFlag(false);
    setPrevSelectedValue(arr[0].selectedValue);

    setIsAddModal(true);

    if (arr[0].type == "Building") {
      setValues(["Metal Sheet", "RCC buildings"]);
    } else {
      setValues(["Ground Mount", "Car/Bus Port"]);
    }
  };
  return (
    <div className="add-building-empty-container">
      {totalValues?.length == 0 ? (
        <div className="add-empty-container">
          <img src={require("../../assets/sitevisit/panelBuilding.png")} />
          <h1>No Building/Area Created</h1>
          <p>Please add new Building/Area.</p>

          <Button onClick={() => setIsAddModal(true)}>
            +Add New Building/Area
          </Button>
        </div>
      ) : (
        <div className="add-building-container">
          <div className="card-group">
            <div className="card-container">
              {totalValues?.map((i, index) => {
                return (
                  <div className="card-box">
                    <Row key={index}>
                      <Col
                        span={6}
                        className="progress"
                        style={{
                          backgroundColor:
                            i.progress === 100
                              ? "#dffff5"
                              : i.progress === 0
                              ? "#e7f1ff"
                              : "#fff7e6",
                        }}
                      >
                        <Progress
                          type="circle"
                          percent={Math.round(i.progress)}
                          strokeWidth={10}
                          strokeColor={
                            i.progress === 100
                              ? "#00b680"
                              : i.progress === 0
                              ? "#9ec3f8"
                              : "#f1a73d"
                          }
                          trailColor={"#c1c1c1"}
                        />
                        <p
                          style={{
                            color:
                              i.progress === 100
                                ? "#00b680"
                                : i.progress === 0
                                ? "#9ec3f8"
                                : "#f1a73d",
                          }}
                        >
                          {i.progress === 100
                            ? "Completed"
                            : i.progress == 0
                            ? "Pending"
                            : "In-progress"}
                        </p>
                      </Col>
                      <Col span={14} className="container">
                        <h1>{i.title}</h1>
                        <p>{i.selectedValue}</p>
                      </Col>
                      <Col
                        span={2}
                        className="container"
                        style={{ cursor: "pointer" }}
                      >
                        <EditOutlined onClick={() => editHandler(i.id)} />
                      </Col>
                      <Col
                        span={2}
                        className="container"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateHandler(i.id, i.index)}
                      >
                        <RightOutlined />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>{" "}
            <Button onClick={() => addNewModal()}>
              +Add New Building/Area
            </Button>
          </div>
        </div>
      )}
      <Modal
        visible={isAddModal}
        className="add-building-modal"
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        <h1>Add New Building/Area</h1>
        <Row>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Building/Area"
            value={type}
            onChange={handleType}
            bordered={false}
          >
            <Option key="Building" value="Building">
              Building
            </Option>
            <Option key="Area" value="Area">
              Area
            </Option>
          </Select>
        </Row>
        <Row>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Type"
            disabled={values.length == 0 ? true : false}
            value={selectedValue}
            onChange={handleSelectedValue}
            bordered={false}
          >
            {values?.map((i) => {
              return (
                <Option key={i} value={i}>
                  {i}
                </Option>
              );
            })}
          </Select>
        </Row>
        <Row>
          <Input
            bordered={false}
            placeholder="Title*"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            className="input-row"
          />
        </Row>
        <div className="btn-container">
          <Button className="create-btn" onClick={() => addHandler()}>
            CREATE
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AddBuildingArea;
