import {
  GET_LINE_DETAILS_FAILURE,
  GET_LINE_DETAILS_LOADING,
  GET_LINE_DETAILS_SUCCESS,
} from "../../actions/types";

const INITIAL_STATE = {
  lineDetails: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const getLineDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LINE_DETAILS_LOADING:
      return {
        ...state,
        lineDetails: {
          ...state.lineDetails,
          get: {
            ...state.lineDetails.get,
            loading: true,
            reset: false,
            success: {
              ...state.lineDetails.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_LINE_DETAILS_SUCCESS:
      return {
        ...state,
        lineDetails: {
          ...state.lineDetails,
          get: {
            ...state.lineDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.lineDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_LINE_DETAILS_FAILURE:
      return {
        ...state,
        lineDetails: {
          ...state.lineDetails,
          get: {
            ...state.lineDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.lineDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export default getLineDetailsReducer;
