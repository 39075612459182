import {
  GET_PROJECT_DOCS_LOADING,
  GET_PROJECT_DOCS_SUCCESS,
  GET_PROJECT_DOCS_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  projectDocs: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const projectDocsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_DOCS_LOADING:
      return {
        ...state,
        projectDocs: {
          ...state.projectDocs,
          get: {
            ...state.projectDocs.get,
            loading: true,
            reset: false,
            success: {
              ...state.projectDocs.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_PROJECT_DOCS_SUCCESS:
      return {
        ...state,
        projectDocs: {
          ...state.projectDocs,
          get: {
            ...state.projectDocs.get,
            loading: false,
            reset: false,
            success: {
              ...state.projectDocs.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_PROJECT_DOCS_FAILURE:
      return {
        ...state,
        projectDocs: {
          ...state.projectDocs,
          get: {
            ...state.projectDocs.get,
            loading: false,
            reset: false,
            success: {
              ...state.projectDocs.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    default:
        return state
  }
};

export default projectDocsReducer;
