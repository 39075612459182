import { Col, Row, Radio, Space, Button, message } from "antd";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSiteVisitDetails,
  updateBuildingArea,
} from "../../actions/get-site-visit-action";
import "./building-area.style.less";
import { groundMountObject } from "./buildingAreaConstants";
import BuildingAreaTopCard from "./BuildingAreaTopCard";
import CardPercentage from "./CardPercentage";
import FloatInput from "./FloatInput";
import FloatInputSuffix from "./FloatInputSuffix";
import SiteVistContext from "./SiteVisitContext";
import UploadFile from "./UploadFile";
import { mergeFiles, processFiles, sendFiles } from "../constants";

function GroundMountArea({ selectedBuildingArea, title, siteVisitId }) {
  const siteVisitContext = useContext(SiteVistContext);
  const {
    handleActiveTabHandler,
    activeTab,
    groundMountDetails,
    setGroundMountDetails,
    groundMountRef,
    saveLaterText,
    setSaveLaterText,
    buildingAreaIndex,
  } = siteVisitContext;

  useEffect(() => {
    setGroundMountDetails({ ...groundMountDetails, name: title });
  }, [title]);

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });
  const imageKeys = [
    "highlight_designated_ground_image",
    "area_image",
    "soil_type_image",
    "ground_condition_image",
    "ground_remarks_image",
    "is_trees_inside_area_image",
    "any_shadow_surrounding_area_image",
    "is_water_tapping_image",
  ];

  useEffect(() => {
    if (
      getSiteVisitDetailsReducer?.success.data?.ground_mount_details_array
        .length > 0
    ) {
      const details =
        getSiteVisitDetailsReducer?.success.data?.ground_mount_details_array[
          buildingAreaIndex
        ];

      const processedImages = imageKeys.reduce((acc, key) => {
        acc[key] = processFiles(details[key], details, `${key}_ids`);
        return acc;
      }, {});

      let {
        highlight_designated_ground_image,
        area_image,
        soil_type_image,
        ground_condition_image,
        ground_remarks_image,
        is_trees_inside_area_image,
        any_shadow_surrounding_area_image,
        is_water_tapping_image,
        ...existingData
      } = details;

      setGroundMountDetails({
        ...existingData,
        ...processedImages,
      });
    }
  }, [getSiteVisitDetailsReducer?.success.data, buildingAreaIndex]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
    }
  }, [siteVisitId]);
  const onChangeHandler = (e) => {
    const value = e.target.value;

    setGroundMountDetails({
      ...groundMountDetails,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (saveLaterText === "Ground Mount") {
      saveHandler();
    }
  }, [saveLaterText]);

  const saveHandler = () => {
    let keys = Object.keys(groundMountObject);

    let count = keys?.reduce((acc, v) => {
      let val = groundMountDetails[v];
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    const formData = new FormData();
    formData.append("site_visit_id", siteVisitId);

    formData.append("index", buildingAreaIndex);
    const {
      highlight_designated_ground_image,
      area_image,
      soil_type_image,
      ground_condition_image,
      ground_remarks_image,
      is_trees_inside_area_image,
      any_shadow_surrounding_area_image,
      is_water_tapping_image,
      ...existingData
    } = groundMountDetails;

    formData.append("ground_mount_details", JSON.stringify(existingData));

    for (let i = 0; i < imageKeys?.length; i++) {
      sendFiles(
        groundMountDetails[imageKeys[i]]?.files,
        imageKeys[i],
        formData
      );
    }

    if (count == keys.length) {
      Promise.resolve(dispatch(updateBuildingArea(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the fields");
      setSaveLaterText();
    }
  };

  const getObstructionItems = () => {
    let items = [
      groundMountDetails?.is_trees_inside_area,
      groundMountDetails?.any_shadow_surrounding_area,
    ];

    if (groundMountDetails?.is_trees_inside_area) {
      items.push(groundMountDetails?.height_of_trees);
      items.push(mergeFiles(groundMountDetails?.is_trees_inside_area_image));
    }
    if (groundMountDetails?.any_shadow_surrounding_area) {
      items.push(
        mergeFiles(groundMountDetails?.any_shadow_surrounding_area_image)
      );
    }
  };

  return (
    <div className="building-area-container">
      <div
        className="card-container card-container-top"
        ref={(element) => {
          groundMountRef.current[0] = element;
        }}
      >
        <BuildingAreaTopCard
          label1="Name"
          label2="Type"
          placeholder1="Name"
          placeholder2="Type"
          name1="name"
          name2="type"
          onChange={onChangeHandler}
          value1={title}
          value2={selectedBuildingArea}
        />
      </div>
      <h1 className="upper-text">Ground details</h1>
      <div
        className="card-container"
        ref={(element) => {
          groundMountRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Ground details"
              items={[
                mergeFiles(
                  groundMountDetails?.highlight_designated_ground_image
                ),
                mergeFiles(groundMountDetails?.area_image),
                mergeFiles(groundMountDetails?.soil_type_image),
                mergeFiles(groundMountDetails?.ground_condition_image),
                mergeFiles(groundMountDetails?.ground_remarks_image),
                groundMountDetails?.area,
                groundMountDetails?.ground_remarks,
                groundMountDetails?.soil_type,
                groundMountDetails?.ground_condition,
              ]}
              // title="Ground details"
            />
            <h1 className="inner-text">
              Highlight the designated ground area on the satellite image
            </h1>
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="highlight_designated_ground_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Area</h1>

            <FloatInputSuffix
              type="number"
              label="Enter Area"
              placeholder="Enter Area"
              onChange={onChangeHandler}
              card="Ground details"
              name="area"
              value={groundMountDetails?.area}
              suffix={"Sqm"}
            />
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="area_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Type of soil</h1>
            <Radio.Group
              name="soil_type"
              onChange={onChangeHandler}
              value={groundMountDetails?.soil_type}
              className="horizontal-radio"
              card="Ground details"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value="Sandy Soil">
                  <span className="text-image">
                    {" "}
                    a) Sandy Soil
                    <img
                      src={require("../../assets/sitevisit/sandySoil.jpg")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Clay Soil">
                  <span className="text-image">
                    {" "}
                    b) Clay Soil
                    <img
                      src={require("../../assets/sitevisit/claySoil.jpg")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Silt Soil">
                  <span className="text-image">
                    {" "}
                    c) Silt Soil
                    <img
                      src={require("../../assets/sitevisit/siltSoil.jpg")}
                      alt="image"
                    />
                  </span>
                </Radio>
                <Radio value="Loamy Soil">
                  <span className="text-image">
                    {" "}
                    d) Loamy Soil
                    <img
                      src={require("../../assets/sitevisit/loamySoil.jpg")}
                      alt="image"
                    />
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="soil_type_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Ground condition</h1>
            <Radio.Group
              name="ground_condition"
              onChange={onChangeHandler}
              value={groundMountDetails?.ground_condition}
              className="horizontal-radio"
              card="Ground details"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value="Poor">Poor</Radio>
                <Radio value="Fair">Fair</Radio>
                <Radio value="Excellent">Excellent</Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="ground_condition_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Specificl remarks about ground</h1>
            <FloatInput
              label="Specific remarks about ground"
              placeholder="Specific remarks about ground"
              onChange={onChangeHandler}
              card="Ground details"
              name="ground_remarks"
              value={groundMountDetails?.ground_remarks}
            />
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="ground_remarks_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Obstructions on the ground</h1>
      <div
        className="card-container"
        ref={(element) => {
          groundMountRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Obstructions on the ground"
              items={getObstructionItems()}
            />
            <h1 className="inner-text">
              Any trees/vegetation inside or surrounding the proposed ground
              area.
            </h1>
            <Radio.Group
              name="is_trees_inside_area"
              onChange={onChangeHandler}
              value={groundMountDetails?.is_trees_inside_area}
              className="horizontal-radio"
              card="Obstructions on the ground"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="is_trees_inside_area_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">Height of the trees or vegetation</h1>

            <FloatInputSuffix
              type="number"
              label="Height of the trees or vegetation"
              placeholder="Height of the trees or vegetation"
              onChange={onChangeHandler}
              card="Obstructions on the ground"
              name="height_of_trees"
              value={groundMountDetails?.height_of_trees}
              suffix={"m"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">
              Any other Shadow causing objects inside or surrounding to the
              ground area
            </h1>
            <FloatInput
              label="Enter Something"
              placeholder="Enter Something"
              onChange={onChangeHandler}
              required={true}
              card="Obstructions on the ground"
              name="any_shadow_surrounding_area"
              value={groundMountDetails?.any_shadow_surrounding_area}
            />
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="any_shadow_surrounding_area_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
      </div>
      <h1 className="upper-text">Water availability for maintence</h1>
      <div
        className="card-container"
        ref={(element) => {
          groundMountRef.current[3] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Water availability for maintence"
              items={
                groundMountDetails?.is_water_tapping
                  ? [
                      groundMountDetails?.is_water_tapping,
                      mergeFiles(groundMountDetails?.is_water_tapping_image),
                      groundMountDetails?.water_tds,
                    ]
                  : [
                      groundMountDetails?.is_water_tapping,
                      groundMountDetails?.water_tds,
                    ]
              }
            />
            <h1 className="inner-text">Water tapping availability near roof</h1>
            <Radio.Group
              name="is_water_tapping"
              onChange={onChangeHandler}
              value={groundMountDetails?.is_water_tapping}
              className="horizontal-radio"
              card="Water availability for maintence"
            >
              <Space direction="horizontal" wrap={true}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
            <UploadFile
              state={groundMountDetails}
              setState={setGroundMountDetails}
              objKey="is_water_tapping_image"
              fileType="is_ground_mount_image"
              imageIndex={buildingAreaIndex}
            />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <h1 className="inner-text">TDS of water available</h1>
            <FloatInputSuffix
              type="number"
              label="TDS of water available"
              placeholder="TDS of water available"
              onChange={onChangeHandler}
              card="Water availability for maintence"
              name="water_tds"
              value={groundMountDetails?.water_tds}
              suffix={"ppm"}
            />
          </Col>
        </Row>
      </div>

      <div
        className="add-container"
        onClick={() => handleActiveTabHandler(2)}
        style={{ cursor: "pointer" }}
      >
        <p>+Add new Building/Area</p>
      </div>
      <div className="btn-container" onClick={() => saveHandler()}>
        <Button>Save</Button>
      </div>
    </div>
  );
}

export default GroundMountArea;
