import { Modal, Row, Col, Button, Upload } from "antd";
import React from "react";
import "./image-modal.style.less";
import { deleteFile } from "../../actions/get-site-visit-action";
import { useDispatch } from "react-redux";

function FormModal({
  title,
  isVisible,
  setIsVisible,
  data,
  setFormState,
  formState,
  existingFiles,
  index,
  imageType,
  uploadHandler,
  laIndex
}) {
  const handleOk = () => {};
  const handleCancel = () => {
    setIsVisible(false);
  };

  const dispatch = useDispatch();

  // Remove Newly added Files
  const handleRemove = (id) => {
    let temp = formState;
    temp[index] = {
      ...temp[index],
      file: {
        ...temp[index].file,
        files: data.filter((i) => i.uid !== id),
      },
    };
    setFormState([...temp]);
  };

  // Remove Existing Files
  const handleRemoveExistingFile = (id) => {
    const formData = new FormData();
    formData.append("file_id", id);
    formData.append(`${imageType}`, true);
    formData.append("index", index);
    if (imageType === "is_metal_sheet_la_image") {
      formData.append("metal_la_index", laIndex);
    }
    if (imageType === "is_rcc_building_la_image") {
      formData.append("rcc_la_index", laIndex);
    }
    dispatch(deleteFile(formData));

    let temp = formState;
    temp[index] = {
      ...temp[index],
      file: {
        ...temp[index].file,
        existing_files: existingFiles?.filter((x) => x.id !== id),
      },
    };
    setFormState([...temp]);
  };
  return (
    <Modal
      title={title}
      className="image-modal"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {data?.length == 0 && existingFiles?.length === 0 ? (
        <>
          <h1
            style={{
              color: "#3d87f1",
              fontSize: "2rem",
              textAlign: "center",
              margin: "3rem 0",
            }}
          >
            No Images
          </h1>
        </>
      ) : (
        <div style={{ height: "calc(30vh)", overflow: "auto" }}>
          {" "}
          {existingFiles?.map((i, index) => {
            return (
              <div className="image-row">
                <Row key={index}>
                  <Col span={6}>
                    <img src={i.file} alt={i.file} />
                  </Col>
                  <Col span={15}>
                    <h1>File - {index + 1}</h1>
                  </Col>
                  <Col span={3}>
                    <img
                      src={require("../../assets/logos/Remove.png")}
                      style={{ width: "2rem" }}
                      onClick={() => handleRemoveExistingFile(i.id)}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
          {data?.map((i, index) => {
            return (
              <div className="image-row">
                <Row key={index}>
                  <Col span={6}>
                    <img src={URL?.createObjectURL(i)} alt={i.name} />
                  </Col>
                  <Col span={15}>
                    <h1>{i.name}</h1>
                  </Col>
                  <Col span={3}>
                    <img
                      src={require("../../assets/logos/Remove.png")}
                      style={{ width: "2rem" }}
                      onClick={() => handleRemove(i.uid)}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      )}
      <Row gutter={[8, 8]} justify="center">
        <Col span={12}>
          <Upload
           accept=".jpg,.jpeg,.png,.heic"
            name="file"
            beforeUpload={(file, FileList) => uploadHandler(FileList, index)}
            showUploadList={false}
            multiple={true}
          >
            <Button className="upload">Upload Picture</Button>
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
}

export default FormModal;
