import {
    POST_PROJECT_LOADING,
    POST_PROJECT_SUCCESS,
    POST_PROJECT_FAILURE,
    POST_PROJECT_RESET,
    GET_RESPONSIBILITY_MATRIX_LOADING,
    GET_RESPONSIBILITY_MATRIX_SUCCESS,
    GET_RESPONSIBILITY_MATRIX_FAILURE,
    POST_EPC_PROJECT_LOADING,
    POST_EPC_PROJECT_SUCCESS,
    POST_EPC_PROJECT_FAILURE,
    POST_EPC_PROJECT_RESET,
    SAVE_PROJECT_FOR_LATER_LOADING,
    SAVE_PROJECT_FOR_LATER_SUCCESS,
    SAVE_PROJECT_FOR_LATER_FAILURE,
    SAVE_PROJECT_FOR_LATER_RESET,
} from "../../actions/types";

const INITIAL_STATE = {
    projects: {
        post: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
        defaultRights: {
            get: {
                loading: false,
                reset: false,
                success: {
                    ok: false,
                    data: null,
                },
                failure: {
                    error: false,
                    message: '',
                },
            }
        }
    }
};

const projectReducers = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case POST_PROJECT_LOADING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_PROJECT_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_PROJECT_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.login,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case POST_PROJECT_RESET:
            return {
                ...state,
                projects: {
                    ...state.projects.post,
                    post: {
                        ...INITIAL_STATE.projects.post,
                        reset: true,
                    },
                },
            };
        case GET_RESPONSIBILITY_MATRIX_LOADING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    defaultRights: {
                        ...state.projects.defaultRights,
                        get: {
                            ...state.projects.defaultRights.get,
                            loading: true,
                            reset: false,
                            success: {
                                ...state.projects.defaultRights.get.success,
                                ok: false,
                                data: null,
                            },
                            failure: {
                                error: false,
                                message: ''
                            }
                        }
                    }
                }
            }
        case GET_RESPONSIBILITY_MATRIX_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    defaultRights: {
                        ...state.projects.defaultRights,
                        get: {
                            ...state.projects.defaultRights.get,
                            loading: false,
                            reset: false,
                            success: {
                                ...state.projects.defaultRights.get.success,
                                ok: true,
                                data: action.payload,
                            },
                            failure: {
                                error: false,
                                message: ''
                            }
                        }
                    }
                }
            }
        case GET_RESPONSIBILITY_MATRIX_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    defaultRights: {
                        ...state.projects.defaultRights,
                        get: {
                            ...state.projects.defaultRights.get,
                            loading: false,
                            reset: false,
                            success: {
                                ...state.projects.defaultRights.get.success,
                                ok: false,
                                data: null,
                            },
                            failure: {
                                error: false,
                                message: action.payload.message
                            }
                        }
                    }
                }
            }
        case POST_EPC_PROJECT_LOADING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_EPC_PROJECT_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case POST_EPC_PROJECT_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.login,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case POST_EPC_PROJECT_RESET:
            return {
                ...state,
                projects: {
                    ...state.projects.post,
                    post: {
                        ...INITIAL_STATE.projects.post,
                        reset: true,
                    },
                },
            };
        case SAVE_PROJECT_FOR_LATER_LOADING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case SAVE_PROJECT_FOR_LATER_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case SAVE_PROJECT_FOR_LATER_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.login,
                    post: {
                        ...state.projects.post,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.projects.post.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case SAVE_PROJECT_FOR_LATER_RESET:
            return {
                ...state,
                projects: {
                    ...state.projects.post,
                    post: {
                        ...INITIAL_STATE.projects.post,
                        reset: true,
                    },
                },
            };
        default:
            return state
    }
}

export default projectReducers;