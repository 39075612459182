import {
    TOGGLE_TASK_LOADING,
  TOGGLE_TASK_SUCCESS,
  TOGGLE_TASK_FAILURE,
} from '../../actions/types';

const INITIAL_STATE = {
    event: {
        post: {
            loading: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
    },
};

const toggleTaskReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_TASK_LOADING:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: true,
                        success: {
                            ...state.event.post.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case TOGGLE_TASK_SUCCESS:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: false,
                        success: {
                            ...state.event.post.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case TOGGLE_TASK_FAILURE:
            return {
                ...state,
                event: {
                    ...state.event,
                    post: {
                        ...state.event.post,
                        loading: false,
                        success: {
                            ...state.event.post.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
    }
    return state;
}

export default toggleTaskReducers;



