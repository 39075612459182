import {
  GET_VENDOR_DASHBOARD_LOADING,
  GET_VENDOR_DASHBOARD_SUCCESS,
  GET_VENDOR_DASHBOARD_FAILURE,
  GET_VENDOR_DASHBOARD_RESET,
  FILTER_ACTIVITY_LIST_LOADING,
  FILTER_ACTIVITY_LIST_SUCCESS,
  FILTER_ACTIVITY_LIST_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  vendorDashboard: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const vendorDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VENDOR_DASHBOARD_LOADING:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: true,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_VENDOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_VENDOR_DASHBOARD_FAILURE:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_VENDOR_DASHBOARD_RESET:
        return {
            ...state,
            vendorDashboard: {
                ...state.vendorDashboard.get,
                post: {
                    ...INITIAL_STATE.vendorDashboard.get,
                    reset: true,
                },
            },
        }
    case FILTER_ACTIVITY_LIST_LOADING:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: true,
              data: {
                ...state.vendorDashboard.get.success.data,
                activity_list: []
              }
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case FILTER_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: true,
              data: {
                ...state.vendorDashboard.get.success.data,
                activity_list: action.payload
              }
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case FILTER_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        vendorDashboard: {
          ...state.vendorDashboard,
          get: {
            ...state.vendorDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorDashboard.get.success,
              ok: true,
              data: state.vendorDashboard.get.success.data
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    default:
      return state;
  }
};

export default vendorDashboardReducer;
