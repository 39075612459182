import { Upload, Button } from "antd";
import React, { useEffect, useState } from "react";
import ImageModal from "./ImageModal";
import "./electrical-details.style.less";

function UploadFile({ state, setState, objKey, fileType, imageIndex }) {
  return (
    <>
      <div className="buttons-grp">
        <Upload
          accept=".jpg,.jpeg,.png,.heic"
          name="file"
          beforeUpload={(file, FileList) => {
            setState({
              ...state,
              [objKey]: { ...state[objKey], files: [...FileList] },
            });
          }}
          showUploadList={false}
          multiple={true}
        >
          <Button className="upload">Upload Picture</Button>
        </Upload>

        <Button
          onClick={() => {
            setState({
              ...state,
              [objKey]: { ...state[objKey], showFile: true },
            });
          }}
          className="save"
        >
          View All Images
        </Button>

        <ImageModal
          title="Image List"
          existingFiles={
            state[objKey]?.existing_files?.length > 0
              ? state[objKey]?.existing_files
              : []
          }
          isVisible={state[objKey]?.showFile}
          setIsVisible={() =>
            setState({
              ...state,
              [objKey]: { ...state[objKey], showFile: false },
            })
          }
          data={state[objKey]?.files?.length > 0 ? state[objKey]?.files : []}
          setFormState={setState}
          objectKey={objKey}
          formState={state}
          fileType={fileType}
          imageIndex={imageIndex}
        />
      </div>
    </>
  );
}

export default UploadFile;
