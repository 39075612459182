import * as Actions from '../../actions/types';

const INITIAL_STATE = {
    myTickets: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
    },
    allTickets: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        },
    }
};

const ticketNotificationReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Actions.GET_COMPANY_TICKETS_LOADING:
            return {
                ...state,
                myTickets: {
                    ...state.myTickets,
                    get: {
                        ...state.myTickets.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.myTickets.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
                allTickets: {
                    ...state.allTickets,
                    get: {
                        ...state.allTickets.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.allTickets.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case Actions.GET_COMPANY_TICKETS_SUCCESS:
            return {
                ...state,
                myTickets: {
                    ...state.myTickets,
                    get: {
                        ...state.myTickets.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.myTickets.get.success,
                            ok: true,
                            data: action.payload?.my_tickets,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
                allTickets: {
                    ...state.allTickets,
                    get: {
                        ...state.allTickets.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.allTickets.get.success,
                            ok: true,
                            data: action.payload?.all_tickets,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case Actions.GET_COMPANY_TICKETS_FAILURE:
            return {
                ...state,
                myTickets: {
                    ...state.myTickets,
                    get: {
                        ...state.myTickets.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.myTickets.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
                allTickets: {
                    ...state.allTickets,
                    get: {
                        ...state.allTickets.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.allTickets.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
        case Actions.GET_COMPANY_TICKETS_RESET:
            return {
                ...state,
                myTickets: {
                    ...state.myTickets.get,
                    get: {
                        ...INITIAL_STATE.myTickets.post,
                        reset: true,
                    },
                },
                allTickets: {
                    ...state.allTickets.get,
                    get: {
                        ...INITIAL_STATE.allTickets.post,
                        reset: true,
                    },
                },
            }

    }
    return state;
}

export default ticketNotificationReducers;
