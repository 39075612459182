import {
  SIGNUP_USER_LOADING,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_RESET,
  GET_COMPANY_DETAILS_LOADING,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  signup: {
    post: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  getCompanyDetails: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const signUpReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_LOADING:
      return {
        ...state,
        signup: {
          ...state.signup,
          post: {
            ...state.signup.post,
            loading: true,
            reset: false,
            success: {
              ...state.signup.post.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        signup: {
          ...state.signup,
          post: {
            ...state.signup.post,
            loading: false,
            reset: false,
            success: {
              ...state.signup.post.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case SIGNUP_USER_FAILURE:
      return {
        ...state,
        signup: {
          ...state.signup,
          post: {
            ...state.signup.post,
            loading: false,
            reset: false,
            success: {
              ...state.signup.post.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case SIGNUP_USER_RESET:
      return {
        ...state,
        signup: {
          ...state.signup.post,
          post: {
            ...INITIAL_STATE.signup.post,
            reset: true,
          },
        },
      };

    case GET_COMPANY_DETAILS_LOADING:
      return {
        ...state,
        getCompanyDetails: {
          ...state.getCompanyDetails,
          get: {
            ...state.getCompanyDetails.get,
            loading: true,
            reset: false,
            success: {
              ...state.getCompanyDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyDetails: {
          ...state.getCompanyDetails,
          get: {
            ...state.getCompanyDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.getCompanyDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        getCompanyDetails: {
          ...state.getCompanyDetails,
          get: {
            ...state.getCompanyDetails.get,
            loading: false,
            reset: false,
            success: {
              ...state.getCompanyDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload,
            },
          },
        },
      };
  }
  return INITIAL_STATE;
};

export default signUpReducers;
