import {
  GET_EVENT_DATA_LOADING,
  GET_EVENT_DATA_SUCCESS,
  GET_EVENT_DATA_FAILURE,
  GET_EVENT_DATA_RESET,
  GET_EVENT_INITIAL_VALUES,
  GET_EVENT_DELAYED_INFO_LOADING,
  GET_EVENT_DELAYED_INFO_SUCCESS,
  GET_EVENT_DELAYED_INFO_FAILURE,
  GET_EVENT_DELAYED_INFO_RESET,
} from "../../actions/types";

const INITIAL_STATE = {
  eventdata: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  eventDelayedData: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const eventDataReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EVENT_DATA_LOADING:
      return {
        ...state,
        eventdata: {
          ...state.eventdata,
          get: {
            ...state.eventdata.get,
            loading: true,
            reset: false,
            success: {
              ...state.eventdata.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_EVENT_DATA_SUCCESS:
      return {
        ...state,
        eventdata: {
          ...state.eventdata,
          get: {
            ...state.eventdata.get,
            loading: false,
            reset: false,
            success: {
              ...state.eventdata.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_EVENT_DATA_FAILURE:
      return {
        ...state,
        eventdata: {
          ...state.eventdata,
          get: {
            ...state.eventdata.get,
            loading: false,
            reset: false,
            success: {
              ...state.eventdata.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_EVENT_DATA_RESET:
      return {
        ...state,
        eventdata: {
          ...state.eventdata.get,
          post: {
            ...INITIAL_STATE.eventdata.get,
            reset: true,
          },
        },
      };
    case GET_EVENT_DELAYED_INFO_LOADING:
      return {
        ...state,
        eventDelayedData: {
          ...state.eventDelayedData,
          get: {
            ...state.eventDelayedData.get,
            loading: true,
            reset: false,
            success: {
              ...state.eventDelayedData.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_EVENT_DELAYED_INFO_SUCCESS:
      return {
        ...state,
        eventDelayedData: {
          ...state.eventDelayedData,
          get: {
            ...state.eventDelayedData.get,
            loading: false,
            reset: false,
            success: {
              ...state.eventDelayedData.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_EVENT_DELAYED_INFO_FAILURE:
      return {
        ...state,
        eventDelayedData: {
          ...state.eventDelayedData,
          get: {
            ...state.eventDelayedData.get,
            loading: false,
            reset: false,
            success: {
              ...state.eventDelayedData.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_EVENT_DELAYED_INFO_RESET:
      return {
        ...state,
        eventDelayedData: {
          ...state.eventDelayedData.get,
          post: {
            ...INITIAL_STATE.eventDelayedData.get,
            reset: true,
          },
        },
      };
    case GET_EVENT_INITIAL_VALUES:
      return state;
  }
  return state;
};

export default eventDataReducers;
