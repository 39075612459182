import {
  GET_INVENTORY_LOADING,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  inventory: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVENTORY_LOADING:
      return {
          ...state,
          inventory: {
            ...state.inventory,
            get: {
              ...state.inventory.get,
              loading: true,
              reset: false,
              success: {
                ...state.inventory.get.success,
                ok: false,
                data: null,
              },
              failure: {
                error: false,
                message: "",
              },
            },
          },
          
      };

    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          get: {
            ...state.inventory.get,
            loading: false,
            reset: false,
            success: {
              ...state.inventory.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_INVENTORY_FAILURE:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          get: {
            ...state.inventory.get,
            loading: false,
            reset: false,
            success: {
              ...state.inventory.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default inventoryReducer;
