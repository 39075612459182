import React, { useContext, useRef, useState, useEffect } from "react";
import CarBusPortArea from "./CarBusPortArea.jsx";
import ElectricalDetails from "./ElectricalDetails.jsx";
import MetalSheetBuilding from "./MetalSheetBuilding.jsx";
import GroundMountArea from "./GroundMountArea.jsx";
import RccBuilding from "./RccBuilding.jsx";
import "./site-right-card.style.less";
import ClientDetails from "./ClientDetails";
import SiteVistContext from "./SiteVisitContext";
import ProjectSpec from "./ProjectSpec.jsx";
import ProposedLocation from "./ProposedLocation.jsx";

function SiteRightCard({
  title,
  collapseTabs,
  isCollapse,
  siteVisitId,
  buildingAreaType,
  selectedBuildingArea,
}) {
  const siteVisitContext = useContext(SiteVistContext);

  const { activeTab, electricalRef, handleInnerCollapseId } = siteVisitContext;

  var isSyncingLeftScroll = false;
  var isSyncingRightScroll = false;

  const isVisible = function (ele, container) {
    const { bottom, height, top } = ele.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    return top <= containerRect.top
      ? containerRect.top - top <= height
      : bottom - containerRect.bottom <= height;
  };
  const handleRightScroll = () => {
    if (!isSyncingLeftScroll) {
      isSyncingRightScroll = true;
      var parent = document.querySelector(".collapse-tab-section");

      var elements = document.querySelectorAll(
        ".collapse-tab-section .inner-collapse-text"
      );

      for (var i = 0; i < elements.length; i++) {
        var el = document.querySelectorAll(
          ".collapse-tab-section .inner-collapse-text"
        )[i];
        var l =
          title === "Project Specifications"
            ? document.querySelectorAll(".site-right-card .document-wrapper")[i]
            : document.querySelectorAll(".site-right-card .card-container")[i];
        if (isVisible(el, parent) && l) {
          l.scrollIntoView();
          // handleInnerCollapseId(i)

          break;
        }
      }
    }
    isSyncingLeftScroll = false;
  };
  const handleLeftScroll = () => {
    if (!isSyncingRightScroll) {
      isSyncingLeftScroll = true;
      var parent = document.querySelector(".site-right-card");

      var elements =
        title === "Project Specifications"
          ? document.querySelectorAll(".site-right-card .document-wrapper")
          : document.querySelectorAll(".site-right-card .card-container");

      for (var i = 0; i < elements?.length; i++) {
        var el =
          title === "Project Specifications"
            ? document.querySelectorAll(".site-right-card .document-wrapper")[i]
            : document.querySelectorAll(".site-right-card .card-container")[i];
        var l = document.querySelectorAll(
          ".collapse-tab-section .inner-collapse-text"
        )[i];

        if (el && isVisible(el, parent)) {
          l.scrollIntoView();
          break;
        }
      }
    }
    isSyncingRightScroll = false;
  };

  useEffect(() => {
    var rightDiv = document.querySelector(".site-right-card");
    rightDiv.addEventListener("scroll", handleLeftScroll);

    return () => rightDiv.removeEventListener("scroll", handleLeftScroll);
  }, []);


  useEffect(() => {
    var leftDiv = document.querySelector(".collapse-tab-section");
    leftDiv.addEventListener("scroll", handleRightScroll);

    return () => leftDiv.removeEventListener("scroll", handleRightScroll);
  }, []);

  return (
    <div className="site-right-card">
      {activeTab == 0 ? (
        <ClientDetails siteVisitId={siteVisitId} />
      ) : activeTab === 1 ? (
        <ElectricalDetails siteVisitId={siteVisitId} />
      ) : buildingAreaType === "Building" &&
        selectedBuildingArea === "Metal Sheet" ? (
        <MetalSheetBuilding
          buildingAreaType={buildingAreaType}
          selectedBuildingArea={selectedBuildingArea}
          title={title}
          siteVisitId={siteVisitId}
        />
      ) : buildingAreaType === "Building" &&
        selectedBuildingArea === "RCC buildings" ? (
        <RccBuilding
          buildingAreaType={buildingAreaType}
          selectedBuildingArea={selectedBuildingArea}
          title={title}
          siteVisitId={siteVisitId}
        />
      ) : buildingAreaType === "Area" &&
        selectedBuildingArea === "Ground Mount" ? (
        <GroundMountArea
          buildingAreaType={buildingAreaType}
          selectedBuildingArea={selectedBuildingArea}
          title={title}
          siteVisitId={siteVisitId}
        />
      ) : buildingAreaType === "Area" &&
        selectedBuildingArea === "Car/Bus Port" ? (
        <CarBusPortArea
          buildingAreaType={buildingAreaType}
          selectedBuildingArea={selectedBuildingArea}
          title={title}
          siteVisitId={siteVisitId}
        />
      ) : title === "Proposed Locations" ? (
        <ProposedLocation title={title} siteVisitId={siteVisitId} />
      ) : (
        <ProjectSpec title={title} siteVisitId={siteVisitId} />
      )}
    </div>
  );
}

export default SiteRightCard;
