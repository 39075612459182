import {
  GET_DAILY_UPDATE_PROJECTS_LOADING,
  GET_DAILY_UPDATE_PROJECTS_SUCCESS,
  GET_DAILY_UPDATE_PROJECTS_FAILURE,
  GET_TODAY_TASKS_LOADING,
  GET_TODAY_TASKS_SUCCESS,
  GET_TODAY_TASKS_FAILURE,
  GET_TOMORROW_TASKS_LOADING,
  GET_TOMORROW_TASKS_SUCCESS,
  GET_TOMORROW_TASKS_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
  dailyUpdates: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },

  memberTasks: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },

  tomorrowMemberTasks: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const dailyUpdatesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DAILY_UPDATE_PROJECTS_LOADING:
      return {
        ...state,
        dailyUpdates: {
          ...state.dailyUpdates,
          get: {
            ...state.dailyUpdates.get,
            loading: true,
            reset: false,
            success: {
              ...state.dailyUpdates.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_DAILY_UPDATE_PROJECTS_SUCCESS:
      return {
        ...state,
        dailyUpdates: {
          ...state.dailyUpdates,
          get: {
            ...state.dailyUpdates.get,
            loading: false,
            reset: false,
            success: {
              ...state.dailyUpdates.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_DAILY_UPDATE_PROJECTS_FAILURE:
      return {
        ...state,
        dailyUpdates: {
          ...state.dailyUpdates,
          get: {
            ...state.dailyUpdates.get,
            loading: false,
            reset: false,
            success: {
              ...state.dailyUpdates.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case GET_TODAY_TASKS_LOADING:
      return {
        ...state,
        memberTasks: {
          ...state.memberTasks,
          get: {
            ...state.memberTasks.get,
            loading: true,
            reset: false,
            success: {
              ...state.memberTasks.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_TODAY_TASKS_SUCCESS:
      return {
        ...state,
        memberTasks: {
          ...state.memberTasks,
          get: {
            ...state.memberTasks.get,
            loading: false,
            reset: false,
            success: {
              ...state.memberTasks.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_TODAY_TASKS_FAILURE:
      return {
        ...state,
        memberTasks: {
          ...state.memberTasks,
          get: {
            ...state.memberTasks.get,
            loading: false,
            reset: false,
            success: {
              ...state.memberTasks.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case GET_TOMORROW_TASKS_LOADING:
      return {
        ...state,
        tomorrowMemberTasks: {
          ...state.tomorrowMemberTasks,
          get: {
            ...state.tomorrowMemberTasks.get,
            loading: true,
            reset: false,
            success: {
              ...state.tomorrowMemberTasks.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_TOMORROW_TASKS_SUCCESS:
      return {
        ...state,
        tomorrowMemberTasks: {
          ...state.tomorrowMemberTasks,
          get: {
            ...state.tomorrowMemberTasks.get,
            loading: false,
            reset: false,
            success: {
              ...state.tomorrowMemberTasks.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case GET_TOMORROW_TASKS_FAILURE:
      return {
        ...state,
        tomorrowMemberTasks: {
          ...state.tomorrowMemberTasks,
          get: {
            ...state.tomorrowMemberTasks.get,
            loading: false,
            reset: false,
            success: {
              ...state.tomorrowMemberTasks.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default dailyUpdatesReducer;
