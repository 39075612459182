import React from "react";
import { getItem, removeItem } from "../SecureStorage";

const EcomReRoute = (props) => {
  const userType = props?.location?.state?.userType;
  const user = JSON.parse(getItem("userData"));
  const pmtToken = user?.authToken;
  const email = user?.username;
  const auctionToken = JSON.parse(getItem("auctionToken"));
  const ecomToken = JSON.parse(getItem("ecomToken"));
  const isNewUser = JSON.parse(getItem("is_new_ecom_user"));
  const firstLogin = JSON.parse(getItem("firstLogin"));

  removeItem("redirectFrom");

  const path = "/";
  let url = `${process.env.REACT_APP_ECOM_SITE_BASE_URL}?ecomToken=${ecomToken}&path=${path}&email=${email}&is_new=${isNewUser}&auctionToken=${auctionToken}&pmtToken=${pmtToken}&redirectFrom=pmt`;

  if (userType) {
    url = url + `&user_type=${userType}`;
  }

  if (firstLogin) {
    url = url + "&first_login=true";
  }

  return window.location.replace(url);
};

export default EcomReRoute;
