import {
    GET_PROJECT_REPORT_LOADING,
    GET_PROJECT_REPORT_SUCCESS,
    GET_PROJECT_REPORT_FAILURE,
} from '../../actions/types'

const INITIAL_STATE = {
    project_report: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            }
        }
    }
}

const projectReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_PROJECT_REPORT_LOADING:
            return {
                ...state,
                project_report: {
                    ...state.project_report,
                    get: {
                        ...state.project_report.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.project_report.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: false,
                            message: ''
                        }
                    }
                }
            }

        case GET_PROJECT_REPORT_SUCCESS:
            return {
                ...state,
                project_report: {
                    ...state.project_report,
                    get: {
                        ...state.project_report.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.project_report.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: ''
                        }
                    }
                }
            }

        case GET_PROJECT_REPORT_FAILURE:
            return {
                ...state,
                project_report: {
                    ...state.project_report,
                    get: {
                        ...state.project_report.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.project_report.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload
                        }
                    }
                }
            }

        default: return state
    }
}

export default projectReportReducer