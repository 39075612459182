import {
  Col,
  Row,
  Select,
  Radio,
  Space,
  Button,
  Checkbox,
  message,
} from "antd";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addElectricalDetails,
  getSiteVisitDetails,
  getSiteVisitformDetails,
} from "../../actions/get-site-visit-action";
import CardPercentage from "./CardPercentage";
import "./electrical-details.style.less";
import FloatInput from "./FloatInput";
import FloatInputSuffix from "./FloatInputSuffix";
import SiteVistContext from "./SiteVisitContext";
import UploadFile from "./UploadFile";
import MultipleForms from "./MultipleForms";
import {
  checkIfBoolean,
  getSiteVisitFormFields,
  mergeFiles,
} from "../constants";
import { mapDetails } from "./buildingAreaConstants";

const { Option } = Select;

function ElectricalDetails({ siteVisitId }) {
  const siteVisitContext = useContext(SiteVistContext);
  const {
    electricalRef,
    electricalDetails,
    setElectricalDetails,
    tabsList,
    editTabsHandler,
    setSaveLaterText,
    saveLaterText,
    handleActiveTabHandler,
    activeTab,
  } = siteVisitContext;

  //  Transformers
  const [transformers, setTransformers] = useState(0);
  const [transformerData, setTransformerData] = useState([]);
  const [transformerLength, setTransformerLength] = useState(0);

  // dgs
  const [dgs, setDgs] = useState(0);
  const [dgsData, setDgsData] = useState([]);
  const [dgsLength, setDgsLength] = useState(0);

  // lt panels
  const [ltpanels, setLtpanels] = useState(0);
  const [ltpanelsData, setLtpanelsData] = useState([]);
  const [ltPanelLength, setLtPanelLength] = useState(0);

  // spare feeders
  const [spareFeeders, setSpareFeeders] = useState(0);
  const [spareFeedersData, setSpareFeedersData] = useState([]);
  const [spareFeederLength, setSpareFeederLength] = useState(0);

  const dispatch = useDispatch();

  const getSiteVisitDetailsReducer = useSelector((state) => {
    return state.getSiteVisitDetailsReducers.siteVisitDetails.get;
  });
  const getSiteVisitFormDetailsReducer = useSelector((state) => {
    return state.getSiteVisitFormDetailsReducers.siteVisitFormDetails.get;
  });

  useEffect(() => {
    if (siteVisitId) {
      dispatch(getSiteVisitDetails(siteVisitId));
      dispatch(getSiteVisitformDetails(siteVisitId));
    }
  }, [siteVisitId]);

  useEffect(() => {
    if (saveLaterText === "Electrical Details") {
      saveHandler();
    }
  }, [saveLaterText]);

  useEffect(() => {
    if (getSiteVisitFormDetailsReducer.success.data) {
      let data = getSiteVisitFormDetailsReducer.success.data;
      setTransformers(data?.transformer_details_array?.length);
      setTransformerLength(data?.transformer_details_array?.length);
      setDgs(data?.dg_details_array?.length);
      setDgsLength(data?.dg_details_array?.length);
      setSpareFeeders(data?.spare_feeder_details_array?.length);
      setSpareFeederLength(data?.spare_feeder_details_array?.length);
      setLtpanels(data?.lt_panel_details_array?.length);
      setLtPanelLength(data?.lt_panel_details_array?.length);
      const temp1 = mapDetails(data?.transformer_details_array, [
        "id",
        "rating",
        "hv",
        "lv",
        "file",
      ]);
      const temp2 = mapDetails(data?.dg_details_array, [
        "id",
        "rating",
        "type",
        "file",
      ]);
      const temp3 = mapDetails(data?.spare_feeder_details_array, [
        "id",
        "rating",
        "file",
      ]);
      const temp4 = mapDetails(data?.lt_panel_details_array, [
        "id",
        "rating",
        "file",
      ]);

      setTransformerData(temp1);
      setDgsData(temp2);
      setSpareFeedersData(temp3);
      setLtpanelsData(temp4);
      setElectricalDetails({
        ...electricalDetails,
        transformer_details_array: temp1,
        dg_details_array: temp2,
        spare_feeder_details_array: temp3,
        lt_panel_details_array: temp4,
      });
    }
  }, [getSiteVisitFormDetailsReducer.success.data]);

  useEffect(() => {
    if (getSiteVisitDetailsReducer.success.data) {
      let data = getSiteVisitDetailsReducer.success.data;

      setElectricalDetails({
        ...electricalDetails,
        vacant_chamber_image: {
          files: [],
          existing_files: data?.vacant_chamber_images || [],
          showFile: false,
        },
        bus_bar_extension_image: {
          files: [],
          existing_files: data?.bus_bar_extension_images || [],
          showFile: false,
        },
        contracted_load: data?.contracted_load,
        grid_voltage_level: data?.grid_voltage_level,
        solar_pv_interconnected_voltage: data?.solar_pv_interconnected_voltage,
        express_dedicated_line: checkIfBoolean(data?.express_dedicated_line),
        net_meter_requirement: checkIfBoolean(data?.net_meter_requirement),
        internet_availability: checkIfBoolean(data?.internet_availability),
        single_phase_supply_availability: checkIfBoolean(
          data?.single_phase_supply_availability
        ),
        load_shedding: data?.load_shedding,
        working_avg_load: data?.working_avg_load,
        holiday_avg_load: data?.holiday_avg_load,
        non_working_days_nos: data?.non_working_days_nos,
        type_of_machinery: data?.type_of_machinery,
        type_of_load: data?.type_of_load,
        capacitor_bank_rating: data?.capacitor_bank_rating,
        cabling_mode: data?.cabling_mode,
        note: data?.note,
        vacant_chamber_available: checkIfBoolean(
          data?.vacant_chamber_available
        ),
        bus_bar_extension_allowed: checkIfBoolean(
          data?.bus_bar_extension_allowed
        ),
        is_spare_feeder: checkIfBoolean(data?.is_spare_feeder),
        dg_synchronisation_panel: checkIfBoolean(
          data?.dg_synchronisation_panel
        ),
      });
    }
  }, [getSiteVisitDetailsReducer.success.data]);

  const incrementHandler = (key) => {
    const dataMap = {
      transformer_details: {
        data: transformerData,
        setData: setTransformerData,
        setCount: setTransformers,
        count: transformers,
        length: transformerLength,
        newItem: {
          rating: "",
          hv: "",
          lv: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
      dg_details: {
        data: dgsData,
        setData: setDgsData,
        count: dgs,
        setCount: setDgs,
        length: dgsLength,
        newItem: {
          rating: "",
          type: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
      lt_panel_details: {
        data: ltpanelsData,
        setData: setLtpanelsData,
        count: ltpanels,
        setCount: setLtpanels,
        length: ltPanelLength,
        newItem: {
          rating: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
      spare_feeder_details: {
        data: spareFeedersData,
        setData: setSpareFeedersData,
        count: spareFeeders,
        setCount: setSpareFeeders,
        length: spareFeederLength,
        newItem: {
          rating: "",
          file: { showFile: false, existing_files: [], files: [] },
        },
      },
    };

    const { data, setData, setCount, newItem, length, count } = dataMap[key];
    if (count > length) {
      message.warning("Please add a new one after saving the first one!");
      return;
    }
    const val = data.length === 0 ? 0 : data[data.length - 1].id;
    const newItemWithId = { ...newItem, id: val + 1 };

    setCount((prevCount) => prevCount + 1);
    setData([...data, newItemWithId]);
  };

  const decrementHandler = (key) => {
    const dataMap = {
      transformer_details: {
        count: transformers,
        setCount: setTransformers,
        data: transformerData,
        setData: setTransformerData,
        length: transformerLength,
      },
      dg_details: {
        count: dgs,
        setCount: setDgs,
        data: dgsData,
        setData: setDgsData,
        length: dgsLength,
      },
      lt_panel_details: {
        count: ltpanels,
        setCount: setLtpanels,
        data: ltpanelsData,
        setData: setLtpanelsData,
        length: ltPanelLength,
      },
      spare_feeder_details: {
        count: spareFeeders,
        setCount: setSpareFeeders,
        data: spareFeedersData,
        setData: setSpareFeedersData,
        length: spareFeederLength,
      },
    };

    const { count, setCount, data, setData, length } = dataMap[key];

    if (count - 1 < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    if (count <= 1) {
      setData([]);
      setCount(0);
    } else {
      setCount(count - 1);
      setData(data.slice(0, -1));
    }
  };

  const handleValues = (value, key) => {
    const dataMap = {
      transformer_details: {
        setCount: setTransformers,
        setData: setTransformerData,
        data: transformerData,
        apiKey: "transformer_details_array",
        fields: ["id", "rating", "hv", "lv", "file"],
        length: transformerLength,
        newItem: {
          rating: "",
          hv: "",
          lv: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
      dg_details: {
        setCount: setDgs,
        setData: setDgsData,
        data: dgsData,
        length: dgsLength,
        apiKey: "dg_details_array",
        fields: ["id", "rating", "type", "file"],
        newItem: {
          rating: "",
          type: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
      lt_panel_details: {
        setCount: setLtpanels,
        setData: setLtpanelsData,
        data: ltpanelsData,
        length: ltPanelLength,
        apiKey: "lt_panel_details_array",
        fields: ["id", "rating", "file"],
        newItem: {
          rating: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
      spare_feeder_details: {
        setCount: setSpareFeeders,
        setData: setSpareFeedersData,
        data: spareFeedersData,
        apiKey: "spare_feeder_details_array",
        fields: ["id", "rating", "file"],
        length: spareFeederLength,
        newItem: {
          rating: "",
          type: "",
          file: {
            showFile: false,
            existing_files: [],
            files: [],
          },
        },
      },
    };

    const { setCount, setData, data, newItem, length, apiKey, fields } =
      dataMap[key];

    if (value < length) {
      message.warning("Please remove the item by selecting the item!");
      return;
    }

    const arr = mapDetails(
      getSiteVisitFormDetailsReducer.success.data[apiKey],
      fields
    );

    for (let i = arr.length === 0 ? 0 : data.length; i < value; i++) {
      arr.push({
        id: i + 1,
        ...newItem,
      });
    }

    setCount(value);
    setData(arr);
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;

    setElectricalDetails({
      ...electricalDetails,
      [e.target.name]: value,
    });
  };
  const onChangeSelectHandler = (value, key) => {
    setElectricalDetails({
      ...electricalDetails,
      [key]: value,
    });
  };

  const saveHandler = () => {
    if (
      siteVisitId &&
      electricalDetails?.contracted_load &&
      electricalDetails?.grid_voltage_level &&
      electricalDetails?.solar_pv_interconnected_voltage &&
      typeof electricalDetails?.express_dedicated_line === "boolean" &&
      typeof electricalDetails?.net_meter_requirement === "boolean" &&
      typeof electricalDetails?.internet_availability === "boolean" &&
      typeof electricalDetails?.single_phase_supply_availability ===
        "boolean" &&
      electricalDetails?.holiday_avg_load &&
      electricalDetails?.non_working_days_nos &&
      electricalDetails?.type_of_machinery?.length > 0 &&
      electricalDetails?.type_of_load?.length > 0 &&
      typeof electricalDetails?.vacant_chamber_available === "boolean" &&
      typeof electricalDetails?.is_spare_feeder === "boolean" &&
      typeof electricalDetails?.dg_synchronisation_panel === "boolean"
    ) {
      const formData = new FormData();
      formData.append("site_visit_id", siteVisitId);

      formData.append(
        "solar_pv_interconnected_voltage",
        electricalDetails?.solar_pv_interconnected_voltage
      );
      formData.append("contracted_load", electricalDetails?.contracted_load);
      formData.append(
        "grid_voltage_level",
        electricalDetails?.grid_voltage_level
      );
      formData.append(
        "express_dedicated_line",
        electricalDetails?.express_dedicated_line
      );
      formData.append(
        "net_meter_requirement",
        electricalDetails?.net_meter_requirement
      );
      formData.append(
        "internet_availability",
        electricalDetails?.internet_availability
      );
      formData.append(
        "single_phase_supply_availability",
        electricalDetails?.single_phase_supply_availability
      );
      if (electricalDetails?.load_shedding) {
        formData.append("load_shedding", electricalDetails?.load_shedding);
      }
      formData.append("holiday_avg_load", electricalDetails?.holiday_avg_load);
      if (electricalDetails?.working_avg_load) {
        formData.append(
          "working_avg_load",
          electricalDetails?.working_avg_load
        );
      }
      formData.append(
        "non_working_days_nos",
        electricalDetails?.non_working_days_nos
      );
      if (electricalDetails?.capacitor_bank_rating) {
        formData.append(
          "capacitor_bank_rating",
          electricalDetails?.capacitor_bank_rating
        );
      }
      for (
        let i = 0;
        i < electricalDetails?.vacant_chamber_image?.files?.length;
        i++
      ) {
        formData.append(
          "vacant_chamber_image",
          electricalDetails?.vacant_chamber_image?.files[i]
        );
      }
      for (
        let i = 0;
        i < electricalDetails?.bus_bar_extension_image?.files?.length;
        i++
      ) {
        formData.append(
          "bus_bar_extension_image",
          electricalDetails?.bus_bar_extension_image?.files[i]
        );
      }

      formData.append(
        "type_of_machinery",
        electricalDetails?.type_of_machinery
      );
      formData.append("cabling_mode", electricalDetails?.cabling_mode);
      if (electricalDetails?.type_of_load) {
        formData.append("type_of_load", electricalDetails?.type_of_load);
      }
      formData.append(
        "vacant_chamber_available",
        electricalDetails?.vacant_chamber_available
      );
      if (typeof electricalDetails?.bus_bar_extension_allowed === "boolean") {
        formData.append(
          "bus_bar_extension_allowed",
          electricalDetails?.bus_bar_extension_allowed
        );
      }
      if (electricalDetails?.note) {
        formData.append("note", electricalDetails?.note);
      }
      formData.append(
        "dg_synchronisation_panel",
        electricalDetails?.dg_synchronisation_panel
      );
      formData.append("is_spare_feeder", electricalDetails?.is_spare_feeder);

      Promise.resolve(dispatch(addElectricalDetails(formData))).then((res) => {
        if (res) {
          dispatch(getSiteVisitDetails(siteVisitId));
          handleActiveTabHandler(activeTab + 1);
          setSaveLaterText();
        }
      });
    } else {
      message.error("Please Select all the required fields");
      setSaveLaterText();
    }
  };

  useEffect(() => {
    let keys = Object.keys(electricalDetails);

    let count = keys?.reduce((acc, v) => {
      let val = electricalDetails[v];
      if (Array.isArray(val)) {
        if (val?.length > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      } else if (val || typeof val == "boolean") {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    let temp = tabsList;
    temp[1].progress = Math.round((count / keys?.length) * 100);
    editTabsHandler(temp);
  }, [electricalDetails]);

  return (
    <div className="electrical-details-container">
      <h1 className="upper-text">Basic Details</h1>

      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[0] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Basic Details"
              items={[
                electricalDetails?.contracted_load,
                electricalDetails?.grid_voltage_level,
                electricalDetails?.solar_pv_interconnected_voltage,
              ]}
            />
            <FloatInput
              label="Contracted Load(KVA)"
              placeholder="Contracted Load(KVA)*"
              name="contracted_load"
              onChange={onChangeHandler}
              value={electricalDetails?.contracted_load}
              required={true}
              card="Basic Details"
            />
          </Col>
        </Row>

        <Row>
          <Col span={22}>
            <Select
              allowClear
              showArrow
              className="select"
              placeholder="Grid Voltage level*"
              name="grid_voltage_level"
              onSelect={(value) =>
                onChangeSelectHandler(value, "grid_voltage_level")
              }
              card="Basic Details"
              value={electricalDetails?.grid_voltage_level}
            >
              <Option value="11kV">11kV</Option>
              <Option value="22kV">22kV</Option>
              <Option value="33kV">33kV</Option>
              <Option value="66kV">66kV</Option>
              <Option value="132kV">132kV</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            <FloatInput
              label="Solar PV Interconnection Voltage"
              placeholder="Solar PV Interconnection Voltage *"
              name="solar_pv_interconnected_voltage"
              onChange={onChangeHandler}
              value={electricalDetails?.solar_pv_interconnected_voltage}
              required={true}
              card="Basic Details"
            />
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[1] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Express/Dedicated Line"
              items={[electricalDetails?.express_dedicated_line]}
              title="Express/Dedicated Line"
            />

            <Radio.Group
              name="express_dedicated_line"
              onChange={onChangeHandler}
              value={electricalDetails?.express_dedicated_line}
              card="Express/Dedicated Line"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[2] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Transformer Details"
              items={[
                transformers,
                electricalDetails?.transformer_details_array,
              ]}
              title="Transformer Details"
            />
            <FloatInputSuffix
              label="No of Transformers"
              placeholder="No of Transformers*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "transformer_details")
              }
              value={transformers}
              objKey="transformer_details"
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="Transformer Details"
            />
            {transformerData.map((i, index) => {
              return (
                <MultipleForms
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={transformerData}
                  setItems={setTransformerData}
                  fields={getSiteVisitFormFields("Transformer")}
                  title="Transformer"
                  type="transformer_details"
                  imageType="is_transformer_image"
                  updateApiKey="transformer_image"
                  siteVisitId={siteVisitId}
                  length={transformerLength}
                  emptyFeilds={{
                    rating: "",
                    hv: "",
                    lv: "",
                    file: { showFile: false, existing_files: [], files: [] },
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>

      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[3] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="DG Details"
              items={[
                dgs,
                electricalDetails?.dg_details_array,
                electricalDetails?.dg_synchronisation_panel,
              ]}
              title="DG Details"
            />

            <FloatInputSuffix
              label="No of DGs"
              placeholder="No of DGs*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "dg_details")
              }
              objKey="dg_details"
              value={dgs}
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="DG Details"
            />
          </Col>
        </Row>
        {dgsData?.length > 0 && (
          <Row>
            <Col span={22}>
              <Select
                allowClear
                showArrow
                className="select"
                placeholder="DG PV Synchronisation Panel"
                value={electricalDetails?.dg_synchronisation_panel}
                onSelect={(value) =>
                  onChangeSelectHandler(value, "dg_synchronisation_panel")
                }
                card="DG Details"
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={22}>
            {dgsData.map((i, index) => {
              return (
                <MultipleForms
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={dgsData}
                  setItems={setDgsData}
                  fields={getSiteVisitFormFields("DG")}
                  title="DG"
                  type="dg_details"
                  imageType="is_dg_image"
                  updateApiKey="dg_image"
                  typeOptions={["Automatic", "Manual"]}
                  siteVisitId={siteVisitId}
                  length={dgsLength}
                  emptyFeilds={{
                    rating: "",
                    type: "",
                    file: { showFile: false, existing_files: [], files: [] },
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[4] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="LT Panel Details"
              items={[ltpanels, electricalDetails?.lt_panel_details_array]}
              title="LT Panel Details"
            />

            <FloatInputSuffix
              label="No of LT Panels"
              placeholder="No of LT Panels*"
              type="number"
              onChange={(e) =>
                handleValues(parseInt(e.target.value), "lt_panel_details")
              }
              objKey="lt_panel_details"
              value={ltpanels}
              required={true}
              increment={incrementHandler}
              decrement={decrementHandler}
              card="LT Panel Details"
            />
          </Col>
        </Row>

        <Row>
          <Col span={22}>
            {ltpanelsData.map((i, index) => {
              return (
                <MultipleForms
                  key={index}
                  data={i}
                  dataIndex={index}
                  items={ltpanelsData}
                  setItems={setLtpanelsData}
                  fields={getSiteVisitFormFields("LT Panel")}
                  title="LT Panel"
                  type="lt_panel_details"
                  imageType="is_lt_panel_image"
                  updateApiKey="lt_panel_image"
                  siteVisitId={siteVisitId}
                  length={ltPanelLength}
                  emptyFeilds={{
                    rating: "",
                    file: {
                      showFile: false,
                      existing_files: [],
                      files: [],
                    },
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[5] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Are the Spare Feeders available ?"
              items={
                electricalDetails?.is_spare_feeder
                  ? [
                      electricalDetails?.is_spare_feeder,
                      spareFeeders,
                      electricalDetails?.spare_feeder_details_array,
                    ]
                  : [electricalDetails?.is_spare_feeder]
              }
              title="Are the Spare Feeders available ?"
            />
            <Radio.Group
              name="is_spare_feeder"
              onChange={onChangeHandler}
              value={electricalDetails?.is_spare_feeder}
              card="Are the Spare Feeders available ?"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        {electricalDetails?.is_spare_feeder && (
          <>
            <Row>
              <Col span={22}>
                <FloatInputSuffix
                  label="No of Spare Feeder available"
                  placeholder="No of Spare Feeder available*"
                  type="number"
                  onChange={(e) =>
                    handleValues(
                      parseInt(e.target.value),
                      "spare_feeder_details"
                    )
                  }
                  objKey="spare_feeder_details"
                  value={spareFeeders}
                  required={true}
                  increment={incrementHandler}
                  decrement={decrementHandler}
                  card="Are the Spare Feeders available ?"
                />
              </Col>
            </Row>

            <Row>
              <Col span={22}>
                {spareFeedersData.map((i, index) => {
                  return (
                    <MultipleForms
                      key={index}
                      data={i}
                      dataIndex={index}
                      items={spareFeedersData}
                      setItems={setSpareFeedersData}
                      fields={getSiteVisitFormFields("Spare Feeder")}
                      title="Spare Feeder"
                      type="spare_feeder_details"
                      imageType="is_spare_feeder_image"
                      updateApiKey="spare_feeder_image"
                      siteVisitId={siteVisitId}
                      length={spareFeederLength}
                      emptyFeilds={{
                        rating: "",
                        file: {
                          showFile: false,
                          existing_files: [],
                          files: [],
                        },
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </>
        )}
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[6] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="is Vacant Chamber available ?"
              items={
                electricalDetails?.vacant_chamber_available
                  ? [
                      electricalDetails?.vacant_chamber_available,
                      mergeFiles(electricalDetails?.vacant_chamber_image),
                    ]
                  : [electricalDetails?.vacant_chamber_available]
              }
              title="is Vacant Chamber available ?"
            />
            <Radio.Group
              onChange={onChangeHandler}
              value={electricalDetails?.vacant_chamber_available}
              name="vacant_chamber_available"
              card="is Vacant Chamber available ?"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>

            {electricalDetails?.vacant_chamber_available === true && (
              <UploadFile
                state={electricalDetails}
                setState={setElectricalDetails}
                objKey="vacant_chamber_image"
              />
            )}
          </Col>
        </Row>
      </div>

      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[7] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="is Bus Bar extension allowed ?"
              items={
                electricalDetails?.bus_bar_extension_allowed
                  ? [
                      electricalDetails?.bus_bar_extension_allowed,
                      mergeFiles(electricalDetails?.bus_bar_extension_image),
                    ]
                  : [electricalDetails?.bus_bar_extension_allowed]
              }
              title="is Bus Bar extension allowed ?"
            />
            <Radio.Group
              onChange={onChangeHandler}
              value={electricalDetails?.bus_bar_extension_allowed}
              name="bus_bar_extension_allowed"
              title="is Bus Bar extension allowed ?"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>

            {electricalDetails?.bus_bar_extension_allowed === true && (
              <UploadFile
                state={electricalDetails}
                setState={setElectricalDetails}
                objKey="bus_bar_extension_image"
              />
            )}
          </Col>
        </Row>
        {electricalDetails?.vacant_chamber_available === false &&
          electricalDetails?.is_spare_feeder === false &&
          electricalDetails?.bus_bar_extension_allowed === false && (
            <Row>
              <Col span={22}>
                <FloatInput
                  label="Note"
                  placeholder="Note"
                  name="note"
                  onChange={onChangeHandler}
                  value={electricalDetails?.note}
                  card="is Bus Bar extension allowed ?"
                />
              </Col>
            </Row>
          )}
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[8] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Capacitor Bank Rating"
              items={[electricalDetails?.capacitor_bank_rating]}
              title="Capacitor Bank Rating"
            />
            <FloatInputSuffix
              type="number"
              label="Capacitor Bank Rating"
              placeholder="Capacitor Bank Rating"
              onChange={onChangeHandler}
              card="Capacitor Bank Rating"
              name="capacitor_bank_rating"
              value={electricalDetails?.capacitor_bank_rating}
              suffix={"kVAR"}
            />
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[9] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Cabling Mode Available"
              items={[electricalDetails?.cabling_mode]}
              title="Cabling Mode Available"
            />
            <Checkbox.Group
              onChange={(e) =>
                setElectricalDetails({
                  ...electricalDetails,
                  cabling_mode: e,
                })
              }
              value={electricalDetails?.cabling_mode}
              title="Cabling Mode Available"
              card="Cabling Mode Available"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Cable Trays">Cable Trays</Checkbox>
                <Checkbox value="Cable Trenches">Cable Trenches</Checkbox>
              </div>
            </Checkbox.Group>
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[10] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Machinery Type"
              items={[electricalDetails?.type_of_machinery]}
              title="Machinery Type"
            />
            <Checkbox.Group
              onChange={(e) =>
                setElectricalDetails({
                  ...electricalDetails,
                  type_of_machinery: e,
                })
              }
              value={electricalDetails?.type_of_machinery}
              title="Machinery Type"
              card="Machinery Type"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Inductive">Inductive</Checkbox>
                <Checkbox value="Resistive">Resistive</Checkbox>
              </div>
            </Checkbox.Group>
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[11] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Load Type"
              items={[electricalDetails?.type_of_load]}
              title="Load Type"
            />

            <Checkbox.Group
              onChange={(e) =>
                setElectricalDetails({
                  ...electricalDetails,
                  type_of_load: e,
                })
              }
              value={electricalDetails?.type_of_load}
              card="Load Type"
            >
              <div className="checkbox-group-border">
                <Checkbox value="Linear">Linear</Checkbox>
                <Checkbox value="Non-Linear">Non-Linear</Checkbox>
              </div>
            </Checkbox.Group>
          </Col>
        </Row>
      </div>

      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[12] = element;
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={22}>
            <CardPercentage
              cardName="Electrical Load Details"
              items={[
                electricalDetails?.load_shedding,
                electricalDetails?.working_avg_load,
                electricalDetails?.holiday_avg_load,
                electricalDetails?.non_working_days_nos,
              ]}
              title="Electrical Load Details"
            />
          </Col>
          <Col span={11}>
            <FloatInputSuffix
              type="number"
              name="load_shedding"
              label="Load Shedding in a Month"
              placeholder="Load Shedding in a Month"
              onChange={onChangeHandler}
              value={electricalDetails?.load_shedding}
              card="Electrical Load Details"
              suffix={"Hours"}
            />
          </Col>
          <Col span={11}>
            <FloatInputSuffix
              type="number"
              name="non_working_days_nos"
              label="Number of non working days in year"
              placeholder="Number of non working days in year*"
              onChange={onChangeHandler}
              value={electricalDetails?.non_working_days_nos}
              required={true}
              card="Electrical Load Details"
              suffix={"Days"}
            />
          </Col>

          <Col span={11}>
            <FloatInput
              name="working_avg_load"
              label="Average load on working days"
              placeholder="Average load on working days"
              onChange={onChangeHandler}
              value={electricalDetails?.working_avg_load}
              card="Electrical Load Details"
            />
          </Col>
          <Col span={11}>
            <FloatInput
              name="holiday_avg_load"
              label="Average load on Non working days"
              placeholder="Average load on Non working days*"
              onChange={onChangeHandler}
              value={electricalDetails?.holiday_avg_load}
              required={true}
              card="Electrical Load Details"
            />
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[13] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Net Metering Requirement"
              items={[electricalDetails?.net_meter_requirement]}
              title="Net Metering Requirement"
            />

            <Radio.Group
              onChange={onChangeHandler}
              value={electricalDetails?.net_meter_requirement}
              name="net_meter_requirement"
              card="Net Metering Requirement"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[14] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Internet Availability"
              items={[electricalDetails?.internet_availability]}
              title="Internet Availability"
            />

            <Radio.Group
              onChange={onChangeHandler}
              value={electricalDetails?.internet_availability}
              name="internet_availability"
              card="Internet Availability"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <div
        className="card-container"
        ref={(element) => {
          electricalRef.current[15] = element;
        }}
      >
        <Row>
          <Col span={22}>
            <CardPercentage
              cardName="Single Phase Supply Availability"
              items={[electricalDetails?.single_phase_supply_availability]}
              title="Single Phase Supply Availability"
            />

            <Radio.Group
              onChange={onChangeHandler}
              value={electricalDetails?.single_phase_supply_availability}
              name="single_phase_supply_availability"
              card="Single Phase Supply Availability"
            >
              <Space direction="vertical">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>

      <div className="btn-container" onClick={() => saveHandler()}>
        <Button>Save</Button>
      </div>
    </div>
  );
}

export default ElectricalDetails;
