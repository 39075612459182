import React, { useState } from "react";
import { Input } from "antd";

import "./float-input-suffix.style.less";

const FloatInputSuffix = (props) => {
  let {
    label,
    value,
    placeholder,
    type,
    required,
    increment,
    decrement,
    objKey,
    name,
    suffix,
    onFocus,
    card,
  } = props;

  const requiredMark = required ? (
    <span style={{ color: "red" }}>*</span>
  ) : null;

  return (
    <div className="float-label-number" onClick={onFocus}>
      <Input
        bordered={false}
        onChange={props.onChange}
        type={type}
        name={name}
        defaultValue={value}
        disabled={suffix ? false : true}
        value={value}
        card={card}
        suffix={
          suffix ? (
            suffix
          ) : (
            <div>
              <span
                onClick={() => decrement(objKey)}
                style={{
                  border: "1px solid #f4777c",
                  color: "#f4777c",
                  fontSize: "1rem",
                  borderRadius: "2px",
                  padding: "2px 5px",
                  cursor: "pointer",
                }}
              >
                -
              </span>
              <span
                onClick={() => increment(objKey)}
                style={{
                  border: "1px solid #00b680",
                  color: "#00b680",
                  fontSize: "1rem",
                  borderRadius: "2px",
                  padding: "2px 5px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
              >
                +
              </span>
            </div>
          )
        }
        placeholder={placeholder}
      />
      <label className="label as-label">
        {value || value == 0 ? label : null}{" "}
        {value || value == 0 ? requiredMark : null}
      </label>
    </div>
  );
};

export default FloatInputSuffix;
