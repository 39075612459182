import { combineReducers } from "redux";
import signUpReducers from "./signup-reducers/index";
import loginReducers from "./login-reducers/index";
import activateReducers from "./activate-user-reducers";
import projectReducers from "./project-reducers/index";
import docsReducers from "./docs-reducers/index";
import teamReducers from "./team-reducers/index";
import ganttReducers from "./gantt-reducers/index";
import preRequsiteReducers from "./pre-requisite-reducers/index";
import approvalReducers from "./approval-reducers/index";
import engineeringReducers from "./engineering-reducers/index";
import procurementReducers from "./procurement-reducers/index";
import materialHandlingReducers from "./material-handling-reducers/index";
import constructionReducers from "./constructors-reducers/index";
import siteHandoverReducers from "./site-handover-reducers/index";
import ganttEventReducers from "./gantt-events-reducers/index";
import ganttEventStageReducers from "./gantt-event-stage-reducers/index";
import eventDataReducers from "./event-data-reducers/index";
import commentReducers from "./comment-reducers/index";
import dailyUpdatesReducer from "./daily-updates-reducers/index";
import specificProjectDetailsReducer from "./specific-project-dashboard-reducer/index";
import commentFileReducers from "./comment-files-reducers/index";
import deleteReducers from "./delete-reducers/index";
import updateReducers from "./update-reducers/index";
import viewSpecificationReducers from "./view-specification-reducers/index";
import ticketReducers from "./ticket-reducers/index";
import spocReducers from "./spoc-reducers/index";
import projectLevelTeamReducer from "./project-level-team-reducer/index";
import uploadFileReducers from "./upload-file-reducers";
import ganttEventMenuReducers from "./gantt-event-menu-reducers/index";
import ganttDataReducers from "./gantt-data-reducers/index";
import allEventReducers from "./all-events-reducers";
import inventoryReducer from "./inventory-reducer/index";
import materialUsedReducer from "./material-used-reducer/index";
import projectDocsReducer from "./project-docs-reducer/index";
import notificationReducers from "./notification-reducers/index";
import drawingReducers from "./drawing-reducers/index";
import companyProfileReducers from "./profile-reducers/index";
import stakeholderProfileReducers from "./stakeholder-profile-reducers/index";
import getSampleGanttReducers from "./sample-gantts-reducers/index";
import sampleGanttDetailsReducers from "./sample-gantts-details-reducers/index";
import ganttProjectReducers from "./gantt-project-reducers/index";
import ganttProjectDetailsReducers from "./gantt-project-details-reducers/index";
import projectFinanceReducer from "./project-finance-reducer/index";
import getSampleEventsReducers from "./sample-events-reducers/index";
import getSampleEventReducers from "./sample-event-reducers/index";
import getProjectReducers from "./get-project-reducers/index";
import postEventReducers from "./post-event-reducer/index";
import specificationReducers from "./project-specification-reducers/index";
import specificationEventReducers from "./project-specification-events-reducers/index";
import subContractorReducers from "./sub-contractor-reducers/index";
import subContractorProjectReducers from "./sub-contarctors-project-reducers/index";
import subContractorStageAccessReducers from "./sub-contractors-stage-access";
import taskListReducers from "./task-list-reducers/index";
import getBillReducers from "./bill-reducers/index";
import getManageSubscriptionReducers from "./manage-subscription-reducers/index";
import projectReportReducer from "./project-report-reducer/index";
import photoReducers from "./photo-reducers";
import photoAlbumReducers from "./photo-album-reducers";
import projectPhotosReducer from "./project-photos-reducer";
import crmReducers from "./crm-reducers";
import customerDashboardReducers from "./customer-dashboard-reducers";
import toggleTaskReducers from "./toggle-task-reducers";
import vendorDashboardReducer from "./vendor-dashboard-reducers";
import customerEventApprovalReducers from "./customer-event-approval-reducers";
import updateCustomerApprovalRequestReducers from "./update-customer-approval-request-reducers";
import planningReducers from "./planning-reducers";
import ticketDashboardReducers from "./ticket-dashboard-reducers";
import ticketNotificationReducers from "./ticket-notification-reducers";
import epcReducers from "./epc-reducers";
import projectManagersReducers from "./project-managers-reducers";
import customerDocsDetailsReducers from "./customer-doc-details-reducers";
import vendorDocDetailsReducers from "./vendor-doc-details-reducers";
import workTableReducers from "./work-table-reducers";
import suppliersReducer from "./suppliers-reducers";
import floatRfqReducers from "./float-rfq-reducers";
import vendorPhotosReducers from "./vendor-photos-reducers";
import getSiteVisitReducers from "./get-site-visit-reducers";
import getSiteVisitDetailsReducers from "./get-site-visit-details-reducers";
import getCompanySpocsReducers from "./get-company-spocs";
import dataDashboardReducers from "./data-dashboard-reducers";
import getFinanceLoanReducer from "./get-finance-loan-reducer";
import getloanListReducer from "./get-loan-list-reducers";
import getLineDetailsReducer from "./get-line-details-reducers";
import getCompanyLoansReducer from "./get-company-loans";
import ssoReducers from "./sso-reducers";
import getLoanLeadsReducer from "./get-loan-leads-reducers";
import getEngineeringDashboardReducers from "./get-engineering-dashboard-reducers";
import getScmDetailReducers from "./get-scm-detail-reducers";
import getSiteVisitFormDetailsReducers from "./get-site-visit-form-details-reducers";
import getSCMDashboardReducers from "./get-scm-dashboard-reducers";
import getConstructionDashboardReducers from "./get-construction-dashboard-reducers";

export default combineReducers({
  signUpReducers,
  loginReducers,
  activateReducers,
  projectReducers,
  allEventReducers,
  docsReducers,
  photoReducers,
  photoAlbumReducers,
  teamReducers,
  suppliersReducer,
  ganttReducers,
  preRequsiteReducers,
  approvalReducers,
  engineeringReducers,
  procurementReducers,
  materialHandlingReducers,
  constructionReducers,
  siteHandoverReducers,
  ganttEventReducers,
  ganttEventStageReducers,
  eventDataReducers,
  commentReducers,
  commentFileReducers,
  dailyUpdatesReducer,
  specificProjectDetailsReducer,
  deleteReducers,
  updateReducers,
  viewSpecificationReducers,
  ticketReducers,
  spocReducers,
  projectLevelTeamReducer,
  uploadFileReducers,
  ganttEventMenuReducers,
  ganttDataReducers,
  inventoryReducer,
  materialUsedReducer,
  projectDocsReducer,
  projectPhotosReducer,
  notificationReducers,
  drawingReducers,
  companyProfileReducers,
  stakeholderProfileReducers,
  getSampleGanttReducers,
  sampleGanttDetailsReducers,
  ganttProjectReducers,
  ganttProjectDetailsReducers,
  projectFinanceReducer,
  getSampleEventsReducers,
  getSampleEventReducers,
  getProjectReducers,
  postEventReducers,
  specificationReducers,
  specificationEventReducers,
  subContractorReducers,
  subContractorProjectReducers,
  subContractorStageAccessReducers,
  taskListReducers,
  getBillReducers,
  getManageSubscriptionReducers,
  projectReportReducer,
  crmReducers,
  customerDashboardReducers,
  toggleTaskReducers,
  vendorDashboardReducer,
  toggleTaskReducers,
  updateCustomerApprovalRequestReducers,
  customerEventApprovalReducers,
  planningReducers,
  ticketDashboardReducers,
  ticketNotificationReducers,
  epcReducers,
  projectManagersReducers,
  customerDocsDetailsReducers,
  vendorDocDetailsReducers,
  workTableReducers,
  floatRfqReducers,
  vendorPhotosReducers,
  getSiteVisitReducers,
  getSiteVisitDetailsReducers,
  getCompanySpocsReducers,
  dataDashboardReducers,
  getFinanceLoanReducer,
  getloanListReducer,
  getLineDetailsReducer,
  getCompanyLoansReducer,
  ssoReducers,
  getLoanLeadsReducer,
  getEngineeringDashboardReducers,
  getSCMDashboardReducers,
  getScmDetailReducers,
  getConstructionDashboardReducers,
  getSiteVisitFormDetailsReducers,
});

// const rootReducer = (state, action) => {
//   // when a logout action is dispatched it will reset redux state
//   if (action.type === 'RESET_STATE') {
//     state = undefined;
//   }

//   return appReducer(state, action);
// };
// export default rootReducer;
