import React, { useState } from "react";
import { Input } from "antd";

import "./float-input.style.less";

const FloatInput = (props) => {
  let {
    label,
    value,
    placeholder,
    type,
    required,
    suffix,
    name,
    onFocus,
    disabled,
    readOnly,
    card,
  } = props;


  const requiredMark = required ? (
    <span style={{ color: "red" }}>*</span>
  ) : null;

  return (
    <div className="float-label" onClick={onFocus}>
      <Input
        bordered={false}
        name={name}
        onChange={props.onChange}
        type={type}
        defaultValue={value}
        value={value}
        suffix={suffix}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        card={card}
      />
      <label className="label as-label">
        {value ? label : null} {value ? requiredMark : null}
      </label>
    </div>
  );
};

export default FloatInput;
