import * as actions from "../../actions/types";

const INITIAL_STATE = {
  get: {
    loading: false,
    reset: false,
    success: {
      ok: false,
      data: null,
    },
    failure: {
      error: false,
      message: "",
    },
  },
  login: {
    post: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  company: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const dataDashboardReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.GET_VENDOR_TEAM_DASHBOARD_LOADING:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          reset: false,
          success: {
            ...state.get.success,
            ok: false,
          },
          failure: {
            error: false,
            message: "",
          },
        },
      };
    case actions.GET_VENDOR_TEAM_DASHBOARD_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          reset: false,
          success: {
            ...state.get.success,
            ok: true,
            data: action.payload,
          },
          failure: {
            error: false,
            message: "",
          },
        },
      };
    case actions.GET_VENDOR_TEAM_DASHBOARD_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          reset: false,
          success: {
            ...state.get.success,
            ok: false,
            data: null,
          },
          failure: {
            error: true,
            message: action.payload.message,
            accessDenied: action.payload.accessDenied,
          },
        },
      };
    case actions.GET_VENDOR_TEAM_DASHBOARD_RESET:
      return {
        ...state,
        get: {
          ...INITIAL_STATE.get,
          reset: true,
        },
      };
    case actions.DATA_DASHBOARD_LOGIN_LOADING:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: true,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actions.DATA_DASHBOARD_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actions.DATA_DASHBOARD_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case actions.DATA_DASHBOARD_LOGIN_RESET:
      return {
        ...state,
        login: {
          ...state.login.post,
          post: {
            ...INITIAL_STATE.login.post,
            reset: true,
          },
        },
      };
    case actions.GET_DATA_DASHBOARD_COMPANY_DETAILS_LOADING:
      return {
        ...state,
        company: {
          ...state.company,
          get: {
            ...state.company.get,
            loading: true,
            reset: false,
            success: {
              ...state.company.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actions.GET_DATA_DASHBOARD_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          get: {
            ...state.company.get,
            loading: false,
            reset: false,
            success: {
              ...state.company.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actions.GET_DATA_DASHBOARD_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          get: {
            ...state.company.get,
            loading: false,
            reset: false,
            success: {
              ...state.company.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case actions.GET_DATA_DASHBOARD_COMPANY_DETAILS_RESET:
      return {
        ...state,
        company: {
          ...state.company.get,
          get: {
            ...INITIAL_STATE.company.get,
            reset: true,
          },
        },
      };
  }
  return state;
};

export default dataDashboardReducers;
