import {
  GET_PROJECT_LEVEL_TEAMS_LOADING,
  GET_PROJECT_LEVEL_TEAMS_SUCCESS,
  GET_PROJECT_LEVEL_TEAMS_FAILURE,
  GET_TEAM_MEMBERS_NAME_LOADING,
  GET_TEAM_MEMBERS_NAME_SUCCESS,
  GET_TEAM_MEMBERS_NAME_FAILURE,
} from "../../actions/types";

const INITIAL_STATE = {
    team: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    },

    teamMembersName: {
        get: {
            loading: false,
            reset: false,

            success: {
                ok: false,
                data: null,
            },

            failure: {
                error: false,
                message: "",
            }
        }
    }
}

const projectLevelTeamReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_PROJECT_LEVEL_TEAMS_LOADING:
            return {
                ...state,
                team: {
                    ...state.team,
                    get: {
                        ...state.team.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.team.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: false,
                            message: "",
                        }
                    }
                }
            }

        case GET_PROJECT_LEVEL_TEAMS_SUCCESS:
            return {
                ...state,
                team: {
                    ...state.team,
                    get: {
                        ...state.team.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.team.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: "",
                        }
                    }
                }
            }

        case GET_PROJECT_LEVEL_TEAMS_FAILURE:
        return {
            ...state,
            team: {
                ...state.team,
                get: {
                    ...state.team.get,
                    loading: false,
                    reset: false,
                    success: {
                        ...state.team.get.success,
                        ok: false,
                        data: null,
                    },
                    failure: {
                        error: true,
                        message: action.payload.message,
                    }
                }
            }
        }

    case GET_TEAM_MEMBERS_NAME_LOADING:
        return {
            ...state,
            teamMembersName: {
                ...state.teamMembersName,
                get: {
                    ...state.teamMembersName.get,
                    loading: true,
                    reset: false,

                    success: {
                        ...state.teamMembersName.get.success,
                        ok: false,
                        data: null,
                    },

                    failure: {
                        error: false,
                        message: "",
                    }
                }
            }
        }

    case GET_TEAM_MEMBERS_NAME_SUCCESS:
        return {
            ...state,
            teamMembersName: {
                ...state.teamMembersName,
                get: {
                    ...state.teamMembersName.get,
                    loading: false,
                    reset: false,

                    success: {
                        ...state.teamMembersName.get.success,
                        ok: true,
                        data: action.payload,
                    },

                    failure: {
                        error: false,
                        message: "",
                    }
                }
            }
        }

    case GET_TEAM_MEMBERS_NAME_FAILURE:
        return {
            ...state,
            teamMembersName: {
                ...state.teamMembersName,
                get: {
                    ...state.teamMembersName.get,
                    loading: false,
                    reset: false,

                    success: {
                        ...state.teamMembersName.get.success,
                        ok: false,
                        data: null,
                    },

                    failure: {
                        error: true,
                        message: action.payload.message,
                    }
                }
            }
        }

    default:
        return state

    }
}

export default projectLevelTeamReducer