import * as actionTypes from "../../actions/types";

const INITIAL_STATE = {
  budgetedFinance: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },

  actualFinance: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  cashflow: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  milestones: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },

  pnlDetails: {
    get: {
      pnlDetailsFlag: false,
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  financialPlan: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  budgetFile: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const projectFinanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_BUDGETED_FINANCE_DETAILS_LOADING:
      return {
        ...state,
        budgetedFinance: {
          ...state.budgetedFinance,
          get: {
            ...state.budgetedFinance.get,
            loading: true,
            reset: false,
            success: {
              ...state.budgetedFinance.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_BUDGETED_FINANCE_DETAILS_SUCCESS:
      return {
        ...state,
        budgetedFinance: {
          ...state.budgetedFinance,
          get: {
            ...state.budgetedFinance.get,
            loading: false,
            reset: false,
            success: {
              ...state.budgetedFinance.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_BUDGETED_FINANCE_DETAILS_FAILURE:
      return {
        ...state,
        budgetedFinance: {
          ...state.budgetedFinance,
          get: {
            ...state.budgetedFinance.get,
            loading: false,
            reset: false,
            success: {
              ...state.budgetedFinance.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case actionTypes.GET_ACTUAL_FINANCE_DETAILS_LOADING:
      return {
        ...state,
        actualFinance: {
          ...state.actualFinance,
          get: {
            ...state.actualFinance.get,
            loading: true,
            reset: false,
            success: {
              ...state.actualFinance.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_ACTUAL_FINANCE_DETAILS_SUCCESS:
      return {
        ...state,
        actualFinance: {
          ...state.actualFinance,
          get: {
            ...state.actualFinance.get,
            loading: false,
            reset: false,
            success: {
              ...state.actualFinance.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_MILESTONES_LOADING:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          get: {
            ...state.milestones.get,
            loading: true,
            reset: false,
            success: {
              ...state.milestones.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_MILESTONES_SUCCESS:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          get: {
            ...state.milestones.get,
            loading: false,
            reset: false,
            success: {
              ...state.milestones.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_MILESTONES_FAILURE:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          get: {
            ...state.milestones.get,
            loading: false,
            reset: false,
            success: {
              ...state.milestones.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case actionTypes.GET_CASHFLOW_LOADING:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          get: {
            ...state.cashflow.get,
            loading: true,
            reset: false,
            success: {
              ...state.cashflow.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_CASHFLOW_SUCCESS:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          get: {
            ...state.cashflow.get,
            loading: false,
            reset: false,
            success: {
              ...state.cashflow.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.GET_CASHFLOW_FAILURE:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          get: {
            ...state.cashflow.get,
            loading: false,
            reset: false,
            success: {
              ...state.cashflow.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case actionTypes.GET_BUDGETED_FINANCE_DETAILS_FAILURE:
      return {
        ...state,
        actualFinance: {
          ...state.actualFinance,
          get: {
            ...state.actualFinance.get,
            loading: false,
            reset: false,
            success: {
              ...state.actualFinance.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case actionTypes.CALCULATE_P_N_L_LOADING:
      return {
        ...state,
        pnlDetails: {
          ...state.pnlDetails,
          get: {
            ...state.pnlDetails.get,
            pnlDetailsFlag: true,
            loading: true,
            reset: false,
            success: {
              ...state.pnlDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.CALCULATE_P_N_L_SUCCESS:
      return {
        ...state,
        pnlDetails: {
          ...state.pnlDetails,
          get: {
            ...state.pnlDetails.get,
            pnlDetailsFlag: true,
            loading: false,
            reset: false,
            success: {
              ...state.pnlDetails.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };

    case actionTypes.CALCULATE_P_N_L_FAILURE:
      return {
        ...state,
        pnlDetails: {
          ...state.pnlDetails,
          get: {
            ...state.pnlDetails.get,
            pnlDetailsFlag: true,
            loading: false,
            reset: false,
            success: {
              ...state.pnlDetails.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };

    case actionTypes.GET_FINANCIAL_PLAN_LOADING:
      return {
        ...state,
        financialPlan: {
          ...state.financialPlan,
          get: {
            ...state.financialPlan.get,
            loading: true,
            reset: false,
            success: {
              ...state.financialPlan.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actionTypes.GET_FINANCIAL_PLAN_SUCCESS:
      return {
        ...state,
        financialPlan: {
          ...state.financialPlan,
          get: {
            ...state.financialPlan.get,
            loading: false,
            reset: false,
            success: {
              ...state.financialPlan.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actionTypes.GET_FINANCIAL_PLAN_FAILURE:
      return {
        ...state,
        financialPlan: {
          ...state.financialPlan,
          get: {
            ...state.financialPlan.get,
            loading: false,
            reset: false,
            success: {
              ...state.financialPlan.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case actionTypes.GET_FINANCIAL_PLAN_RESET:
      return {
        ...state,
        financialPlan: {
          ...state.financialPlan.get,
          get: {
            ...INITIAL_STATE.financialPlan.post,
            reset: true,
          },
        },
      };
    case actionTypes.DOWNLOAD_BUDGET_SUCCESS:
      return {
        ...state,
        budgetFile: {
          ...state.budgetFile,
          get: {
            ...state.budgetFile.get,
            loading: false,
            reset: false,
            success: {
              ...state.budgetFile.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    default:
      return state;
  }
};

export default projectFinanceReducer;
